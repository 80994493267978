export const EmptyDocumentIcon = ({
  className,
  strokeWidth,
}: {
  className?: string
  strokeWidth: string
}) => (
  <svg
    width="159"
    height="179"
    viewBox="0 0 159 179"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M69 21.5V99.5"
      stroke="#D2E8FF"
      strokeWidth={strokeWidth}
      strokeDasharray="12 12"
    />
    <path
      d="M69 100L0.999995 139"
      stroke="#D2E8FF"
      strokeWidth={strokeWidth}
      strokeDasharray="12 12"
    />
    <path
      d="M138 139L69 100"
      stroke="#D2E8FF"
      strokeWidth={strokeWidth}
      strokeDasharray="12 12"
    />
    <path
      d="M1 60L69 21L138 60M1 60V139L69 178M1 60L69 99.5M69 178L138 139V60M69 178V99.5M138 60L69 99.5"
      stroke="#5884E7"
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <path
      d="M118 21H158"
      stroke="#5884E7"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <path
      d="M138 41V0.999999"
      stroke="#5884E7"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
)
