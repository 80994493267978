import { Html } from '@react-three/drei'
import { useSearch } from 'wouter'
import { useShallow } from 'zustand/react/shallow'

import { CADPartLabel } from '../CADPartLabel'
import { Progress } from '@/components/ui/progress'
import { CrossSectionControls } from './CrossSectionControls'
import { ExplosionsControls } from './ExplosionsControls'
import { ControlPanel } from '@/pages/CADPage/components/ControlPanel'

import { useCADPageStore } from '@/state'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

import { useBounds } from './hooks/useBounds'
import { useModelHandlers } from './hooks/useModelHandlers'
import { useExplosionLines } from './hooks/useExplosionLines'
import { useModelSetup } from './hooks/useModelSetup'
import { useCrossSection } from '../ControlPanel/CrossSectionButton/hooks/useCrossSection'
import { useSetCadFromView } from '../ScreenshotPanelButton/hooks/useSetCadFromView'
import { useEffect, useMemo } from 'react'
import queryString from 'query-string'
import { useWireframe } from './hooks/useWireframe'
import { useExplosionsControls } from './hooks/useExplosionsControls'

export const Model = () => {
  const searchParams = useSearch()
  const { isLoading: isLoadingCAD, data } = useCADQuery()
  const { isLoading: isLoadingDoc, data: docData } = useDocumentPageQuery()
  const gltf = data?.gltf
  const documentType = docData?.documentType

  const loading = Math.round(useCADPageStore((state) => state.loadingProgress))
  const handlers = useModelHandlers()

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const setCadFromView = useSetCadFromView()

  const allViews = useMemo(() => {
    if (isLoadingDoc) return []
    if (docData?.views) {
      return docData.views
    }

    return []
  }, [docData])

  const documentPages = useMemo(() => {
    if (isLoadingDoc) return []
    if (docData?.documentPages) {
      return docData.documentPages
    }

    return []
  }, [docData])

  useBounds(gltf)
  useModelSetup(gltf)
  useWireframe(gltf)
  useExplosionLines(gltf)
  useExplosionsControls(gltf)
  useCrossSection()

  useEffect(() => {
    const { viewId, stepId } = queryString.parse(searchParams) as {
      viewId?: string
      stepId?: string
    }

    if (viewId) {
      const view = allViews.find((view) => view.id === viewId)
      const documentPage = documentPages.find(
        (documentPage) => documentPage.id === view?.document_page,
      )

      const awaitSetCadFromView = async (viewParam) => setCadFromView(viewParam)

      if (view) {
        awaitSetCadFromView(view).catch((error) => {
          console.error('Failed to set CAD from view:', error)
        })
        setCadPageState({
          subAssemblyDocumentPageId: documentPage?.parent || null,
        })
      }
    }

    if (stepId) {
      setCadPageState({
        selectedParts: [],
        operationStep: {
          documentId: docData?.doc?.id as string,
          stepId: stepId as string,
          selectFromCad: false,
          isActive: true,
        },
      })
    }
    // #TODO: WTF is this?
  }, [searchParams])

  const isLoading = isLoadingCAD || isLoadingDoc
  if (isLoading || !documentType || !gltf || !handlers) {
    return (
      <Html center zIndexRange={[50, 0]} className="w-80 flex flex-col gap-2.5">
        <Progress value={loading} className="w-full" />
        <span className="text-xs text-gray-500 font-semibold text-center">
          {loading}%
        </span>
      </Html>
    )
  }

  return (
    <>
      <primitive
        object={gltf?.scene ?? {}}
        onPointerEnter={handlers.handlePointerEnter}
        onPointerLeave={handlers.handlePointerUpAndLeave}
        onPointerDown={handlers.handlePointerDown}
        onPointerUp={handlers.handlePointerUpAndLeave}
      />
      <>{gltf.scene && <ControlPanel />}</>
      <CADPartLabel documentType={documentType} />
      <ExplosionsControls gltf={gltf} />
      <CrossSectionControls gltf={gltf} />
    </>
  )
}
