import { useEffect } from 'react'
import { useCADPageStore } from '@/state/cad'
import { useCADQuery } from '@/services/queries/cads'

import type { GLTFObject } from '@/lib/cad/GLTFObject'

export const useExplosionLines = (gltf?: GLTFObject) => {
  const { data: cadData } = useCADQuery()
  const explosionsState = useCADPageStore((state) => state.explosionsState)
  const explosions = useCADPageStore((state) => state.explosions)
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const assemblyTree = cadData.rawAssemblyTree

  useEffect(() => {
    if (gltf && assemblyTree) {
      gltf.showExplosionLines(
        explosions,
        assemblyTree,
        isExplosionLinesEnabled,
        {
          hiddenParts,
        },
      )
    }
  }, [
    gltf,
    explosions,
    isExplosionLinesEnabled,
    hiddenParts,
    assemblyTree,
    explosionsState,
  ])
}
