import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { EyeIcon } from '@heroicons/react/24/solid'
import { EyeSlashIcon } from '@heroicons/react/24/solid'
import { Button } from '@/components/ui/button'
import { useCADPageStore } from '@/state/cad'
import { cn } from '@/utils'

import type { GLTFObject } from '@/lib/cad/GLTFObject'
import type { ASMTreeNode } from '@/state'

export const HidePartButton = ({
  node,
  gltf,
  isVisible,
}: {
  node: ASMTreeNode
  gltf: GLTFObject
  isVisible: boolean
}) => {
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const isHidden = hiddenParts.includes(node.uuid)

  const toggleHiddenPart = useCallback(() => {
    gltf.setVisibility(node.instance, !!isHidden)
    setCadPageState({
      hiddenParts: isHidden
        ? hiddenParts.filter((uuid) => uuid !== node.uuid)
        : [...hiddenParts, node.uuid],
    })
  }, [gltf, hiddenParts, isHidden, node.uuid, node.instance, setCadPageState])

  const Icon = isHidden ? EyeSlashIcon : EyeIcon
  const shouldShowButton = isVisible || isHidden

  return (
    <Button
      className="h-1"
      variant="ghost"
      onMouseDown={(e) => {
        e.stopPropagation()
        if (shouldShowButton) {
          toggleHiddenPart()
        }
      }}
    >
      <Icon
        className={cn('w-4 h-4', {
          hidden: !shouldShowButton,
          'text-primary-50': !isHidden,
          'text-gray-400': isHidden,
        })}
      />
    </Button>
  )
}
