import { MinimalLayout } from '@/components/layouts/MinimalLayout'
import { EditorNavbar } from '@/components/nav/EditorNavbar'
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable'
import { ReadOnlyBanner } from '@/components/banners/ReadOnlyBanner'

import { Documents } from './components/Documents'
import { TableOfContents } from './components/TableOfContents'
import { AnnotationsToolbar } from './components/Annotations/AnnotationsToolbar'
import { DocPageContainer } from '@/components/layouts/DocPageContainer'
import { useDocumentState } from '@/state'
import Comments from './components/Documents/Comments'
import { usePreloadViews } from '@/utils/hooks/usePreloadViews'

export const DocumentPage = () => {
  const commentsEnabled = useDocumentState((state) => state.commentsEnabled)
  usePreloadViews()
  return (
    <MinimalLayout>
      <EditorNavbar active="document" />
      <ReadOnlyBanner />
      <DocPageContainer>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel defaultSize={21} minSize={15} maxSize={40}>
            <TableOfContents />
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel className="relative">
            <AnnotationsToolbar />
            <Documents />
          </ResizablePanel>
          {commentsEnabled && (
            <>
              <ResizablePanel
                className="relative min-w-[300px]"
                defaultSize={16}
                minSize={16}
                maxSize={16}
              >
                <Comments />
              </ResizablePanel>
            </>
          )}
        </ResizablePanelGroup>
      </DocPageContainer>
    </MinimalLayout>
  )
}
