import { useCallback } from 'react'
import { v4 as generateUUID } from 'uuid'
import { useCreateDocumentPage } from '../document_pages'

export const useCreateOperationStep = (props: {
  documentVersionId: string
  invalidateOnSuccess?: boolean
}) => {
  const { isPending, mutateAsync: createDocumentPage } = useCreateDocumentPage({
    documentVersionId: props.documentVersionId,
    invalidateOnSuccess: props?.invalidateOnSuccess,
  })

  const mutate = useCallback(
    ({
      documentVersionId,
      parentDocumentId,
    }: {
      documentVersionId: string
      parentDocumentId: string | null
    }) => {
      const id = generateUUID()
      return createDocumentPage({
        documentVersionId,
        values: {
          name: '',
          assembly_group_id: id,
          template_values: {},
          parent_id: parentDocumentId,
        },
      })
    },
    [createDocumentPage],
  )

  return {
    isPending,
    mutate,
  }
}
