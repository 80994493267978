import { useQuery, useMutation } from '@tanstack/react-query'

import {
  createCadVersion,
  listCadVersions,
  getCadVersion,
  updateCadVersion,
  CadVersion,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import { RawAssemblyTree } from '@/state'
import { QUERY_KEYS as CAD_QUERY_KEYS } from '@/services/queries/cads'
import QUERY_KEYS from './queryKeys'
import MUTATION_KEYS from './mutationKeys'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useToast } from '@/components/ui/use-toast'
import CustomError from '@/lib/api/CustomError'

type mutationCadVersionProps = {
  onSuccess?: (props: {
    projectId: string
    cadId: string
    cadVersion?: CadVersion
  }) => void
}

/*
 ** CAD Versions in cache need to be updated after
 ** 2 minutes of inactivity to update the download URL
 */
const CAD_VERSIONS_CACHE_TIME = 120000

export const useCreateCadVersion = (props?: mutationCadVersionProps) => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_CAD_VERSION],
    mutationFn: async ({
      projectId,
      cadId,
      values,
    }: {
      projectId: string
      cadId: string
      values: {
        filename: string
        comment: string
      }
    }) => {
      const cadVersion = await createCadVersion(cadId, values)

      if (!cadVersion.upload_url) {
        throw new Error('Failed to get upload URL')
      }

      return {
        cadId,
        projectId,
        cadVersion,
        uploadUrl: cadVersion.upload_url,
      }
    },
    onSuccess: async ({ cadVersion, cadId, projectId }) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CAD_VERSIONS, { cadId: cadVersion.cad }],
      })
      if (props?.onSuccess)
        props.onSuccess({
          projectId,
          cadId,
          cadVersion,
        })
    },
  })
}

export const useUpdateCadVersion = (props?: mutationCadVersionProps) => {
  const { projectId, cv: selectedCadVersion } = useCadPageParams()
  const { toast } = useToast()

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_CAD_VERSION],
    mutationFn: ({
      cadVersionId,
      values,
    }: {
      cadVersionId: string
      values: { assembly_tree: RawAssemblyTree }
    }) => updateCadVersion(cadVersionId, values),
    onSuccess: async (cadVersion) => {
      queryClient.setQueryData(
        [QUERY_KEYS.CAD_VERSIONS, { cadId: cadVersion.cad }],
        (cadVersions: Array<CadVersion> | undefined) => {
          if (cadVersions)
            return cadVersions.map((currentCadVersion) =>
              currentCadVersion.id === cadVersion?.id
                ? cadVersion
                : currentCadVersion,
            )
        },
      )
      queryClient.setQueryData(
        [QUERY_KEYS.CAD_VERSION, { cadVersionId: cadVersion?.id }],
        cadVersion,
      )
      queryClient.setQueryData(
        [CAD_QUERY_KEYS.PAGE_CAD, projectId, selectedCadVersion],
        (oldPageCad: {
          version: CadVersion
          rawAssemblyTree: RawAssemblyTree | undefined
        }) => ({
          ...oldPageCad,
          version: cadVersion,
          rawAssemblyTree: cadVersion.assembly_tree,
        }),
      )
      if (props?.onSuccess)
        props.onSuccess({
          projectId,
          cadId: cadVersion.cad,
          cadVersion,
        })
    },
    onError: (error) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useListCadVersions = ({ cadId }: { cadId?: string | null }) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.CAD_VERSIONS, { cadId }],
    queryFn: async () => {
      if (!cadId) return

      try {
        const cadVersions = await listCadVersions(cadId)
        return cadVersions
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    staleTime: CAD_VERSIONS_CACHE_TIME,
    enabled: !!cadId,
  })
}

export const listCadVersionsQuery = ({ cadId }: { cadId: string }) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.CAD_VERSIONS, { cadId }],
    queryFn: () => listCadVersions(cadId),
    staleTime: CAD_VERSIONS_CACHE_TIME,
  })
}

export const getLatestCadVersion = async ({
  cadId,
}: {
  cadId?: string | null
}) => {
  if (!cadId) {
    return
  }
  const cadVersions = await listCadVersionsQuery({ cadId })

  const latestCadVersion = cadVersions.length > 0 ? cadVersions[0] : null

  if (!latestCadVersion) {
    throw new Error('No CAD versions found')
  }

  if (!latestCadVersion.id) {
    throw new Error('No CAD version ID found')
  }

  return latestCadVersion
}

const getCadVersionFn = async ({ cadVersionId }: { cadVersionId: string }) => {
  const cadVersion = await getCadVersion(cadVersionId)

  if (cadVersion) {
    queryClient.setQueryData(
      [QUERY_KEYS.CAD_VERSIONS, { cadId: cadVersion.cad }],
      (cadVersions: Array<CadVersion> | undefined) => {
        if (cadVersions) {
          const cadVersionExists = !!cadVersions.find(
            (currentCadVersion) => currentCadVersion.id === cadVersionId,
          )
          return cadVersionExists
            ? cadVersions.map((currentCadVersion) =>
                currentCadVersion.id === cadVersion.id
                  ? cadVersion
                  : currentCadVersion,
              )
            : [...cadVersions, cadVersion]
        }
      },
    )
  }

  return cadVersion
}
export const useGetCadVersion = ({
  cadVersionId,
}: {
  cadVersionId?: string | null
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.CAD_VERSION, { cadVersionId }],
    staleTime: CAD_VERSIONS_CACHE_TIME,
    queryFn: async () => {
      if (!cadVersionId) return null

      try {
        const cadVersion = await getCadVersionFn({ cadVersionId })
        return cadVersion
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: !!cadVersionId,
  })
}

export const getCadVersionQuery = ({
  cadVersionId,
}: {
  cadVersionId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.CAD_VERSION, { cadVersionId }],
    staleTime: CAD_VERSIONS_CACHE_TIME,
    queryFn: () => getCadVersionFn({ cadVersionId }),
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
