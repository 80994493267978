import { useEffect, useMemo } from 'react'

import { useAppStore } from '@/state'
import { useListViews } from '@/services/queries/views'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

export const usePreloadViews = () => {
  const serviceWorker = useAppStore((state) => state.serviceWorker)

  const { isLoading: isLoadingCadData, data: cadData } = useCADQuery()
  const { isLoading: isLoadingDocPage, data: docData } = useDocumentPageQuery()

  const cadVersionId = useMemo(() => {
    if (isLoadingCadData) return
    return cadData?.version?.id
  }, [isLoadingCadData, cadData])
  const documentVersionId = useMemo(() => {
    if (isLoadingDocPage) return
    return docData?.documentVersion?.id
  }, [isLoadingDocPage, docData])

  const { data: views } = useListViews({
    cadVersionId,
    documentVersionId,
  })

  useEffect(() => {
    if (serviceWorker && views && views.length) {
      views.forEach(async (view) => {
        try {
          const response = await fetch(view.download_url as string)
          if (!response.ok)
            throw new Error(`Failed to fetch image: ${view.download_url}`)
          const blob = await response.blob()

          serviceWorker?.conditionallyAddToCache({
            cacheKey: (view as any).url || (view.download_url as string),
            data: {
              body: blob,
              headers: JSON.parse(JSON.stringify(response.headers)),
              ok: response.ok,
              redirected: response.redirected,
              status: response.status,
              statusText: response.statusText,
              type: response.type,
              url: response.url,
            },
          })
        } catch (error) {
          console.error('Error caching image:', error)
        }
      })
    }
  }, [views, serviceWorker])
}
