import { useMutation } from '@tanstack/react-query'
import {
  uploadFile,
  cadVersionUploaded,
  DocumentTypeChoices,
} from '@/lib/api/client'
import { invalidateProjects } from '@/services/queries/projects/cache'
import DOCUMENT_QUERY_KEYS from '@/services/queries/documents/queryKeys'
import { compressFile } from '@/lib/gzip'
import queryClient from '@/queryClient'
import { useToast } from '@/components/ui/use-toast'
import CustomError from '@/lib/api/CustomError'

import { useCreateProject } from '@/services/queries/projects'
import { useCreateCad } from '@/services/queries/cads'
import { useCreateCadVersion } from '@/services/queries/cad_versions'
import { useCreateDocument } from '@/services/queries/documents'
import { useCreateDocumentVersion } from '@/services/queries/document_versions'

type FormInputs = {
  documentName: string
  cadId?: string
  files?: File[]
  projectId?: string
  projectName?: string
  template: DocumentTypeChoices
}

interface CreateDocumentMutationProps {
  onSuccess: (props: { documentId: string; projectId: string }) => void
}

export const useCreateDocumentFormMutation = ({
  onSuccess,
}: CreateDocumentMutationProps) => {
  const { toast } = useToast()
  const { mutateAsync: createProject } = useCreateProject()
  const { mutateAsync: createCad } = useCreateCad()
  const { mutateAsync: createCadVersion } = useCreateCadVersion()
  const { mutateAsync: createDocument } = useCreateDocument()
  const { mutateAsync: createDocumentVersion } = useCreateDocumentVersion()

  const defaultProjectName = 'Q20 Project'

  const createAndUploadNewCAD = async (projectId: string, files: File[]) => {
    const file = files[0]
    const { cadId } = await createCad({
      projectId,
      values: { file: file, name: file.name },
    })

    const compressedFile = await compressFile(file)
    const { cadVersion, uploadUrl } = await createCadVersion({
      projectId,
      cadId,
      values: { filename: compressedFile.name, comment: '' },
    })

    await uploadFile(uploadUrl, compressedFile)
    await cadVersionUploaded(cadVersion.id || '')

    return cadId
  }

  return useMutation({
    mutationFn: async ({
      projectName,
      formValues,
      isCreatingNewProject,
      isUploadingNewCAD,
    }: {
      projectName?: string
      formValues: FormInputs
      isCreatingNewProject: boolean
      isUploadingNewCAD: boolean
    }) => {
      try {
        const projectId =
          isCreatingNewProject && projectName
            ? (await createProject({ name: projectName })).id || ''
            : formValues.projectId || defaultProjectName

        const cadId =
          isUploadingNewCAD && formValues.files
            ? await createAndUploadNewCAD(projectId, formValues.files)
            : formValues.cadId || ''

        const documentId =
          (await createDocument({
            projectId,
            values: {
              name: formValues.documentName,
              cad_id: cadId,
              document_type: formValues.template,
            },
          }).then(({ document }) => document.id)) || ''

        await createDocumentVersion({ documentId })

        return {
          projectId,
          documentId,
        }
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }

        throw e
      }
    },
    onSuccess: async (props) => {
      await invalidateProjects()
      await queryClient.invalidateQueries({
        queryKey: [
          DOCUMENT_QUERY_KEYS.DOCUMENTS,
          { projectId: props.projectId },
        ],
      })
      if (onSuccess) onSuccess(props)
    },
  })
}
