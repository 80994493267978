import { useShallow } from 'zustand/react/shallow'
import {
  useOperationSteps,
  useCreateOperationStep,
} from '@/services/queries/operation_steps'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useCADQuery, useResetGLTF } from '@/services/queries/cads'
import { useIsReadOnly } from '../../hooks/useIsReadOnly'
import { useCADPageStore } from '@/state'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import { StepCardList } from './StepCardList'
import { AddStepButton } from './AddStepButton'
import { AssemblyOrderHeader } from './AssemblyOrderHeader'
import { cn } from '@/utils'

export const AssemblyOrderPanel = () => {
  const { isLoading: isCadLoading, data: cadData } = useCADQuery()
  const { isLoading: isDocumentLoading, data: docData } = useDocumentPageQuery()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const { mutate: createOperationStep } = useCreateOperationStep({
    documentVersionId: docData?.documentVersion.id,
  })
  const { isLoading: isReadOnlyLoading, data: isReadOnlyData } = useIsReadOnly()
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const subAssemblyDocumentPageId = useCADPageStore(
    (state) => state.subAssemblyDocumentPageId || null,
  )
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const isEditModeActive = useCADPageStore(
    useShallow((state) => state.isEditModeActive),
  )
  const { reset: resetGLTF } = useResetGLTF()

  const isLoading =
    isCadLoading || isDocumentLoading || isLoadingSteps || isReadOnlyLoading

  if (isLoading) {
    return (
      <div>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    )
  }

  const documentVersionId = cadData.documentVersion?.id
  const documentType = docData?.documentType
  const isReadOnly = Boolean(isReadOnlyData?.isReadOnly)

  return (
    <div
      className={cn(
        'bg-gray-100 flex flex-col h-full w-full py-4 px-2 space-y-2 border-[3px] rounded-xl relative',
        {
          'border-gray-300': !activeOperationStep && !isEditModeActive,
          'border-primary-50': activeOperationStep && !isEditModeActive,
        },
      )}
      data-testid="steps-list"
    >
      <div className="text-sm font-semibold px-2">
        {activeOperationStep && !isEditModeActive && (
          <Button
            variant="outline"
            size="sm"
            className="absolute top-0 right-0 bg-white border-2 border-primary-50 z-50 text-primary-50 h-5 hover:text-primary-50"
            onClick={resetGLTF}
            style={{ translate: '-30% -50%' }}
          >
            ESC
          </Button>
        )}
      </div>
      <AssemblyOrderHeader />
      <StepCardList
        documentVersionId={documentVersionId as string}
        documentId={docData?.doc?.id as string}
        steps={steps.filter(
          (step) => step.parent === subAssemblyDocumentPageId,
        )}
        isReadOnly={isReadOnly}
      />
      <div className="flex justify-end">
        <AddStepButton
          isReadOnly={isReadOnly}
          documentType={documentType}
          onClick={async () => {
            if (documentVersionId) {
              const { documentPage: step } = await createOperationStep({
                documentVersionId,
                parentDocumentId: subAssemblyDocumentPageId,
              })

              setCadPageState({
                operationStep: {
                  documentId: docData?.doc?.id as string,
                  stepId: step?.id as string,
                  isActive: true,
                  selectFromCad: false,
                },
              })

              const intervalId = setInterval(() => {
                const stepContainer = document.getElementById(
                  'step-' + step?.id,
                )
                if (stepContainer) {
                  clearInterval(intervalId)
                  stepContainer.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              }, 100)
            }
          }}
        />
      </div>
    </div>
  )
}
