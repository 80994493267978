import { useQuery, useMutation } from '@tanstack/react-query'

import {
  createDocument,
  updateDocument,
  listDocuments,
  getDocument,
  deleteDocument,
  Document,
  DocumentTypeChoices,
  DocumentInput,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import DOCUMENT_QUERY_KEYS from './queryKeys'
import PROJECT_QUERY_KEYS from '../projects/queryKeys'
import { useDocumentPageParams } from '@/pages/DocumentPage/hooks'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'

type mutationDocumentProps = {
  onSuccess?: (response?: { document: Document; projectId: string }) => void
}

export const useCreateDocument = (props?: mutationDocumentProps) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_DOCUMENT],
    mutationFn: async ({
      projectId,
      values,
    }: {
      projectId: string
      values: {
        name: string
        cad_id: string
        document_type: DocumentTypeChoices
      }
    }) => {
      const document = await createDocument(projectId, values)

      return {
        document,
        projectId,
      }
    },
    onSuccess: async ({ document, projectId }) => {
      await queryClient.invalidateQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
      })
      queryClient.setQueryData(
        [DOCUMENT_QUERY_KEYS.DOCUMENT, { documentId: document?.id }],
        document,
      )
      if (props?.onSuccess)
        props.onSuccess({
          document,
          projectId,
        })
    },
    onError: (error) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useUpdateDocument = (props?: mutationDocumentProps) => {
  const { cv, dv } = useDocumentPageParams()
  const { toast } = useToast()

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_DOCUMENT],
    mutationFn: async ({
      documentId,
      projectId,
      values,
    }: {
      documentId: string
      projectId: string
      values: DocumentInput
    }) => {
      const document = await updateDocument(documentId, values)
      return { document, projectId }
    },
    onSuccess: async ({ document, projectId }) => {
      queryClient.setQueryData(
        [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
        (documents: Array<Document> | undefined) =>
          documents?.map((doc) => {
            if (doc.id === document?.id) return document
            return doc
          }),
      )

      await queryClient.invalidateQueries({
        queryKey: [PROJECT_QUERY_KEYS.PROJECT, { projectId }],
      })
      await queryClient.invalidateQueries({
        queryKey: [PROJECT_QUERY_KEYS.PROJECTS],
      })
      await queryClient.invalidateQueries({
        queryKey: [
          DOCUMENT_QUERY_KEYS.DOCUMENT_PAGE,
          {
            projectId,
            documentId: document.id,
            cadVersion: cv,
            documentVersions: dv,
          },
        ],
      })
      if (props?.onSuccess) props.onSuccess()
    },
    onError: (error) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useDeleteDocument = (props?: mutationDocumentProps) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_DOCUMENT],
    mutationFn: async ({
      documentId,
      projectId,
    }: {
      documentId: string
      projectId: string
    }) => {
      await deleteDocument(documentId)

      return {
        documentId,
        projectId,
      }
    },
    onSuccess: async ({ documentId, projectId }) => {
      queryClient.setQueryData(
        [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
        (documents: Array<Document> | undefined) =>
          documents?.filter((document) => document.id !== documentId),
      )

      await queryClient.invalidateQueries({
        queryKey: [PROJECT_QUERY_KEYS.PROJECT, { projectId }],
      })

      await queryClient.invalidateQueries({
        queryKey: [PROJECT_QUERY_KEYS.PROJECTS],
      })

      queryClient.removeQueries({
        queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT, { documentId }],
      })
      if (props?.onSuccess) props.onSuccess()
    },
    onError: (error) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useListDocuments = ({ projectId }: { projectId: string }) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
    queryFn: async () => {
      try {
        const documents = await listDocuments(projectId)
        return documents
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
  })
}

export const listDocumentsQuery = ({ projectId }: { projectId: string }) => {
  return queryClient.fetchQuery({
    queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENTS, { projectId }],
    queryFn: () => listDocuments(projectId),
  })
}

export const useDocument = ({ documentId }: { documentId: string }) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [DOCUMENT_QUERY_KEYS.DOCUMENT, { documentId }],
    enabled: Boolean(documentId),
    queryFn: async () => {
      try {
        const document = await getDocument(documentId)
        return document
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
  })
}

export { DOCUMENT_QUERY_KEYS as QUERY_KEYS, MUTATION_KEYS }
