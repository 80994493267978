import { useCADPageStore } from '@/state/cad'
import { useOperationSteps } from '../queries/operation_steps'

export const useGetInclusiveGroupIds = () => {
  const { isLoading, steps } = useOperationSteps()
  const operationStep = useCADPageStore((state) => state.operationStep)

  if (isLoading || !operationStep) return null
  const activeStep = steps.find((step) => step.id === operationStep.stepId)
  const stepIsActive = Boolean(operationStep?.isActive)

  if (!activeStep || !stepIsActive) return null
  const stepNumber = activeStep?.order_number

  return [
    ...new Set(steps.slice(0, stepNumber).flatMap((s) => s.assembly_group_ids)),
  ]
}
