/**
 * Application URLs
 */
export const BASE_URL = __API_URL__ ?? 'http://localhost:8000'
export const BASE_API_URL = `${BASE_URL}/api`
export const MAX_VIEWS_IN_DOCUMENT = 1

export const SENTRY_DSN = __SENTRY_DSN__ ?? ''
export const AMPLITUDE_API_KEY = __AMPLITUDE_API_KEY__ ?? ''
export const FRONTEND_REVISION = __FRONTEND_REVISION__ ?? ''
export const INFRASTRUCTURE_ENVIRONMENT_TYPE =
  __INFRASTRUCTURE_ENVIRONMENT_TYPE__ ?? undefined
export const CSRF_TOKEN_NAME = __CSRF_TOKEN_NAME__ ?? 'csrftoken'
export const LOGIN_REDIRECT_NAME = 'redirect'

export const X_AMZ_DATE_FORMAT = 'YYYYMMDDTHHmmssZ'

export const WAND_COLOR = 0xf5a50a

export const COLOR_OPTIONS = [
  {
    value: 0xf5a50a,
    name: 'Tan',
  },
  {
    value: 0xff0000,
    name: 'Red',
  },
  {
    value: 0xff7f00,
    name: 'Orange',
  },
  {
    value: 0xfade66,
    name: 'Yellow',
  },
  {
    value: 0x00e200,
    name: 'Green',
  },
  {
    value: 0x0080ff,
    name: 'Blue',
  },
  {
    value: 0x6900d2,
    name: 'Purple',
  },
  {
    value: 0x983d10,
    name: 'Brown',
  },
]
export const HIGHLIGHT_GREEN = 0x00ff9d
export const HIGHLIGHT_BLUE = 0x3a8bff
export const SELECTED_PURPLE = 0x6200ea
export const HIGHLIGHT_PURPLE = 0x9575cd

// export const HIGHLIGHT_BLUE = 0x5884e7
// export const HIGHLIGHT_GREEN = 0x20fc03
// export const HIGHLIGHT_GREEN = 0x3ed7c4
