import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { ErrorBoundary } from '@sentry/react'
import { ResizableBox } from 'react-resizable'
import dayjs from 'dayjs'
import { useLocation, useParams } from 'wouter'

import { Image } from '@/components/pdf/Image'
import { useAppStore } from '@/state'
import { useDocumentState } from '@/state/document'
import {
  ImageTemplate,
  TemplateAttribute,
  useUpdateTemplateElement,
  ViewTemplate,
} from '@/services/hooks/template_attributes'
import { useDeleteTemplateAttribute } from '@/services/queries/template_attributes'
import { cn } from '@/utils'
import { Spinner } from '@/components/ui/spinner'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { VerticalDotsIcon } from '@/components/icons/VerticalDotsIcon'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'

export enum ImageTypeEnum {
  Image = 'image',
  View = 'view',
}
type DraggableImageProps = {
  documentPageId: string
  userFacingDownloadUrl: string
  imageId?: string | null
  isAddingAnnotation: boolean
  downloadUrl: string
  type: ImageTypeEnum
  isReadOnly?: boolean
  className?: string
  timestamp?: string
  imageTemplate: TemplateAttribute<ImageTemplate | ViewTemplate>
}
export const DraggableImage = ({
  documentPageId,
  userFacingDownloadUrl,
  imageId,
  isAddingAnnotation,
  downloadUrl,
  type,
  isReadOnly,
  className,
  timestamp,
  imageTemplate,
}: DraggableImageProps) => {
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])
  const containerRef = useRef<HTMLDivElement>(null)
  const zoom = useDocumentState((state) => state.zoom)
  const zoomRatio = useMemo(() => zoom / 100, [zoom])
  const selectElement = useDocumentState((state) => state.setSelectedElement)
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPageId &&
      selectedElement?.id === imageTemplate?.id,
    [selectedElement, documentPageId, imageTemplate?.id],
  )
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isHoveringImage, setIsHoveringImage] = useState(false)
  const [{ position, delta }, setPosition] = useState({
    position: {
      x: 0,
      y: 0,
    },
    delta: INITIAL_DELTA,
  })
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  })
  const positionData = useMemo(
    () => imageTemplate?.template_values?.position,
    [imageTemplate],
  )
  const imageSize = useMemo(
    () => imageTemplate?.template_values?.size,
    [imageTemplate],
  )
  const imagePosition = useMemo(
    () => imageTemplate?.template_values?.imagePosition || 'relative',
    [imageTemplate],
  )
  const { mutateAsync: updateTemplate } = useUpdateTemplateElement({
    documentPageId,
  })

  const {
    isPending: isDeletingTemplate,
    mutateAsync: deleteTemplateAttribute,
  } = useDeleteTemplateAttribute()

  const { attributes, isDragging, listeners, transform, setNodeRef } =
    useDraggable({
      disabled: isAddingAnnotation || isReadOnly || isMenuOpen,
      id: imageTemplate?.id as string,
      data: { type },
    })

  const deleteImageHandler = useCallback(async () => {
    await deleteTemplateAttribute({
      templateAttributeId: imageTemplate?.id as string,
    })
  }, [imageTemplate, deleteTemplateAttribute])

  const onRemove = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        await deleteImageHandler()
      }
    },
    [deleteImageHandler],
  )

  const updateImageSizeHandler = useCallback(
    async (size: { width: number; height: number }) => {
      await updateTemplate({
        payload: {
          size,
        },
        data_type: type,
        templateAttributeId: imageTemplate?.id as string,
      })
    },
    [type, imageTemplate?.id, updateTemplate],
  )

  useEffect(() => {
    if (!imageSize) return
    setContainerDimensions(imageSize)
  }, [imageSize])

  useEffect(() => {
    let resizeObserver: ResizeObserver
    if (containerRef.current && !imageSize) {
      const rect = containerRef.current.getBoundingClientRect()
      setContainerDimensions({
        width: rect.width,
        height: rect.height,
      })

      resizeObserver = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect
        setContainerDimensions({
          width,
          height,
        })
      })

      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [containerRef, imageSize])

  useEffect(() => {
    if (isSelected) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected])

  useEffect(() => {
    if (!(transform?.x || transform?.y)) return
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: (transform?.x || 0) / zoomRatio,
        y: (transform?.y || 0) / zoomRatio,
      },
    }))
  }, [transform, zoomRatio])

  useEffect(() => {
    if (!positionData) return
    setPosition({
      position: positionData,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, positionData])

  const shouldShowImageMenu = useMemo(
    () => isSelected || isHoveringImage || isMenuOpen,
    [isSelected, isHoveringImage, isMenuOpen],
  )

  const viewUploadsInProgress = useAppStore(
    (state) => state.viewUploadsInProgress,
  )

  const isViewJobProcessing = useMemo(() => {
    if (!imageId || viewUploadsInProgress.length === 0) {
      return false
    }

    return Boolean(viewUploadsInProgress.find((job) => job.viewId === imageId))
  }, [imageId, viewUploadsInProgress])

  return (
    <>
      <div
        data-testid="draggable-image-container"
        className="flex flex-1 p-px box-content relative"
        ref={containerRef}
        style={{
          minHeight: 180,
          minWidth: 320,
        }}
      />
      <div
        {...attributes}
        {...listeners}
        onMouseEnter={() => setIsHoveringImage(true)}
        onMouseLeave={() => setIsHoveringImage(false)}
        className={cn(
          'border rounded-md flex flex-1 p-px box-content absolute',
          className,
          {
            'cursor-move': !isReadOnly,
            'cursor-default': isReadOnly,
            'shadow-md': isDragging,
            'shadow-gray-200': isDragging,
            'bg-sky-200/10': isDragging,
            'z-[1]': !isDragging,
            'z-[100]': isDragging,
          },
        )}
        style={{
          translate: `${delta?.x || 0}px ${delta?.y || 0}px`,
          top: position?.y || 0,
          left: position?.x || 0,
          borderColor: isSelected ? '#818cf8' : 'transparent',
        }}
      >
        {isViewJobProcessing ||
          (isDeletingTemplate && (
            <div className="absolute h-full w-full bg-zinc-400/60">
              <Spinner />
            </div>
          ))}
        <div className="absolute top-2 right-2 z-50">
          {shouldShowImageMenu && (
            <ImageMenu
              downloadUrl={downloadUrl}
              onMenuOpenChange={setIsMenuOpen}
              userFacingDownloadUrl={userFacingDownloadUrl}
              imageId={imageId}
              type={type}
            />
          )}
        </div>
        <ResizableBox
          width={containerDimensions.width}
          height={containerDimensions.height}
          onResize={(event) => {
            event.stopPropagation()
          }}
          onResizeStart={(event) => {
            event.stopPropagation()
          }}
          maxConstraints={[740, 420]}
          onResizeStop={async (_event, data) => {
            await updateImageSizeHandler(data.size)
            selectElement({
              documentPageId,
              id: imageTemplate?.id as string,
              isRemovable: true,
            })
          }}
          resizeHandles={!isReadOnly && isSelected ? ['se'] : []}
        >
          <div
            ref={setNodeRef}
            className={cn(
              'flex flex-1 flex-col justify-center items-center h-full w-full',
              {
                'pointer-events-none': isAddingAnnotation || isDragging,
                'pointer-events-auto': !isAddingAnnotation && !isDragging,
              },
            )}
          >
            <div
              className={cn('h-full', {
                'h-full': imagePosition === 'relative',
              })}
            >
              <ErrorBoundary fallback={<div>Failed to load image</div>}>
                <div
                  className={cn(
                    'h-full cursor-move flex justify-center flex-col items-center',
                    {
                      'cursor-move': !isReadOnly,
                      'cursor-default': isReadOnly,
                      'pointer-events-none': isDragging,
                    },
                  )}
                >
                  <Image
                    src={downloadUrl}
                    className={cn('max-h-full h-auto w-auto', {
                      'cursor-move': !isReadOnly,
                      'cursor-default': isReadOnly,
                    })}
                    data-testid="draggable-image"
                  />
                </div>
              </ErrorBoundary>
            </div>
            {timestamp && (
              <div className="text-xs mb-2 font-medium text-gray-500">
                {dayjs(timestamp).format('MMM DD, YYYY hh:mm A')}
              </div>
            )}
          </div>
        </ResizableBox>
      </div>
    </>
  )
}

type ImageMenuProps = {
  downloadUrl: string
  imageId?: string | null
  userFacingDownloadUrl: string
  onMenuOpenChange: (open: boolean) => void
  type: ImageTypeEnum
}
const ImageMenu = ({
  downloadUrl,
  imageId,
  userFacingDownloadUrl,
  onMenuOpenChange,
  type,
}: ImageMenuProps) => {
  const [, setLocation] = useLocation()
  const { toast } = useToast()
  const { projectId, documentId } = useParams<{
    projectId: string
    documentId: string
  }>()
  const onDownloadHandler = useCallback(async (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    try {
      const response = await fetch(downloadUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = userFacingDownloadUrl

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: `There was a problem downloading the image: ${error}`,
      })
    }
  }, [])

  const cadLink = useMemo(
    () => `/p/${projectId}/document/${documentId}/cad?viewId=${imageId}`,
    [imageId],
  )

  const onOpenInCadHandler = useCallback(
    (event: any) => {
      event.stopPropagation()
      event.preventDefault()
      setLocation(cadLink)
    },
    [cadLink],
  )

  const onCopyUrlHandler = useCallback(
    async (event: any) => {
      event.stopPropagation()
      event.preventDefault()

      try {
        await navigator.clipboard.writeText(
          `${document.location.origin}${cadLink}`,
        )
        toast({
          variant: 'success',
          description: 'Image URL copied to clipboard',
        })
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: `There was a problem generating the image URL: ${error}`,
        })
      }
    },
    [cadLink],
  )

  return (
    <DropdownMenu onOpenChange={onMenuOpenChange}>
      <DropdownMenuTrigger
        className="h-auto px-2 py-1 rounded-md bg-transparent hover:bg-zinc-100"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <VerticalDotsIcon className="w-3 h-3 stroke-gray-800" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-2 z-[100]">
        {type === ImageTypeEnum.View && (
          <>
            <DropdownMenuItem className="p-0">
              <Button
                variant="ghost"
                className="w-full"
                onClick={onOpenInCadHandler}
              >
                Open in CAD
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem className="p-0">
              <Button
                variant="ghost"
                className="w-full"
                onClick={onCopyUrlHandler}
              >
                Copy URL
              </Button>
            </DropdownMenuItem>
          </>
        )}
        <DropdownMenuItem className="p-0">
          <Button
            variant="ghost"
            className="w-full"
            onClick={onDownloadHandler}
          >
            Download
          </Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default DraggableImage
