import { useRef } from 'react'
import { ErrorBoundary } from '@sentry/react'
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { DocumentPDFPreview } from '@/components/pdf/document'
import { Spinner } from '@/components/ui/spinner'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

export const DownloadPDFDialogContent = ({
  onDownload,
}: {
  onDownload: () => void
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const { data: documentData } = useDocumentPageQuery()

  const documentName = documentData?.doc?.name || 'document'
  const versionNumber = documentData?.documentVersion?.version_number || 1

  return (
    <DialogContent
      className="sm:max-w-[425px] min-h-16"
      ariaLabel="PDF Download dialog"
    >
      <ErrorBoundary fallback={<div>Failed to generate PDF file</div>}>
        <DialogHeader>
          <DialogTitle>PDF Download</DialogTitle>
          <DialogDescription>
            Your pdf will be downloaded shortly
          </DialogDescription>
        </DialogHeader>
        <div>
          <DocumentPDFPreview
            onRender={({ pdfUrl, pdfBlob }) => {
              const link = linkRef.current
              if (!link) {
                return
              }

              const fileName = `${documentName}_v${versionNumber}.pdf`
              link.href = pdfUrl
              link.download = fileName

              // @ts-expect-error - IE download
              const isIE = window.navigator.msSaveBlob

              if (isIE) {
                // @ts-expect-error - IE download
                window.navigator.msSaveBlob(pdfBlob, fileName)
              } else {
                link.click()
              }

              onDownload()
            }}
          />
          <Spinner />
        </div>
        <a ref={linkRef} href="#"></a>
      </ErrorBoundary>
    </DialogContent>
  )
}
