import { useMutation } from '@tanstack/react-query'
import queryClient from '@/queryClient'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { deleteDocumentPageAssemblyGroupID } from '@/lib/api/client'
import { QUERY_KEYS as DOCUMENT_PAGES_QUERY_KEYS } from '@/services/queries/document_pages/queryKeys'
import { useCADPageStore } from '@/state/cad'
import { QUERY_KEYS as VIEWS_QUERY_KEYS } from '../views'

import type { DocumentPage } from '@/lib/api/client'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'
import { useToggleStepCardsList } from '@/pages/CADPage/components/AssemblyOrderPanel/StepCardList/hooks/useToggleStepCardsList'
import { useOperationSteps } from './useOperationSteps'

export const useDeletePartFromOperation = () => {
  const { documentId } = useCadPageParams()
  const { steps } = useOperationSteps()
  const { toast } = useToast()
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const getCadPageState = useCADPageStore((state) => state.getState)
  const setCadPageState = useCADPageStore((state) => state.setState)
  const toggleStepCardsList = useToggleStepCardsList()

  return useMutation({
    mutationFn: async ({
      assemblyGroupId,
      documentPageId,
      documentVersionId,
      documentPageAssemblyReferenceId,
    }: {
      assemblyGroupId: string
      documentPageId: string
      documentVersionId: string
      documentPageAssemblyReferenceId: number
    }) => {
      await deleteDocumentPageAssemblyGroupID(documentPageAssemblyReferenceId)
      return {
        assemblyGroupId,
        documentPageId,
        documentVersionId,
        documentPageAssemblyReferenceId,
      }
    },

    onSuccess: async ({
      assemblyGroupId,
      documentPageId,
      documentVersionId,
    }) => {
      await queryClient.invalidateQueries({
        queryKey: [
          DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId },
        ],
        refetchType: 'all',
      })

      await queryClient.invalidateQueries({
        queryKey: [VIEWS_QUERY_KEYS.VIEWS],
        refetchType: 'all',
      })

      const isActive = activeOperationStep?.stepId === documentPageId
      const selectFromCad = activeOperationStep?.selectFromCad
      if (isActive && !selectFromCad) {
        const selectedParts = getCadPageState().selectedParts

        setCadPageState({
          selectedParts: selectedParts.filter((id) => id !== assemblyGroupId),
          operationStep: {
            documentId,
            stepId: documentPageId,
            isActive: false,
            selectFromCad: false,
          },
        })

        await toggleStepCardsList({
          steps,
          previousSteps: steps,
          documentVersionId,
          isReadOnly: false,
        })
      }
    },

    onMutate: ({ assemblyGroupId, documentPageId, documentVersionId }) => {
      const queryKey = [
        DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
        { documentVersionId },
      ]

      const previousDocumentPages = (queryClient.getQueryData(queryKey) ||
        []) as DocumentPage[]

      queryClient.setQueryData(queryKey, (old: DocumentPage[]) => {
        if (!old) return []

        return old.map((doc) => {
          if (doc.id === documentPageId) {
            return {
              ...doc,
              documentpageassemblygroupids:
                doc.documentpageassemblygroupids.filter(
                  (id) => id.assembly_group_id !== assemblyGroupId,
                ),
            }
          }
          return doc
        })
      })

      return { previousDocumentPages }
    },

    onError: (error, { documentVersionId }, context) => {
      console.error('Failed to remove part from operation', error)

      queryClient.setQueryData(
        [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
        context?.previousDocumentPages || [],
      )

      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}
