import { cn } from '@/utils'
import { useListViewsTemplates } from '@/services/hooks/template_attributes'
import DraggableImage, { ImageTypeEnum } from '../DraggableImage'
import type { DocumentPage, View } from '@/lib/api/client'

interface OperationViewsProps {
  documentPage: DocumentPage
  isReadOnly: boolean
  views: View[]
}

export const OperationViews = ({
  documentPage,
  isReadOnly,
  views,
}: OperationViewsProps) => {
  const viewTemplates = useListViewsTemplates(documentPage.id)

  return (
    <div
      className="flex flex-1 justify-between"
      style={{
        maxHeight: 360,
      }}
    >
      <div className="flex flex-col gap-2 flex-1">
        <div
          className="flex gap-2"
          style={{
            height: '50%',
          }}
        >
          {viewTemplates
            .sort(
              (a, b) =>
                (b.template_values?.position?.x || 0) -
                (a.template_values?.position?.x || 0),
            )
            .map((template, i) => {
              const baseUrl = window.location.origin
              const placeholderImage = `${baseUrl}/static/images/cad-placeholder.png`

              const userFacingDownloadUrl = ''
              const isAddingAnnotation = false

              const viewId = template.template_values?.viewId
              const view = views.find((view) => view.id === viewId)

              const downloadUrl = view?.download_url || placeholderImage

              return (
                <div
                  key={i}
                  className={cn(
                    'flex flex-col justify-between top-0 left-0 flex-1',
                    {
                      [template.template_values.imagePosition || 'relative']:
                        true,
                    },
                  )}
                  style={{
                    maxWidth: '49%',
                    maxHeight: 180,
                  }}
                >
                  <DraggableImage
                    documentPageId={documentPage?.id as string}
                    userFacingDownloadUrl={userFacingDownloadUrl}
                    isAddingAnnotation={isAddingAnnotation}
                    downloadUrl={downloadUrl}
                    imageTemplate={template as any}
                    type={ImageTypeEnum.View}
                    imageId={viewId}
                    isReadOnly={isReadOnly}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
