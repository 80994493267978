import { useShallow } from 'zustand/react/shallow'

import { CaretDownIcon } from '@radix-ui/react-icons'
import { ExplodeIcon } from '@/components/icons/ExplodeIcon'
import { ExplosionLineIcon } from '@/components/icons/ExplosionLineIcon'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { ControlButton } from '../ControlButton'
import { SaveExplosionsButton } from './SaveExplosionsButton'

import { ExplosionsState, useCADPageStore } from '@/state/cad'
import { useCADQuery } from '@/services/queries/cads/queries/useCADQuery'
import { useAutoSelectedExplodedPart } from './hooks/useAutoSelectedExplodedPart'

export const ExplosionsToolbar = () => {
  const { isLoading, data: cadData } = useCADQuery()
  const gltf = cadData.gltf

  const autoSelectExplodedPart = useAutoSelectedExplodedPart()

  const toggleExplosions = useCADPageStore((state) => state.toggleExplosions)
  const disableSectioning = useCADPageStore((state) => state.disableSectioning)
  const linesEnabled = useCADPageStore((state) => state.isExplosionLinesEnabled)
  const explosionsState = useCADPageStore((state) => state.explosionsState)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const isActive = explosionsState !== ExplosionsState.DISABLED

  if (isLoading || !gltf) return null

  return (
    <div>
      <DropdownMenu open={isActive} modal={false}>
        <DropdownMenuTrigger>
          <ControlButton
            isActive={isActive}
            asChild
            title="Explosions"
            icon={
              <div className="flex flex-row items-center">
                <ExplodeIcon className="w-4 h-4" />
                <CaretDownIcon />
              </div>
            }
            onClick={() => {
              disableSectioning()
              autoSelectExplodedPart()
              toggleExplosions()
            }}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex flex-row min-w-0">
          <DropdownMenuItem>
            <ControlButton
              title="Lines"
              icon={<ExplosionLineIcon className="w-4 h-4" />}
              isActive={linesEnabled}
              onClick={() => {
                setCadPageState({
                  isExplosionLinesEnabled: !linesEnabled,
                })
              }}
            />
          </DropdownMenuItem>
          <SaveExplosionsButton />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
