import { ThreeEvent } from '@react-three/fiber'
import { useShallow } from 'zustand/react/shallow'

import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { useCADQuery } from '@/services/queries/cads'
import { useCADPageStore } from '@/state/cad'
import {
  Selectable,
  SelectionEvent,
  useSelectable,
} from '@/pages/CADPage/hooks'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { RawAssemblyTree } from '@/state'

import { CADPageState } from '@/state/cad'
import { useAddPartsFromCAD } from '../../AssemblyOrderPanel/StepCardList/hooks/useAddPartsFromCAD'

type ModelState = {
  getState: CADPageState['getState']
  setState: CADPageState['setState']
  updateTransparency: CADPageState['updateTransparency']
  updatePartColor: CADPageState['updatePartColor']
  isTransparent: CADPageState['isTransparent']
  isColored: CADPageState['isColored']
}

/**
 * Custom hook that handles interactions with the CAD model.
 *
 * @returns {void | ModelHandlers}
 * Returns the model handlers if the data is loaded, otherwise returns nothing.
 */
export const useModelHandlers = () => {
  const {
    isLoading: isLoadingAssemblyTree,
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { isLoading: isLoadingCad, data } = useCADQuery()
  const { onAddPartFromCAD } = useAddPartsFromCAD()
  const gltf = data?.gltf

  const selectable = useSelectable({
    onSelect: onAddPartFromCAD,
    onMultiSelect: onAddPartFromCAD,
  })

  const getState = useCADPageStore(useShallow((state) => state.getState))
  const setState = useCADPageStore(useShallow((state) => state.setState))
  const isColored = useCADPageStore(useShallow((state) => state.isColored))
  const isTransparent = useCADPageStore(
    useShallow((state) => state.isTransparent),
  )
  const updatePartColor = useCADPageStore(
    useShallow((state) => state.updatePartColor),
  )
  const updateTransparency = useCADPageStore(
    useShallow((state) => state.updateTransparency),
  )
  if (isLoadingCad || isLoadingAssemblyTree || !gltf || !assemblyTree) return

  return createModelHandlers(selectable, gltf, assemblyTree, {
    getState,
    setState,
    isColored,
    isTransparent,
    updatePartColor,
    updateTransparency,
  })
}

/**
 * Creates handlers for interacting with the CAD model.
 *
 * @param gltf
 * @param assemblyTree
 * @param state
 * @param selectable - Object responsible for handling selection.
 * @returns Handlers for pointer enter, pointer down, and pointer up/leave events.
 */
export const createModelHandlers = (
  selectable: Selectable,
  gltf: GLTFObject,
  assemblyTree: RawAssemblyTree,
  state: ModelState,
) => {
  /*
   * Handler for when the pointer enters a part in the CAD model.
   */
  const handlePointerEnter = (event: ThreeEvent<PointerEvent>) => {
    if (state.getState().isRotating || state.getState().isDragging) return
    if (gltf.transformControls) {
      const helper = gltf.transformControls.getHelper()
      const raycaster = gltf.transformControls.getRaycaster()
      const intersectingControls = raycaster.intersectObject(helper).length > 0
      const draggingControls = gltf?.transformControls?.isDragging
      if (intersectingControls || draggingControls) {
        return
      }
    }

    const parentName = event.object?.parent?.name as string
    selectable.onSelectHover(event, parentName)
  }

  /*
   * Handler for when the pointer is pressed down on a part in the CAD model.
   */
  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    if (state.getState().isRotating || state.getState().isDragging) return
    if (gltf.transformControls) {
      const helper = gltf.transformControls.getHelper()
      const raycaster = gltf.transformControls.getRaycaster()
      const intersectingControls = raycaster.intersectObject(helper).length > 0
      const draggingControls = gltf?.transformControls?.isDragging
      if (intersectingControls || draggingControls) {
        return
      }
    }

    state.setState({ point: [event.point.x, event.point.y, event.point.z] })

    const wandSelected = state.getState().wandSelected
    const parentName = event?.object?.parent?.name as string
    const selection = gltf.getObjectByName(parentName)
    const node = assemblyTree.nodes.find((node) => node.instance === parentName)

    if (!selection || !selection.visible || !node) return

    //
    // Scroll part into view from the Assembly Tree
    //

    const el = document.getElementById(`tree-node-container-${node.uuid}`)
    el?.scrollIntoView({ behavior: 'smooth' })
    el?.classList.add('bg-primary-10', 'border', '!border-primary-50')
    setTimeout(() => {
      el?.classList.remove('bg-primary-10', 'border', '!border-primary-50')
    }, 1500)

    //
    // Setting part colors
    //
    const shouldSetColor =
      selection &&
      selection.name &&
      wandSelected &&
      wandSelected !== 'transparency'
    const isPartColored =
      selection && selection.name && state.isColored(selection.name)

    if (shouldSetColor && !isPartColored) {
      gltf.setColor(selection.name, wandSelected)
      state.updatePartColor(selection.name, wandSelected, true)
    }

    if (shouldSetColor && isPartColored) {
      gltf.setColor(selection.name, wandSelected)
      state.updatePartColor(selection.name, wandSelected, false)
    }

    //
    // Setting part transparency
    //

    const shouldSetTransparency =
      selection &&
      selection.name &&
      wandSelected &&
      wandSelected === 'transparency'

    const isPartTransparent =
      selection && selection.name && state.isTransparent(selection.name)

    if (shouldSetTransparency && !isPartTransparent) {
      gltf.setTransparency(selection.name)
      state.updateTransparency(selection.name)
    }

    if (shouldSetTransparency && isPartTransparent) {
      gltf.resetTransparency([selection.name])
      state.updateTransparency(selection.name)
    }

    //
    // Select parts (for multi-select)
    //

    const shouldSelectPart = selection && selection.name && !wandSelected
    if (shouldSelectPart) {
      selectable.handlers(node.uuid, { object: selection }).onSelectStart(event)
    }
  }

  /*
   * Handler for when the pointer is released or leves a part in the CAD model.
   */
  const handlePointerUpAndLeave = (event: SelectionEvent) => {
    if (gltf?.transformControls?.isDragging) return
    selectable.onSelectEnd(event)
  }

  return { handlePointerEnter, handlePointerDown, handlePointerUpAndLeave }
}
