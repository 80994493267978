import { cn } from '@/utils'
import { useShallow } from 'zustand/react/shallow'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'
import { AddPartIcon } from '@/components/icons/AddPartIcon'
import { Step } from '@/services/queries/operation_steps/types'

import { useCADPageStore } from '@/state/cad'
import { useAddPartsFromCAD } from './StepCardList/hooks/useAddPartsFromCAD'

export const AddPartFromCADButton = ({ step }: { step: Step }) => {
  const activeStep = useCADPageStore(useShallow((state) => state.operationStep))
  const { isLoading, toggleAddPartsFromCADMode } = useAddPartsFromCAD()

  const isActive = activeStep?.stepId === step.id
  const selectFromCad = isActive && activeStep?.selectFromCad

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            disabled={isLoading}
            size="sm"
            variant="ghost"
            className="px-1 cursor-pointer hover:bg-purple-50"
            onMouseDown={(e) => {
              e.stopPropagation()
              toggleAddPartsFromCADMode(step)
            }}
          >
            <AddPartIcon
              className={cn('w-4 h-4 stroke-gray-400 hover:stroke-purple-600', {
                'stroke-purple-600': selectFromCad,
              })}
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Add Parts From CAD</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
