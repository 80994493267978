import { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { Box3, Vector3, PerspectiveCamera } from 'three'
import { useCADPageStore } from '@/state/cad'
import {
  DISTANCE_FACTOR,
  DEFAULT_CAMERA_NEAR,
  CAMERA_FAR_FACTOR,
} from '@/pages/CADPage/constants'

export const useBounds = (gltf?: GLTFObject) => {
  const { camera } = useThree() as { camera: PerspectiveCamera }

  const setCadPageState = useCADPageStore((state) => state.setState)
  const cameraPosition = useCADPageStore((state) => state.cameraPosition)
  const cameraUp = useCADPageStore((state) => state.cameraUp)
  const cameraZoom = useCADPageStore((state) => state.cameraZoom)
  const cameraQuaternion = useCADPageStore((state) => state.cameraQuaternion)
  const cameraFov = useCADPageStore((state) => state.cameraFov)
  const cameraNear = useCADPageStore((state) => state.cameraNear)
  const cameraFar = useCADPageStore((state) => state.cameraFar)
  const boundsMax = useCADPageStore((state) => state.boundsMax)
  const sceneCenter = useCADPageStore((state) => state.sceneCenter)

  useEffect(() => {
    if (!gltf) return

    const bounds = new Box3().setFromObject(gltf?.scene)
    const boundsSizes = bounds.getSize(new Vector3())
    const boundsMaxSize = Math.max(...boundsSizes.toArray())
    const center = bounds.getCenter(new Vector3())

    gltf.scene.position.sub(center)

    const worldPosition = new Vector3()
    gltf.scene.getWorldPosition(worldPosition)
    gltf.scene.position.sub(worldPosition)

    camera.near = cameraNear || DEFAULT_CAMERA_NEAR
    camera.far =
      cameraFar || CAMERA_FAR_FACTOR * DISTANCE_FACTOR * boundsMaxSize

    if (!sceneCenter) {
      setCadPageState({ boundsMax: boundsMax || boundsMaxSize })
      setCadPageState({ sceneCenter: sceneCenter || center })
      setCadPageState({ cameraNear: camera.near })
      setCadPageState({ cameraFar: camera.far })
    }

    if (cameraPosition.length) {
      camera.position.fromArray(cameraPosition)
    } else {
      const position = [
        boundsMaxSize * DISTANCE_FACTOR,
        boundsMaxSize * DISTANCE_FACTOR,
        boundsMaxSize * DISTANCE_FACTOR,
      ]
      camera.position.fromArray(position)
      setCadPageState({ cameraPosition: position })
    }

    camera.fov = cameraFov
    camera.zoom = cameraZoom
    camera.up.fromArray(cameraUp)
    camera.quaternion.fromArray(cameraQuaternion)

    camera.updateProjectionMatrix()
  }, [gltf])
}
