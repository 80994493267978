import { useShallow } from 'zustand/react/shallow'

import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline'

import { useCADPageStore } from '@/state/cad'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useStepOrderNumber } from './hooks/useStepOrderNumber'
import { cn } from '@/utils'
import { isEmptyOrSpaces } from '@/utils'

export const AssemblyOrderHeader = () => {
  const { isLoading: isDocumentLoading, data: docData } = useDocumentPageQuery()

  const documentType = docData?.documentType

  const subAssemblyDocumentPageId = useCADPageStore(
    (state) => state.subAssemblyDocumentPageId,
  )
  const activeOperationStep = useCADPageStore((state) => state.operationStep)
  const isEditModeActive = useCADPageStore(
    useShallow((state) => state.isEditModeActive),
  )
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const { isLoading: isLoadingSteps, steps } = useOperationSteps()

  const subStep = subAssemblyDocumentPageId
    ? steps.find((s) => s.id === subAssemblyDocumentPageId)
    : undefined

  const {
    isLoading: isLoadingOrderNumber,
    data: { orderNumber },
  } = useStepOrderNumber({
    step: subStep,
  })

  if (isDocumentLoading || isLoadingSteps || isLoadingOrderNumber) {
    return (
      <div>
        <Skeleton />
      </div>
    )
  }

  if (!subAssemblyDocumentPageId || !subStep) {
    return (
      <div
        className={cn('text-md font-semibold px-2', {
          'text-primary-50': activeOperationStep && !isEditModeActive,
        })}
      >
        {documentType === 'project_tracker' ? 'Notes' : 'Operations'}
      </div>
    )
  }

  return (
    <div className="flex flex-col space-y-4">
      <Button
        type="button"
        className="rounded-full flex self-start items-center space-x-2 h-6 text-xs"
        onClick={() => {
          const parentDocumentPageId = subStep.parent || null
          setCadPageState({
            subAssemblyDocumentPageId: parentDocumentPageId,
          })
        }}
      >
        <ArrowLongLeftIcon className="h-5 w-5" />
        <span>Parent Operation</span>
      </Button>
      <div className="text-sm px-2">
        <span className="font-semibold">SubOperation of </span>{' '}
        <div className="bg-primary-10 text-primary-50 rounded-lg px-2 py-1 inline-block border border-primary-20">
          <span>
            {isEmptyOrSpaces(subStep.name)
              ? `Operation ${orderNumber}`
              : subStep.name}
          </span>
        </div>
      </div>
    </div>
  )
}
