import { isAssemblyNodeLocked } from '@/pages/CADPage/components/AssemblyTree/lib/isAssemblyNodeLocked'

import { useCADQuery } from '@/services/queries/cads'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useCADPageStore } from '@/state/cad'

import type { ASMTreeNode } from '@/state'

export const useIsAssemblyNodeLocked = ({ node }: { node?: ASMTreeNode }) => {
  const subAssemblyDocumentPageId = useCADPageStore(
    (state) => state.subAssemblyDocumentPageId,
  )

  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const { isLoading: isCadDataLoading, data: cadData } = useCADQuery()
  const tree = cadData.rawAssemblyTree

  const isLocked = isAssemblyNodeLocked({
    node,
    tree,
    steps,
    subAssemblyDocumentPageId,
  })

  return {
    isLoading: isLoadingSteps || isCadDataLoading,
    data: {
      isLocked,
    },
  }
}
