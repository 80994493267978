import { useRef, useEffect, useMemo, useCallback, useState } from 'react'
import debounce from 'lodash.debounce'
import {
  Cog6ToothIcon,
  PlusIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'
import { v4 as uuidv4 } from 'uuid'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { cn } from '@/utils'
import {
  useCreateDocumentNotes,
  useCreateLabel,
  useCreateTable,
} from '@/services/hooks/template_attributes'
import { AnnotationsToolbarMenuItem, Icon } from './AnnotationsToolbarMenuItem'
import { DEFAULT_MENUS, AnnotationsMenuItem } from './menus'
import {
  DEFAULT_STROKE_COLOR,
  DISABLED_STROKE_COLOR,
  STROKE_COLOR_VARIATIONS,
} from './constants'
import { useAnnotationsQuery } from './queries'
import { useAnnotations } from './hooks'
import { AnnotationSizes, IconStrokes } from './enums'
import { useDocumentState } from '@/state'
import { CreateDocumentVersionButton } from '@/components/versions/CreateDocumentVersionButton'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { ColorPallete } from '@/components/icons/ColorPallete'
import {
  ReaderIcon,
  TableIcon,
  ImageIcon,
  TextIcon,
} from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { CommentsButton } from '../CommentsButton'
import { UploadPhotoButton } from '../UploadPhotoButton'
import { CreateDocumentTemplateButton } from '../CreateDocumentTemplateButton'
import { useCADQuery } from '@/services/queries/cads'
import { DocumentTypeChoices } from '@/lib/api/client'
import { useIsReadOnly } from '@/pages/CADPage/hooks/useIsReadOnly'
import { useDocumentTemplateFlag } from '@/featureFlags/useDocumentTemplateFlag'

export const AnnotationsToolbar = () => {
  const {
    data: { isEnabled: isDocumentTemplateFlagEnabled },
  } = useDocumentTemplateFlag()
  const documentPage = useDocumentPageQuery()
  const [annotationMenus, setAnnotationMenus] = useState(DEFAULT_MENUS)
  const currentDocumentPageId = useDocumentState(
    (state) => state.currentDocumentPageId,
  )
  const annotationsToolbarRef = useRef<HTMLDivElement>(null)
  const {
    isAddingAnnotation,
    newAnnotation,
    setRefPosition,
    canDropAnnotation,
  } = useAnnotationsQuery()
  const {
    Icon: IconPlaceholder,
    id: iconPlaceholderId,
    size: iconPlaceholderSize,
    strokeColor: iconPlaceholderColor,
    startPoint: iconPlaceholderStartPoint,
  } = newAnnotation || {}

  useAnnotations()

  useEffect(() => {
    setAnnotationMenus((prev) => {
      const bubblesMenu = {
        id: 'bubbles',
        columns: 4,
        TriggerIcon: BubblesMenuItem('A') as any,
        menuItems: [] as any,
        tooltipContent: 'Bubble annotations',
      }
      const menuItems = Array.from(Array(26)).map((_, index) => ({
        id: `bubble-${index + 1}`,
        Icon: BubblesMenuItem(`${String.fromCharCode(65 + index)}`) as any,
        strokeColor: 'black',
        bubbleValue: String.fromCharCode(65 + index),
      }))
      bubblesMenu.menuItems = menuItems
      return [...prev, bubblesMenu]
    })

    return () => {
      setAnnotationMenus(DEFAULT_MENUS)
    }
  }, [])

  const onResizeHandler = useCallback(
    () =>
      debounce(([entry]: Array<ResizeObserverEntry>) => {
        const rect = entry.target.getBoundingClientRect()
        setRefPosition({ x: rect.x, y: rect.y })
      }, 300),
    [setRefPosition],
  )

  const resizeObserver = useMemo(
    () => new ResizeObserver(onResizeHandler()),
    [onResizeHandler],
  )

  useEffect(() => {
    const annotationsToolbar = annotationsToolbarRef.current
    if (annotationsToolbar) {
      resizeObserver.observe(annotationsToolbar)
    }

    return () => {
      if (annotationsToolbar) resizeObserver.unobserve(annotationsToolbar)
    }
  }, [annotationsToolbarRef, resizeObserver])

  return (
    <div
      className="flex absolute z-30 top-0 left-0 w-full bg-white h-12 shadow-md shadow-stone-200/70"
      ref={annotationsToolbarRef}
    >
      {isAddingAnnotation && IconPlaceholder && (
        <div
          className={cn(
            'fixed z-50 pointer-events-none p-0.5 box-content border-2 border-transparent',
            iconPlaceholderSize &&
              (AnnotationSizes[iconPlaceholderSize] ??
                `h-${iconPlaceholderSize} w-${iconPlaceholderSize}`),
          )}
          id={`icon-placeholder--${iconPlaceholderId}`}
        >
          {!iconPlaceholderStartPoint && (
            <IconPlaceholder
              className="h-full w-full stroke-1 opacity-60"
              strokeWidth={
                IconStrokes[iconPlaceholderSize || ''] ?? IconStrokes['small']
              }
              strokeColor={
                canDropAnnotation
                  ? iconPlaceholderColor || DEFAULT_STROKE_COLOR
                  : DISABLED_STROKE_COLOR
              }
            />
          )}
        </div>
      )}
      <div className="flex items-center mx-4 gap-3">
        <InsertElementMenu
          isDisabled={
            !currentDocumentPageId ||
            isAddingAnnotation ||
            !documentPage.data?.isLatestDocumentVersion
          }
          documentType={documentPage.data?.documentType}
        />
        {annotationMenus.map((menu) => (
          <AnnotationsToolbarMenu
            key={menu.id}
            menuId={menu.id}
            menuItems={menu.menuItems}
            columns={menu.columns}
            colorOptionsEnabled={menu.colorOptionsEnabled}
            TriggerIcon={menu.TriggerIcon}
            tooltipContent={menu.tooltipContent}
            isDisabled={
              !currentDocumentPageId ||
              isAddingAnnotation ||
              !documentPage.data?.isLatestDocumentVersion
            }
          />
        ))}
        <CommentsButton />
        <CreateDocumentVersionButton
          className="rounded-md hover:bg-gray-100 m-0 h-8 w-8 flex items-center justify-center"
          disabled={!documentPage.data?.isLatestDocumentVersion}
        />
        {isDocumentTemplateFlagEnabled && (
          <CreateDocumentTemplateButton
            isDisabled={!documentPage.data?.isLatestDocumentVersion}
            documentPageId={currentDocumentPageId}
            documentVersionId={documentPage.data?.documentVersion?.id}
          />
        )}
      </div>
    </div>
  )
}

const BubblesMenuItem = (value: string) => {
  const Component = () => (
    <div className="flex items-center justify-center">
      <span
        className="flex border font-bold border-black shadow-none m-0 rounded-full justify-center items-center text-sm p-2"
        style={{ borderWidth: 3, height: 20, width: 20 }}
      >
        {value}
      </span>
    </div>
  )

  return Component
}

type AnnotationsToolbarMenuProps = {
  menuItems: Array<AnnotationsMenuItem>
  menuId: string
  columns?: number
  colorOptionsEnabled?: boolean
  TriggerIcon: Icon
  isDisabled: boolean
  tooltipContent: string
}
const AnnotationsToolbarMenu = ({
  menuItems,
  menuId,
  columns,
  colorOptionsEnabled,
  TriggerIcon,
  isDisabled,
  tooltipContent,
}: AnnotationsToolbarMenuProps) => {
  const [selectedColor, setSelectedColor] = useState(DEFAULT_STROKE_COLOR)
  const [showColorOptions, setShowColorOptions] = useState(false)

  useEffect(() => {
    setShowColorOptions(false)
  }, [selectedColor])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        data-testid={`annotations-menu-${menuId}-button`}
        disabled={isDisabled}
        className={cn(
          'rounded-md hover:bg-gray-100 m-0 h-8 w-8 flex items-center justify-center',
          {
            'opacity-25': isDisabled,
          },
        )}
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="rounded-md hover:bg-gray-100 m-0 h-8 w-8 flex p-0 items-center justify-center"
                disabled={isDisabled}
              >
                <TriggerIcon className="h-6 w-full" />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="bg-black text-white">
              <p>{tooltipContent}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex flex-col min-w-0 max-w-44 px-1.5 py-2">
        {showColorOptions && (
          <div
            className="absolute max-w-[80px] inset-x-0 mx-auto bg-white border shadow rounded-md"
            style={{ transform: 'translateY(-105%)' }}
          >
            <AnnotationsColorOptions
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </div>
        )}
        {colorOptionsEnabled && (
          <div className="flex items-center justify-center mb-2">
            <span
              className="rounded-full m-1 text-center text-xs cursor-pointer"
              onClick={() => setShowColorOptions(!showColorOptions)}
            >
              <ColorPallete className="h-5 w-5" />
            </span>
          </div>
        )}
        <div
          className="grid min-w-0 max-w-44 gap-2"
          style={{
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            overflow: 'visible',
          }}
        >
          {menuItems.map((menuItem) => (
            <DropdownMenuItem
              data-testid={`annotations-menu-${menuId}-option`}
              key={`${menuItem.id}${menuItem.size ? `-${menuItem.size}` : ''}`}
              className="w-full h-full flex items-center justify-center p-0"
            >
              <AnnotationsToolbarMenuItem
                strokeColor={selectedColor}
                {...menuItem}
              />
            </DropdownMenuItem>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

type InsertElementMenuProps = {
  isDisabled: boolean
  documentType?: DocumentTypeChoices
}
const InsertElementMenu = ({
  isDisabled,
  documentType,
}: InsertElementMenuProps) => {
  const selectedPageId = useDocumentState(
    (state) => state.currentDocumentPageId,
  )

  const { mutateAsync: createTable } = useCreateTable()

  const { data: cadData } = useCADQuery()
  const documentPage = cadData?.documentPages.find(
    (page) => page.id === selectedPageId,
  )
  const { isLoading: readOnlyLoading, data: readOnlyData } = useIsReadOnly()

  const { mutate: createNotes } = useCreateDocumentNotes()

  const { mutate: createLabel } = useCreateLabel()

  const disableAddImage =
    !documentPage || readOnlyLoading || readOnlyData.isReadOnly

  const menuOptions = useMemo(
    () => [
      {
        title: 'Textbox',
        description: 'Space for notes',
        Icon: ReaderIcon,
        handler: () => {
          if (!documentPage) return
          createNotes({
            documentPageId: documentPage?.id as string,
            notesData: {
              content: '',
              notesPosition: 'absolute',
            },
          })
        },
        tooltip: 'Add Textbox',
      },
      {
        title: 'Label',
        description: 'Limited text space',
        Icon: TextIcon,
        handler: () => {
          if (!documentPage) return
          createLabel({
            documentPageId: documentPage?.id as string,
            labelData: {
              content: '',
              position: {
                x: 0,
                y: 190,
              },
            },
          })
        },
        tooltip: 'Add Textbox',
      },
      {
        title: 'CAD or Image',
        description: 'Add an image',
        Icon: ImageIcon,
        disabled: disableAddImage,
        Trigger: UploadPhotoButton,
        tooltip: 'Upload Image',
      },
      {
        title: 'Table',
        description: 'Generic tabular data',
        Icon: TableIcon,
        handler: async () => {
          const firstAccessor = `custom-column--${uuidv4()}`
          const secondAccessor = `custom-column--${uuidv4()}`
          const tableData = Array.from(Array(2)).map(() => ({
            [firstAccessor]: '',
            [secondAccessor]: '',
          }))

          const tableColumns = [
            {
              [firstAccessor]: '',
            },
            {
              [secondAccessor]: '',
            },
          ]

          await createTable({
            documentPageId: documentPage?.id as string,
            tableData: {
              columns: tableColumns,
              rows: tableData,
              type: 'generic-table',
              position: {
                x: 0,
                y: 190,
              },
            },
          })
        },
        tooltip: 'Insert Table',
      },
      {
        title: 'Parts Table',
        description: 'Additional parts table',
        Icon: Cog6ToothIcon,
        handler: async () => {
          await createTable({
            documentPageId: documentPage?.id as string,
            tableData: {
              columns: [],
              rows: [
                {
                  partName: '',
                  quantity: '',
                },
              ],
              type: 'parts-table',
              isManual: true,
              position: {
                x: 0,
                y: 190,
              },
            },
          })
        },
        tooltip: 'Insert Parts Table',
      },
      {
        title: 'Tools Table',
        description: 'Additional tools table',
        Icon: WrenchScrewdriverIcon,
        handler: async () => {
          await createTable({
            documentPageId: documentPage?.id as string,
            tableData: {
              columns: [],
              rows: [],
              type: 'tools-table',
              isManual: true,
              position: {
                x: 0,
                y: 190,
              },
            },
          })
        },
        tooltip: 'Insert Tools Table',
      },
    ],
    [disableAddImage, documentPage, createNotes, createTable],
  )

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        disabled={isDisabled}
        className={cn('rounded-md hover:bg-gray-100 m-0 h-8 w-8', {
          'opacity-25': isDisabled,
        })}
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="rounded-md hover:bg-gray-100 m-0 h-8 w-8 flex p-0 items-center justify-center"
                disabled={isDisabled}
              >
                <div className="w-full">
                  <PlusIcon className="h-6 w-full stroke-2" />
                </div>
              </Button>
            </TooltipTrigger>
            <TooltipContent className="bg-black text-white">
              <p>Add element to document</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex min-w-0 px-1.5 py-2">
        <ul className="w-full">
          {menuOptions.map(
            (
              { title, description, Icon, handler, tooltip, Trigger, disabled },
              i,
            ) => (
              <li
                key={`option-${i}`}
                className="flex flex-row items-center justify-between mb-2 last:mb-0 border-b last:border-b-0 order-gray-100"
              >
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      {Trigger && documentPage ? (
                        <Trigger
                          documentPage={documentPage}
                          isDisabled={disabled}
                          documentType={documentType}
                        >
                          <Icon className="h-7 w-7 text-blue-500" />
                          <div>
                            <p className="text-sm font-medium">{title}</p>
                            <p className="text-xs text-gray-500">
                              {description}
                            </p>
                          </div>
                        </Trigger>
                      ) : (
                        <Button
                          variant="outline"
                          className="flex flex-row items-center space-x-2 hover:bg-gray-100 border-0 shadow-none w-full justify-start text-left h-full p-2"
                          onClick={handler}
                          disabled={disabled}
                        >
                          <Icon className="h-7 w-7 text-blue-500" />
                          <div>
                            <p className="text-sm font-medium">{title}</p>
                            <p className="text-xs text-gray-500">
                              {description}
                            </p>
                          </div>
                        </Button>
                      )}
                    </TooltipTrigger>
                    <TooltipContent className="bg-black text-white">
                      <p>{tooltip}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </li>
            ),
          )}
        </ul>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

type AnnotationsColorOptionsProps = {
  selectedColor: string
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>
}
const AnnotationsColorOptions = ({
  selectedColor,
  setSelectedColor,
}: AnnotationsColorOptionsProps) => {
  return (
    <div className="flex mt-1 mb-2 justify-center flex-wrap ">
      {[DEFAULT_STROKE_COLOR, ...STROKE_COLOR_VARIATIONS].map((color) => (
        <span
          className={cn(
            'rounded-full h-4 w-4 m-1 text-center cursor-pointer hover:scale-125 transition-transform ease-in-out duration-100 border flex items-center justify-center text-xs',
          )}
          key={`color-option--${color}`}
          style={{
            backgroundColor: color,
            color: selectedColor === color ? 'white' : color,
            boxSizing: 'border-box',
          }}
          onClick={() => {
            setSelectedColor(color)
          }}
        >
          &#10003;
        </span>
      ))}
    </div>
  )
}
