import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { v4 as generateUUID } from 'uuid'
import { useListDocumentPagesQuery } from '@/services/queries/document_pages'
import { useListTemplateAttributes } from '../template_attributes'
import {
  createDocumentPage,
  createTemplateAttribute,
  listTemplateAttributes,
} from '@/lib/api/client'
import { MUTATION_KEYS } from './mutationKeys'

export const useDefaultDocumentTemplateConfig = ({
  documentVersionId,
}: {
  documentVersionId?: string | null
}) => {
  const { isLoading: isDocumentPagesLoading, data: pages } =
    useListDocumentPagesQuery({
      documentVersionId,
    })

  const defaultDocumentTemplatePage = useMemo(() => {
    return pages?.find((page) => page.template_values?.is_template)
  }, [pages])

  const { isLoading: isLoadingTemplates, data: defaultTemplateAttributes } =
    useListTemplateAttributes({
      documentPageId: defaultDocumentTemplatePage?.id,
    })

  const isLoading = isDocumentPagesLoading || isLoadingTemplates

  return {
    isLoading,
    data: {
      defaultDocumentTemplatePage,
      defaultTemplateAttributes: defaultTemplateAttributes || [],
    },
  }
}

export const useCreateDocumentTemplate = () => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_DOCUMENT_FROM_TEMPLATE],
    mutationFn: async ({
      documentPageId,
      documentVersionId,
    }: {
      documentPageId: string
      documentVersionId: string
    }) => {
      const templateAttributes = await listTemplateAttributes(documentPageId)
      const templateDocumentPage = await createDocumentPage(documentVersionId, {
        name: `template-of-${documentPageId}`,
        assembly_group_id: generateUUID(),
        template_values: {
          is_template: true,
        },
        parent_id: null,
      })

      const templatedPageId = templateDocumentPage.id
      const templateDataTypes = ['image', 'note', 'view', 'table']

      if (!templatedPageId) {
        throw new Error(
          'failed to create document template, missing templateId',
        )
      }

      const hasRemovedPartsTable = templateAttributes.find(
        (attr) =>
          attr.data_type === 'table' &&
          attr.template_values?.type === 'parts-table' &&
          attr.template_values?.removed === true,
      )

      if (!hasRemovedPartsTable) {
        await createTemplateAttribute(templatedPageId, {
          data_type: 'table',
          template_values: {
            type: 'parts-table',
            removed: true,
          } as any,
        })

        await createTemplateAttribute(templatedPageId, {
          data_type: 'table',
          template_values: {
            rows: [
              {
                partName: '',
                quantity: '',
              },
            ],
            type: 'parts-table',
            columns: [],
            isManual: true,
            position: {
              x: 4.676865780359863,
              y: -3.0001053155637294,
            },
          } as any,
        })
      }

      const hasRemovedToolsTable = templateAttributes.find(
        (attr) =>
          attr.data_type === 'table' &&
          attr.template_values?.type === 'parts-table' &&
          attr.template_values?.removed === true,
      )

      if (!hasRemovedToolsTable) {
        await createTemplateAttribute(templatedPageId, {
          data_type: 'table',
          template_values: {
            type: 'tools-table',
            removed: true,
          } as any,
        })

        await createTemplateAttribute(templatedPageId, {
          data_type: 'table',
          template_values: {
            rows: [],
            type: 'tools-table',
            columns: [],
            isManual: true,
            position: {
              x: 540.0015913776829,
              y: -1.998503199316012,
            },
            columnsSize: {
              toolName: 200,
            },
          } as any,
        })
      }

      for (const templateAttribute of templateAttributes) {
        const dataType = templateAttribute.data_type

        if (!templateDataTypes.includes(dataType)) {
          continue
        }

        const overrides: any = {}

        if (dataType === 'table') {
          overrides.rows = []
        }

        if (dataType === 'note') {
          overrides.content = '<p></p>'
        }

        if (dataType === 'view') {
          overrides.viewId = null
        }

        if (dataType === 'image') {
          overrides.imageId = null
        }

        await createTemplateAttribute(templatedPageId, {
          data_type: templateAttribute.data_type,
          template_values: {
            ...templateAttribute.template_values,
            ...overrides,
          },
        })
      }
    },
  })
}

export const useDeleteDocumentTemplate = () => {
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_DOCUMENT_FROM_TEMPLATE],
    mutationFn: async () => {},
  })
}
