import { useDraggable } from '@dnd-kit/core'
import { ReactNode } from 'react'

import { useSelectable } from '../../hooks'
import { DRAGGABLE_TYPE } from './constants'
import { ASMTreeNode, RawAssemblyTree, useCADPageStore } from '@/state'

export const DraggableTreeNodeContainer = ({
  className,
  children,
  enabled,
  node,
  tree,
  onMouseOver,
  onMouseOut,
}: {
  className?: string
  children: ReactNode
  enabled: boolean
  node: ASMTreeNode
  tree: RawAssemblyTree
  onMouseOver?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    node: ASMTreeNode,
  ) => void
  onMouseOut?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    node: ASMTreeNode,
  ) => void
}) => {
  const draggable = useDraggable({
    id: `assembly-tree-node-${node.uuid}`,
    data: { node, tree, type: DRAGGABLE_TYPE },
    disabled: !enabled,
  })

  const selectable = useSelectable({})

  const resetEditMode = useCADPageStore((state) => state.resetEditMode)
  const selectFromCad = useCADPageStore(
    (state) => state.operationStep?.selectFromCad,
  )

  return (
    <div
      id={`tree-node-container-${node.uuid}`}
      className={className}
      ref={draggable.setNodeRef}
      {...draggable.listeners}
      {...draggable.attributes}
      onMouseOver={(e) => {
        if (onMouseOver) {
          onMouseOver(e, node)
        }
      }}
      onMouseOut={(e) => {
        if (onMouseOut) {
          onMouseOut(e, node)
        }
      }}
      onMouseDown={(e) => {
        e.stopPropagation()
        const commandKey = e.metaKey || e.ctrlKey || e.shiftKey
        if (enabled && !commandKey && draggable.listeners) {
          if (selectFromCad) resetEditMode()
          draggable.listeners.onMouseDown(e)
        } else if (enabled && commandKey) {
          selectable.handlers(node.uuid).onSelectStart(e)
        } else if (!commandKey) {
          selectable.reset()
        }
      }}
    >
      {children}
    </div>
  )
}
