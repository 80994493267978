import { ASMTreeNode } from '@/state'
import { CollapsibleTreeNodeButton } from './CollapsibleTreeNodeButton'
import { HidePartButton } from './HidePartButton'
import { AttachedToOperationIndicator } from './AttachedToOperationIndicator'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import type { GLTFObject } from '@/lib/cad/GLTFObject'
import type { RawAssemblyTree } from '@/state'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useMemo } from 'react'
import { findAttachedStep } from './lib/findAttachedStep'
import { useStepOrderNumber } from '../AssemblyOrderPanel/hooks/useStepOrderNumber'

export const TreeNodeItem = ({
  tree,
  node,
  gltf,
  isCollapsible,
  isAttachedToOperationStep,
  level,
  showHidePartButton,
}: {
  tree: RawAssemblyTree
  node: ASMTreeNode
  gltf: GLTFObject
  isCollapsible: boolean
  isAttachedToOperationStep: boolean
  level: number
  showHidePartButton: boolean
}) => {
  const { steps } = useOperationSteps()

  const attachedStep = useMemo(
    () => findAttachedStep({ steps, tree, node }),
    [steps, tree, node],
  )

  const {
    data: { orderNumber },
  } = useStepOrderNumber({ step: attachedStep?.step })

  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild>
          <div
            data-testid={`tree-node-${node.instance}`}
            id={`tree-node-${node.uuid}`}
            className="flex items-center space-x-0 py-1"
            style={{
              paddingLeft: level * 10,
            }}
          >
            <CollapsibleTreeNodeButton enabled={isCollapsible} />
            <div className="flex items-center justify-between w-full pr-4">
              <div className="text-ellipsis overflow-hidden max-w-40">
                {node.display_name}
              </div>
              <div className="flex items-center space-x-2 justify-end">
                <HidePartButton
                  node={node}
                  isVisible={showHidePartButton}
                  gltf={gltf}
                />
                <AttachedToOperationIndicator
                  node={node}
                  isVisible={!isAttachedToOperationStep}
                  tree={tree}
                />
              </div>
            </div>
          </div>
        </TooltipTrigger>
        {isAttachedToOperationStep && (
          <TooltipContent className="bg-black text-white">
            {attachedStep && attachedStep.node.uuid === node.uuid && (
              <span>Attached to Operation {orderNumber}</span>
            )}
            {attachedStep && attachedStep.node.uuid !== node.uuid && (
              <span>
                Attached to Operation {orderNumber} through{' '}
                {attachedStep.node.display_name}
              </span>
            )}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  )
}
