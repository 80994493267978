import * as Sentry from '@sentry/browser'

export function setupMemoryMonitoring() {
  const performance = window.performance as any
  if (!performance || !performance.memory) {
    return
  }

  // Check memory usage every 3 minutes
  setInterval(() => {
    const memoryUsage = performance.memory.usedJSHeapSize / (1024 * 1024)
    if (memoryUsage > 500) {
      Sentry.captureMessage('High memory usage detected', {
        level: 'warning',
        extra: {
          memoryUsageMB: Math.round(memoryUsage),
          totalHeapSizeMB: Math.round(
            performance.memory.totalJSHeapSize / (1024 * 1024),
          ),
          heapLimitMB: Math.round(
            performance.memory.jsHeapSizeLimit / (1024 * 1024),
          ),
        },
      })
    }
  }, 180000)
}
