export const VIEW_WORKER_ACTIONS = {
  INIT: 'init',
  KICK_JOBS: 'kick_jobs',
  START_JOBS: 'start_jobs',
  UPLOAD_VIEW: 'upload_view',
  VIEWS_IN_PROGRESS: 'views_in_progress',
  VIEWS_COMPLETED: 'views_completed',
}

export const SERVICE_WORKER_ACTIONS = {
  DELETE_CACHE: 'delete_cache',
  ADD_TO_CACHE: 'add_to_cache',
  CONDITIONALLY_ADD_TO_CACHE: 'conditionally_add_to_cache',
  HANDLE_ERROR: 'handle_error',
  ERROR_EVENT: 'error_event',
}

export const ISOMETRIC_CONSTS = {
  ZOOM: 1,
  FOV: 45,
  QUATERNION: [
    -0.17940314685543646, 0.31052187450204366, 0.05980104895181215,
    0.931565623506131,
  ],
}

export const RESPONSE_TYPES = {
  UNAUTHORIZED: 401,
}
