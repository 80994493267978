import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import {
  createView,
  updateView,
  deleteView,
  listViews,
  listDocumentPages,
  listDocumentVersionViews,
  getView,
  View,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import { ViewInput } from '@/lib/api/client/types'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useToast } from '@/components/ui/use-toast'
import CustomError from '@/lib/api/CustomError'
import { useDocumentTemplateFlag } from '@/featureFlags/useDocumentTemplateFlag'
import {
  listTemplateAttributesQuery,
  useUpdateTemplateAttribute,
} from '../template_attributes'

type mutationViewProps = {
  onSuccess?: (view?: View) => void
  onMutate?: () => void
}

/*
 ** Views in cache need to be updated after 1 minute
 ** of inactivity to update the download URL
 */
export const VIEWS_CACHE_TIME = 1 * 1000 * 60

export const useCreateView = (props?: mutationViewProps) => {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_VIEW],
    mutationFn: async ({
      cadVersionId,
      documentVersionId,
      values,
    }: {
      cadVersionId: string
      documentVersionId: string
      values: ViewInput
    }) => {
      const view = await createView(cadVersionId, values)
      return { view, documentVersionId }
    },
    onMutate: () => {
      if (props?.onMutate) props.onMutate()
    },
    onSuccess: ({ view, documentVersionId }) => {
      const updatedView = {
        ...view,
        url: view.download_url,
      } as any
      delete updatedView.download_url
      const viewsUpdaterFn = (data: any) =>
        data ? [...data, updatedView] : [updatedView]
      queryClient.setQueriesData(
        {
          queryKey: [
            QUERY_KEYS.VIEWS,
            {
              cadVersionId: updatedView?.cad_version,
              documentVersionId,
            },
          ],
        },
        viewsUpdaterFn,
      )

      if (props?.onSuccess) props.onSuccess(view)
    },
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useUpdateView = (props?: mutationViewProps) => {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_VIEW],
    mutationFn: ({ viewId, values }: { viewId: string; values: ViewInput }) =>
      updateView(viewId, values),
    onSuccess: async (view: View) => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.VIEWS],
      })
      if (props?.onSuccess) props.onSuccess(view)
    },
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useDeleteView = (props?: {
  onSuccess?: (viewId: string) => void
}) => {
  const {
    data: { isEnabled: isDocumentTemplateFlagEnabled },
  } = useDocumentTemplateFlag()
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()

  const queryClient = useQueryClient()
  const { data: docData } = useDocumentPageQuery()

  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_VIEW],
    mutationFn: async ({ viewId }: { viewId: string }) => {
      if (isDocumentTemplateFlagEnabled) {
        const view = await getViewQuery({ viewId })
        const documentPageId = view.document_page
        if (documentPageId) {
          const templateAttributes = await listTemplateAttributesQuery({
            documentPageId,
          })
          const viewTemplateAttributes = templateAttributes.filter(
            (attr) =>
              attr.data_type === 'view' &&
              attr.template_values.viewId === viewId,
          )

          for (const attr of viewTemplateAttributes) {
            await updateTemplateAttribute({
              templateAttributeId: attr.id as string,
              payload: {
                data_type: 'view',
                template_values: {
                  ...attr.template_values,
                  viewId: null,
                } as any,
              },
            })
          }
        }
      }

      await deleteView(viewId)

      return { viewId }
    },
    onSuccess: ({ viewId }) => {
      const queryKey = [QUERY_KEYS.VIEW, { viewId }]
      const viewsUpdaterFn = (data: any) =>
        data?.filter((view: any) => view.id !== viewId)

      queryClient.setQueriesData(
        {
          queryKey: [
            QUERY_KEYS.VIEWS,
            {
              cadVersionId: docData?.version?.id,
              documentVersionId: docData?.documentVersion?.id,
            },
          ],
        },
        viewsUpdaterFn,
      )

      queryClient.removeQueries({ queryKey })

      if (props?.onSuccess) props.onSuccess(viewId)
    },
  })
}

export const useListDocumentVersionViews = ({
  cadVersionId,
  documentVersionId,
}: {
  cadVersionId?: string | null
  documentVersionId?: string | null
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.VIEWS, { cadVersionId, documentVersionId }],
    queryFn: async () => {
      if (!cadVersionId || !documentVersionId) return []
      return listDocumentVersionViews(documentVersionId)
    },
    enabled: Boolean(cadVersionId && documentVersionId),
    staleTime: VIEWS_CACHE_TIME,
  })
}

export const useListViews = ({
  cadVersionId,
  documentVersionId,
}: {
  cadVersionId?: string | null
  documentVersionId?: string | null
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.VIEWS, { cadVersionId, documentVersionId }],
    queryFn: async () => {
      try {
        const documentPages = documentVersionId
          ? await listDocumentPages(documentVersionId)
          : []
        const documentPageIds = new Set(documentPages.map((page) => page.id))
        const views = cadVersionId ? await listViews(cadVersionId) : []
        return views.filter((view) => {
          return view.document_page && documentPageIds.has(view.document_page)
        })
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: Boolean(cadVersionId) && Boolean(documentVersionId),
    staleTime: VIEWS_CACHE_TIME,
  })
}

export const listViewsQuery = (
  cadVersionId: string,
  documentVersionId: string,
) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.VIEWS, { cadVersionId, documentVersionId }],
    queryFn: () => {
      if (!cadVersionId) return []
      return listViews(cadVersionId)
    },
    staleTime: VIEWS_CACHE_TIME,
  })
}

export const useListPageViews = ({
  cadVersionId,
  documentVersionId,
}: {
  cadVersionId?: string | null
  documentVersionId?: string | null
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.VIEWS, { cadVersionId, documentVersionId }],
    queryFn: async () => {
      try {
        const documentPages = documentVersionId
          ? await listDocumentPages(documentVersionId)
          : []
        const documentPageIds = new Set(documentPages.map((page) => page.id))
        const views = cadVersionId ? await listViews(cadVersionId) : []
        return views.filter((view) => {
          return view.document_page && documentPageIds.has(view.document_page)
        })
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: Boolean(cadVersionId && documentVersionId),
    staleTime: VIEWS_CACHE_TIME,
  })
}

export const listPageViewsQuery = ({
  cadVersionId,
  documentVersionId,
}: {
  cadVersionId: string
  documentVersionId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.VIEWS, { cadVersionId, documentVersionId }],
    queryFn: () => {
      return listViews(cadVersionId)
    },
    staleTime: VIEWS_CACHE_TIME,
  })
}

export const getViewQuery = ({ viewId }: { viewId: string }) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.VIEW, { viewId }],
    queryFn: () => getView(viewId),
  })
}

export const useGetView = ({ viewId }: { viewId?: string | null }) => {
  return useQuery({
    queryKey: [QUERY_KEYS.VIEW, { viewId }],
    queryFn: () => getView(viewId as string),
    enabled: Boolean(viewId),
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
