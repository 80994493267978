import { useShallow } from 'zustand/react/shallow'

import { CaretDownIcon } from '@radix-ui/react-icons'
// import { ExplodeXIcon } from '@/components/icons/ExplodeXIcon'
// import { ExplodeYIcon } from '@/components/icons/ExplodeYIcon'
// import { ExplodeZIcon } from '@/components/icons/ExplodeZIcon'
import { CrossSectionIcon } from '@/components/icons/CrossSectionIcon'
import { SectionRotateIcon } from '@/components/icons/SectionRotateIcon'
import { SectionTranslateIcon } from '@/components/icons/SectionTranslateIcon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { ControlButton } from '../ControlButton'
import { SectionState, useCADPageStore } from '@/state/cad'

export const CrossSectionButton = () => {
  const setCadPageState = useCADPageStore((state) => state.setState)
  const state = useCADPageStore(useShallow((state) => state.sectionState))
  const rotateEnabled = useCADPageStore((state) => state.rotateEnabled)
  const translateEnabled = useCADPageStore((state) => state.translateEnabled)
  const toggleSectioning = useCADPageStore((state) => state.toggleSectioning)
  const disableExplosions = useCADPageStore((state) => state.disableExplosions)
  const isActive = state === SectionState.ENABLED

  return (
    <div>
      <DropdownMenu open={isActive} modal={false}>
        <DropdownMenuTrigger>
          <ControlButton
            asChild
            title="Section"
            isActive={isActive}
            onClick={() => {
              disableExplosions()
              toggleSectioning()
            }}
            icon={
              <div className="flex flex-row items-center">
                <CrossSectionIcon className="w-4 h-4" />
                <CaretDownIcon />
              </div>
            }
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={10} className="flex flex-row min-w-0">
          <DropdownMenuItem>
            <ControlButton
              isActive={translateEnabled}
              title={'Translate'}
              icon={<SectionTranslateIcon className="w-6 h-6" />}
              onClick={() => {
                setCadPageState({ translateEnabled: !translateEnabled })
              }}
            />
          </DropdownMenuItem>
          <DropdownMenuItem>
            <ControlButton
              isActive={rotateEnabled}
              title={'Rotate'}
              icon={<SectionRotateIcon className="w-6 h-6" />}
              onClick={() => {
                setCadPageState({ rotateEnabled: !rotateEnabled })
              }}
            />
          </DropdownMenuItem>
          {/* <DropdownMenuItem>
            <ControlButton
              title="Rotate 180"
              icon={<ResetIcon className="w-4 h-4" />}
              onClick={() => {}}
            />
          </DropdownMenuItem>
          <DropdownMenuItem>
            <ControlButton
              title="X Axis"
              icon={<ExplodeXIcon className="w-4 h-4" />}
              onClick={() => {}}
            />
          </DropdownMenuItem>
          <DropdownMenuItem>
            <ControlButton
              title="Y Axis"
              icon={<ExplodeYIcon className="w-4 h-4" />}
              onClick={() => {}}
            />
          </DropdownMenuItem>
          <DropdownMenuItem>
            <ControlButton
              title="Z Axis"
              icon={<ExplodeZIcon className="w-4 h-4" />}
              onClick={() => {}}
            />
          </DropdownMenuItem> */}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
