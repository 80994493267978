import { useUserPreferences } from '@/services/queries/users'
import { INFRASTRUCTURE_ENVIRONMENT_TYPE } from '@/constants'

const DOCUMENT_TEMPLATES_PREFERENCE = 'DOCUMENT_TEMPLATES'

export const useDocumentTemplateFlag = () => {
  const { isLoading: isLoadingUserPreferences, data: userPreferencesData } =
    useUserPreferences()

  const isDev =
    INFRASTRUCTURE_ENVIRONMENT_TYPE === 'dev' ||
    !INFRASTRUCTURE_ENVIRONMENT_TYPE

  const preferences = userPreferencesData?.data?.preferences || []

  const isEnabled = preferences.includes(DOCUMENT_TEMPLATES_PREFERENCE) || isDev

  return {
    isLoading: isLoadingUserPreferences,
    data: {
      isEnabled,
    },
  }
}
