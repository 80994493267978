import { Intersection } from 'three'
import { Canvas, events } from '@react-three/fiber'

export const eventManagerFactory: Parameters<typeof Canvas>[0]['events'] = (
  state,
) => ({
  // Default configuration
  ...events(state),

  // Determines if the event layer is active
  enabled: true,

  // Event layer priority, higher prioritized layers come first and may stop(-propagate) lower layer
  priority: 1,

  // The filter can re-order or re-structure the intersections
  filter: (items: Intersection[]) =>
    items.filter((item) => {
      let visible = true
      item.object.traverseAncestors((object) => {
        if (!object.visible) {
          visible = false
          return false
        }
      })
      return visible
    }),

  // The compute defines how pointer events are translated into the raycaster and pointer vector2
  //   compute: (event: DomEvent, state: RootState, previous?: RootState) => {
  //     state.pointer.set((event.offsetX / state.size.width) * 2 - 1, -(event.offsetY / state.size.height) * 2 + 1)
  //     state.raycaster.setFromCamera(state.pointer, state.camera)
  //   },

  // Find more configuration default on ./packages/fiber/src/web/events.ts
  // And type definitions in ./packages/fiber/src/core/events.ts
})
