import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as THREE from 'three'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { useCADPageStore } from '@/state/cad'
import { QUERY_KEYS } from '../queryKeys'

const loader = new GLTFLoader()

const getGLTF = async ({
  downloadUrl,
  onLoadingProgress,
}: {
  downloadUrl: string
  onLoadingProgress: (progress: number) => void
}): Promise<{ gltf: GLTFObject }> =>
  new Promise((resolve, reject) => {
    const cleanDownloadUrl = downloadUrl.split('?')[0]
    const gltfCache = THREE.Cache.get(cleanDownloadUrl)

    if (gltfCache) {
      onLoadingProgress(100)
      resolve({
        gltf: new GLTFObject(gltfCache),
      })
      return
    }

    loader.load(
      downloadUrl,
      (gltf: GLTF) => {
        resolve({
          gltf: new GLTFObject(gltf),
        })
      },
      (xhr) => onLoadingProgress((xhr.loaded / xhr.total) * 100),
      (err) => reject(err),
    )
  })

export const useGLTF = ({
  downloadUrl,
  documentId,
  cadVersionId,
}: {
  downloadUrl?: string | null
  documentId?: string | null
  cadVersionId?: string | null
}) => {
  const isEnabled = !!downloadUrl && !!cadVersionId
  const setLoadingProgress = useCADPageStore(
    useShallow((state) => state.setLoadingProgress),
  )

  return useQuery({
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    staleTime: Infinity,
    enabled: isEnabled,
    queryKey: [QUERY_KEYS.GLTF, documentId, cadVersionId],
    queryFn: async (): Promise<{ gltf?: GLTFObject }> => {
      if (downloadUrl && cadVersionId) {
        return getGLTF({
          downloadUrl,
          onLoadingProgress: (progress) => setLoadingProgress(progress),
        })
      }
      return {}
    },
  })
}
