import { GLTFObject, GroupObject } from '@/lib/cad/GLTFObject'
import { RawAssemblyTree } from '@/state'

// Get inclsuive cross section map from exclusive cross section map
export function getInclusiveMap(
  assemblyTree: RawAssemblyTree,
  exclusiveCrossSectionMap: string[][],
) {
  const parts =
    assemblyTree.nodes
      .filter((node) => node.uuid !== assemblyTree.root)
      .map((node) => node.instance)
      .filter((instance) => instance !== undefined) ?? []

  return exclusiveCrossSectionMap.map((exclusiveParts) => {
    return parts.filter((part) => !exclusiveParts.includes(part))
  })
}

export function convertCrossSectionMap(crossSectionMap: string[][]): {
  [key: string]: number[]
} {
  const result: { [key: string]: number[] } = {}

  crossSectionMap.forEach((partNames, planeIndex) => {
    partNames.forEach((partName) => {
      if (!result[partName]) {
        result[partName] = []
      }
      result[partName].push(planeIndex)
    })
  })

  return result
}

export function getSelectedObjects(
  gltf: GLTFObject,
  selectedParts: string[],
  assemblyTree: RawAssemblyTree,
  hiddenParts: string[],
): GroupObject[] {
  return selectedParts
    .map((uuid) => assemblyTree.nodes.find((n) => n.uuid === uuid))
    .filter(
      (node) => node !== undefined && !hiddenParts.includes(node.instance),
    )
    .map((node) => {
      if (node && node.instance) {
        return gltf.getObjectByName(gltf.formatPartName(node.instance) ?? '')
      }
    })
    .filter((object): object is GroupObject => object !== null)
    .filter((object) => object && object.visible) as GroupObject[]
}
