import { useQuery, useMutation } from '@tanstack/react-query'

import {
  createTemplateAttribute,
  updateTemplateAttribute,
  deleteTemplateAttribute,
  listTemplateAttributes,
  getTemplateAttribute,
  TemplateAttribute,
  TemplateAttributeInput,
} from '@/lib/api/client'
import queryClient from '@/queryClient'
import MUTATION_KEYS from './mutationKeys'
import QUERY_KEYS from './queryKeys'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'
import {
  useListViewsTemplates,
  useCreateViewTemplate,
  useCreateImageTemplate,
} from '@/services/hooks/template_attributes'

type mutationTemplateAttributeProps = {
  shouldOptimisticUpdate?: boolean
  onSuccess?: (templateAttribute?: TemplateAttribute) => void
}

const onCreateTemplateAttributeSuccess = ({
  props,
  templateAttribute,
}: {
  props?: mutationTemplateAttributeProps
  templateAttribute: TemplateAttribute
}) => {
  const shouldOptimisticUpdate =
    props === undefined ? true : props.shouldOptimisticUpdate !== false
  if (shouldOptimisticUpdate) {
    queryClient.setQueriesData(
      {
        queryKey: [
          QUERY_KEYS.TEMPLATE_ATTRIBUTES,
          { documentPageId: templateAttribute?.document_page },
        ],
      },
      (templateAttributes: Array<TemplateAttribute> | undefined) => {
        if (templateAttributes) {
          return [...templateAttributes, templateAttribute]
        }
        return []
      },
    )

    queryClient.setQueryData(
      [
        QUERY_KEYS.TEMPLATE_ATTRIBUTE,
        { templateAttributeId: templateAttribute?.id },
      ],
      templateAttribute,
    )
  }
  if (props?.onSuccess) props.onSuccess(templateAttribute)
}

export const useCreateTemplateAttribute = (
  props?: mutationTemplateAttributeProps,
) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      documentPageId,
      payload,
    }: {
      documentPageId: string
      payload: TemplateAttributeInput
    }) => {
      const res = await createTemplateAttribute(documentPageId, payload)
      return res
    },
    onSuccess: (templateAttribute) =>
      onCreateTemplateAttributeSuccess({ props, templateAttribute }),
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const createTemplateAttributeMutation = () => {
  return queryClient.getMutationCache().build(queryClient, {
    mutationKey: [MUTATION_KEYS.CREATE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      documentPageId,
      payload,
    }: {
      documentPageId: string
      payload: TemplateAttributeInput
    }) => {
      const res = await createTemplateAttribute(documentPageId, payload)
      return res
    },
    onSuccess: (templateAttribute) =>
      onCreateTemplateAttributeSuccess({ templateAttribute }),
  })
}

const onUpdateTemplateAttributeSuccess = ({
  props,
  templateAttribute,
}: {
  props?: mutationTemplateAttributeProps
  templateAttribute: TemplateAttribute
}) => {
  queryClient.setQueriesData(
    {
      queryKey: [
        QUERY_KEYS.TEMPLATE_ATTRIBUTES,
        { documentPageId: templateAttribute?.document_page },
      ],
    },
    (templateAttributes: Array<TemplateAttribute> | undefined) => {
      if (templateAttributes) {
        return templateAttributes.map((currentTemplateAttribute) => {
          return currentTemplateAttribute.id === templateAttribute?.id
            ? templateAttribute
            : currentTemplateAttribute
        })
      }
      return []
    },
  )

  queryClient.setQueryData(
    [
      QUERY_KEYS.TEMPLATE_ATTRIBUTE,
      { templateAttributeId: templateAttribute?.id },
    ],
    templateAttribute,
  )
  if (props?.onSuccess) props.onSuccess(templateAttribute)
}

export const updateTemplateAttributeMutation = () => {
  return queryClient.getMutationCache().build(queryClient, {
    mutationKey: [MUTATION_KEYS.UPDATE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      templateAttributeId,
      payload,
    }: {
      templateAttributeId: string
      payload: TemplateAttributeInput
    }) => {
      const res = await updateTemplateAttribute(templateAttributeId, payload)
      return res
    },
    onSuccess: (templateAttribute) =>
      onUpdateTemplateAttributeSuccess({ templateAttribute }),
  })
}

export const useUpdateTemplateAttribute = (
  props?: mutationTemplateAttributeProps,
) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      templateAttributeId,
      payload,
    }: {
      templateAttributeId: string
      payload: TemplateAttributeInput
    }) => {
      const res = await updateTemplateAttribute(templateAttributeId, payload)
      return res
    },
    onSuccess: (templateAttribute) =>
      onUpdateTemplateAttributeSuccess({ props, templateAttribute }),
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const useDeleteTemplateAttribute = (
  props?: mutationTemplateAttributeProps,
) => {
  const { toast } = useToast()
  return useMutation({
    mutationKey: [MUTATION_KEYS.DELETE_TEMPLATE_ATTRIBUTE],
    mutationFn: async ({
      templateAttributeId,
    }: {
      templateAttributeId: string
    }) => {
      await deleteTemplateAttribute(templateAttributeId)
      return templateAttributeId
    },
    onSuccess: (templateAttributeId) => {
      queryClient.setQueriesData(
        {
          queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTES],
        },
        (templateAttributes: Array<TemplateAttribute> | undefined) => {
          if (templateAttributes) {
            return templateAttributes.filter(
              (currentTemplateAttribute) =>
                currentTemplateAttribute.id !== templateAttributeId,
            )
          }
          return []
        },
      )

      queryClient.removeQueries({
        queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTE, { templateAttributeId }],
      })

      if (props?.onSuccess) props.onSuccess()
    },
    onError: (error: any) => {
      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}

export const listTemplateAttributesQuery = ({
  documentPageId,
}: {
  documentPageId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTES, { documentPageId }],
    queryFn: () => listTemplateAttributes(documentPageId),
  })
}

export const useListTemplateAttributes = ({
  documentPageId,
}: {
  documentPageId?: string | null
}) => {
  const { toast } = useToast()
  return useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTES, { documentPageId }],
    queryFn: async () => {
      if (!documentPageId) return []
      try {
        const templateAttributes = await listTemplateAttributes(documentPageId)
        return templateAttributes
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    enabled: Boolean(documentPageId),
  })
}

const getTemplateAttributeQueryFn = async ({
  templateAttributeId,
}: {
  templateAttributeId: string
}) => {
  const templateAttribute = await getTemplateAttribute(templateAttributeId)

  queryClient.setQueriesData(
    {
      queryKey: [
        QUERY_KEYS.TEMPLATE_ATTRIBUTES,
        { documentPageId: templateAttribute?.document_page },
      ],
    },
    (templateAttributes: Array<TemplateAttribute> | undefined) => {
      if (templateAttributes) {
        return [...templateAttributes, templateAttribute]
      }
      return []
    },
  )
}
export const getTemplateAttributeQuery = ({
  templateAttributeId,
}: {
  templateAttributeId: string
}) => {
  return queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTE, { templateAttributeId }],
    queryFn: () => getTemplateAttributeQueryFn({ templateAttributeId }),
  })
}

export const useGetTemplateAttribute = ({
  templateAttributeId,
}: {
  templateAttributeId: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_ATTRIBUTE, { templateAttributeId }],
    queryFn: () => getTemplateAttributeQueryFn({ templateAttributeId }),
    enabled: Boolean(templateAttributeId),
  })
}

export const useCreateOrAttachViewTemplateAttributeMutation = ({
  documentPageId,
}: {
  documentPageId?: string
}) => {
  const viewTemplates = useListViewsTemplates(documentPageId)
  const { mutate: updateTemplateAttribute } = useUpdateTemplateAttribute()
  const createTemplateViewMutation = useCreateViewTemplate()

  return useMutation({
    mutationFn: async ({ viewId }: { viewId: string }) => {
      if (!documentPageId) {
        throw new Error(
          'Failed to create view template - missing document page id',
        )
      }

      const avaiableViewTemplateAttribute = viewTemplates.find(
        (attr) => attr.data_type === 'view' && !attr.template_values?.viewId,
      )
      const avaiableViewTemplateAttributeId = avaiableViewTemplateAttribute?.id

      if (avaiableViewTemplateAttributeId) {
        updateTemplateAttribute({
          templateAttributeId: avaiableViewTemplateAttributeId,
          payload: {
            data_type: 'view',
            template_values: {
              ...avaiableViewTemplateAttribute.template_values,
              viewId,
            } as any,
          },
        })
      } else {
        const rightMostView = viewTemplates
          .sort(
            (a, b) =>
              (b.template_values?.position?.x || 0) -
              (a.template_values?.position?.x || 0),
          )
          .at(viewTemplates.length - 1)

        if (rightMostView && rightMostView.template_values?.position?.x) {
          rightMostView.template_values.position.x += 1
        }

        createTemplateViewMutation.mutate({
          documentPageId,
          viewTemplateData: {
            viewId,
            imagePosition: 'absolute',
            position: {
              x: 5.412093389601887,
              y: 290.9924257753847,
            },
          },
        })
      }
    },
  })
}

export const useCreateOrAttachImageTemplateAttributeMutation = () => {
  const { mutateAsync: updateTemplateAttribute } = useUpdateTemplateAttribute()
  const { mutateAsync: createImageTemplate } = useCreateImageTemplate()

  return useMutation({
    mutationFn: async ({
      documentPageId,
      imageId,
    }: {
      documentPageId: string
      imageId: string
    }) => {
      const templateAttributes = await listTemplateAttributesQuery({
        documentPageId,
      })

      const avaiableImageTemplateAttribute = templateAttributes.find(
        (attr) => attr.data_type === 'image' && !attr.template_values?.imageId,
      )
      const avaiableImageTemplateAttributeId =
        avaiableImageTemplateAttribute?.id

      if (avaiableImageTemplateAttributeId) {
        await updateTemplateAttribute({
          templateAttributeId: avaiableImageTemplateAttributeId,
          payload: {
            data_type: 'image',
            template_values: {
              ...avaiableImageTemplateAttribute.template_values,
              imageId,
            } as any,
          },
        })
      } else {
        await createImageTemplate({
          documentPageId,
          imageData: {
            imageId,
            imagePosition: 'absolute',
          },
        })
      }
    },
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
