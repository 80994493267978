import { useEffect } from 'react'
import { useAppStore } from '@/state'
import { useCADQuery } from '../queries/cads'

export const useKickViewWorker = () => {
  const viewWorker = useAppStore((state) => state.viewWorker)
  const isViewWorkerProcessing = useAppStore(
    (state) => state.isViewWorkerProcessing,
  )
  const { data: cadData } = useCADQuery()

  const isLatestCadVersion = cadData.isLatestCadVersion
  const cadVersionId = cadData.version?.id
  const documentVersionId = cadData.documentVersion?.id

  useEffect(() => {
    const kickWorker = async () => {
      const shouldKickWorker =
        viewWorker && cadVersionId && isLatestCadVersion && documentVersionId
      if (shouldKickWorker) {
        await viewWorker.kick(cadVersionId, documentVersionId)
      }
    }
    kickWorker().catch((error) => {
      console.error('Failed to kick worker:', error)
    })
  }, [cadVersionId, isLatestCadVersion, viewWorker, documentVersionId])

  return {
    isViewWorkerProcessing,
    viewWorker,
  }
}
