import { useState } from 'react'
import { useLocation } from 'wouter'

import { useListProjects } from '@/services/queries/projects'

import { CreateDocumentFormFields } from './CreateDocumentFormFields'
import { useCreateDocumentFormMutation } from '../../hooks/useCreateDocumentForm'

export const CreateDocumentForm = () => {
  const [, setLocation] = useLocation()
  const { mutate: createNewDocument, isPending: isCreatingNewDocument } =
    useCreateDocumentFormMutation({
      onSuccess: ({ projectId, documentId }) => {
        setLocation(`/p/${projectId}/document/${documentId}/cad`)
      },
    })
  const [isCreatingNewProject, setIsCreatingNewProject] = useState(false)
  const [isUploadingNewCAD, setIsUploadingNewCAD] = useState(false)

  const projectsList = useListProjects({ limit: 5, offset: 0 })
  const totalProjects = projectsList.data?.pages[0].totalProjects || 0

  return (
    <CreateDocumentFormFields
      totalProjects={totalProjects}
      isUploadingNewCAD={isUploadingNewCAD}
      isCreatingNewProject={isCreatingNewProject}
      isCreatingNewDocument={isCreatingNewDocument}
      setIsCreatingNewProject={setIsCreatingNewProject}
      setIsUploadingNewCAD={setIsUploadingNewCAD}
      onAfterSubmit={({
        documentName,
        cadId,
        files,
        projectId,
        projectName,
        template,
      }) => {
        createNewDocument({
          projectName,
          formValues: { documentName, cadId, files, projectId, template },
          isCreatingNewProject: totalProjects === 0 || isCreatingNewProject,
          isUploadingNewCAD: totalProjects === 0 || isUploadingNewCAD,
        })
      }}
    />
  )
}
