import { useMemo } from 'react'
import { useDndContext, DragOverlay } from '@dnd-kit/core'
import { snapCenterToCursor, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { ASMTreeNode, RawAssemblyTree } from '@/state'
import { useCADQuery } from '@/services/queries/cads'
import { TreeNode } from './TreeNode'
import { DRAGGABLE_TYPE } from './constants'
import { useCADPageStore } from '@/state'
import { useShallow } from 'zustand/react/shallow'

export const DraggableTreeNodeOverlay = () => {
  const { data: cadData, isLoading } = useCADQuery()
  const dndContext = useDndContext()
  const selected = useCADPageStore(useShallow((state) => state.selectedParts))
  const nMultiSelect = useMemo(() => selected.length, [selected])
  const node = dndContext.active?.data?.current?.node as ASMTreeNode | undefined
  const tree = dndContext.active?.data?.current?.tree as
    | RawAssemblyTree
    | undefined
  const dragType = dndContext.active?.data?.current?.type as string | undefined
  const gltf = cadData.gltf

  const showOverlay =
    !isLoading && node && gltf && tree && dragType === DRAGGABLE_TYPE

  if (!showOverlay) {
    return null
  }

  return (
    <DragOverlay modifiers={[snapCenterToCursor, restrictToWindowEdges]}>
      <div className="bg-white border border-gray-200 drop-shadow-xl rounded-lg cursor-move py-1">
        {nMultiSelect > 1 && (
          <span
            className="absolute -top-2 -right-2 w-5 h-5 flex rounded-full items-center justify-center bg-primary-50 text-white"
            style={{ fontSize: 12 }}
          >
            {selected.length}
          </span>
        )}
        <TreeNode
          documentType="work_instructions"
          nodeUUID={node.uuid}
          tree={tree}
          gltf={gltf}
          level={0}
          isReadOnly
          hasDraggableChildren={false}
          assemblyGroupIdsInOperationStep={[]}
        />
      </div>
    </DragOverlay>
  )
}
