import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useShallow } from 'zustand/react/shallow'
import * as z from 'zod'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { Files } from '@/components/ui/file'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/ui/loading'

import type { DocumentPage, DocumentTypeChoices } from '@/lib/api/client'

import { useCreateImage, useListImages } from '@/services/queries/images'
import { cn } from '@/utils'
import { Tabs, TabsTrigger, TabsList, TabsContent } from '@/components/ui/tabs'
import { ScreenshotList } from '@/pages/CADPage/components/ScreenshotPanelButton/ScreenshotList'
import { useDocumentPageParams } from '../hooks'
import { useScreenshots } from '@/pages/CADPage/components/ScreenshotPanelButton/hooks/useScreenshots'
import { useIsReadOnly } from '@/pages/CADPage/hooks/useIsReadOnly'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useCADPageStore } from '@/state/cad'
import { ImagesList } from './ImagesList'
import {
  useCreateImageTemplate,
  useCreateViewTemplate,
} from '@/services/hooks/template_attributes'
import { VisuallyHidden } from '@radix-ui/themes'
import { useDocumentTemplateFlag } from '@/featureFlags/useDocumentTemplateFlag'
import {
  useCreateOrAttachViewTemplateAttributeMutation,
  useCreateOrAttachImageTemplateAttributeMutation,
} from '@/services/queries/template_attributes'

interface UploadPhotoButtonProps {
  documentPage: DocumentPage
  children?: React.ReactNode
  isDisabled?: boolean
  documentType?: DocumentTypeChoices
}

type FormInputs = {
  files: File[]
}

const formSchema = z.object({
  files: z.array(z.any()).min(1, {
    message: 'Upload at least 1 image.',
  }),
})

export const UploadPhotoButton = forwardRef<any, UploadPhotoButtonProps>(
  ({ documentPage, children, isDisabled, documentType }, ref) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger
          disabled={isDisabled}
          className={cn(
            'flex flex-row items-center space-x-2 border-0 shadow-none w-full justify-start text-left h-full p-2',
            {
              'opacity-50': isDisabled,
              'hover:bg-accent': !isDisabled,
              'hover:text-accent-foreground': !isDisabled,
            },
          )}
          onClick={() => {
            setIsOpen(true)
          }}
        >
          {children}
        </DialogTrigger>
        <DialogContent
          className="min-h-44"
          ref={ref}
          ariaLabel="Upload image dialog"
        >
          <VisuallyHidden>
            <DialogHeader>
              <DialogTitle>Upload new image</DialogTitle>
            </DialogHeader>
          </VisuallyHidden>
          <Tabs defaultValue="select" className="w-full">
            <TabsList className="my-5 border-b w-full justify-start">
              <TabsTrigger value="select">Use existing images</TabsTrigger>
              <TabsTrigger value="upload">Upload new</TabsTrigger>
            </TabsList>
            <TabsContent value="select">
              <SelectImageList
                documentType={documentType}
                documentPageId={documentPage?.id as string}
                documentVersionId={documentPage.document_version}
                closeDialog={() => setIsOpen(false)}
              />
            </TabsContent>
            <TabsContent value="upload">
              <UploadForm
                documentPage={documentPage}
                closeModal={() => setIsOpen(false)}
              />
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    )
  },
)

UploadPhotoButton.displayName = 'UploadPhotoButton'

type UploadImageFormProps = {
  closeModal: () => void
  documentPage: DocumentPage
}
const UploadForm = ({ closeModal, documentPage }: UploadImageFormProps) => {
  const form = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  })

  const { isPending, mutate: createImage } = useCreateImage({
    onSuccess: () => {
      closeModal()
    },
  })

  const onSubmit = ({ files }: FormInputs) => {
    createImage({
      documentPage,
      file: files[0],
    })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <Files
          placeholder="Upload a PNG, JPG or GIF up to 5 MB."
          onChange={(files) => {
            form.setValue('files', files)
          }}
          validateFile={(file) => {
            form.setError('files', {})
            const validExtensions = ['png', 'jpg', 'jpeg', 'gif']
            const tok = file.name.toLowerCase().split('.')
            const ext = tok[tok.length - 1]

            const hasValidFileExtension = validExtensions.includes(ext)
            if (!hasValidFileExtension) {
              form.setError('files', {
                type: 'extention',
                message: `Only files with the following extensions are allowed: ${validExtensions.join(
                  ', ',
                )}.`,
              })
            }

            const maxFileSize = 5242880 // 5 MB
            const hasValidFileSize = file.size <= maxFileSize

            if (!hasValidFileSize) {
              form.setError('files', {
                type: 'max',
                message: 'Image must be smaller than 5MB.',
              })
            }

            const isValid = hasValidFileExtension && hasValidFileExtension

            return isValid
          }}
          error={form.formState.errors.files?.message}
        />
        <Button type="submit" disabled={isPending}>
          {isPending ? (
            <div className="flex items-center space-x-2">
              <span>Uploading</span>
              <Loading />
            </div>
          ) : (
            'Upload image'
          )}
        </Button>
      </form>
    </Form>
  )
}

type SelectImageListProps = {
  documentType?: DocumentTypeChoices
  documentPageId: string
  documentVersionId: string
  closeDialog: () => void
}
const SelectImageList = ({
  documentType,
  documentPageId,
  documentVersionId,
  closeDialog,
}: SelectImageListProps) => {
  const { isLoading: isReadOnlyInfoLoading, data: readOnlyInfo } =
    useIsReadOnly()
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const { documentId, cv } = useDocumentPageParams()
  const { mutateAsync: createViewTemplate, isPending: isCreatingViewTemplate } =
    useCreateViewTemplate()
  const {
    mutateAsync: createImageTemplate,
    isPending: isCreatingImageTemplate,
  } = useCreateImageTemplate()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()
  const { isLoading: isLoadingScreenshots, data: views } = useScreenshots({
    documentId,
    cadVersionId: cv,
    documentVersionId,
  })
  const { data: images, isLoading: isLoadingImages } = useListImages({
    documentVersionId,
  })

  const isLoadingViews = useMemo(
    () => isLoadingScreenshots || isReadOnlyInfoLoading || isLoadingSteps,
    [isLoadingScreenshots, isReadOnlyInfoLoading, isLoadingSteps],
  )
  const isReadOnly = useMemo(
    () => Boolean(readOnlyInfo?.isReadOnly),
    [readOnlyInfo?.isReadOnly],
  )

  useEffect(() => {
    setCadPageState({
      filterScreenshotsByDocumentPageId: documentPageId,
    })

    return () => {
      setCadPageState({
        filterScreenshotsByDocumentPageId: null,
      })
    }
  }, [setCadPageState, documentPageId])

  const {
    data: { isEnabled: isDocumentTemplateFlagEnabled },
  } = useDocumentTemplateFlag()

  const { mutateAsync: createOrAttachViewTemplate } =
    useCreateOrAttachViewTemplateAttributeMutation({ documentPageId })

  const { mutateAsync: createOrAttachImageTemplate } =
    useCreateOrAttachImageTemplateAttributeMutation()

  return (
    <div className="flex max-h-[650px] flex-col overflow-y-scroll">
      <div className="flex flex-col">
        <h3 className="text-lg font-bold">CAD Screenshots</h3>
        <ScreenshotList
          views={views || []}
          steps={steps}
          documentType={documentType}
          isLoading={isLoadingViews}
          isDisabled={isCreatingViewTemplate}
          isReadOnly={isReadOnly}
          onClick={async (view) => {
            if (isDocumentTemplateFlagEnabled) {
              await createOrAttachViewTemplate({ viewId: view.id as string })
            } else {
              await createViewTemplate({
                documentPageId,
                viewTemplateData: {
                  viewId: view.id,
                  imagePosition: 'absolute',
                },
              })
            }
            closeDialog()
          }}
        />
      </div>
      <div className="flex flex-col">
        <h3 className="text-lg font-bold">Image Gallery</h3>
        <ImagesList
          images={images || []}
          isDisabled={isCreatingImageTemplate}
          isLoading={isLoadingImages}
          isReadOnly={isReadOnly}
          onClick={async (image) => {
            if (isDocumentTemplateFlagEnabled) {
              await createOrAttachImageTemplate({
                documentPageId,
                imageId: image.id as string,
              })
            } else {
              await createImageTemplate({
                documentPageId,
                imageData: {
                  imageId: image.id as string,
                  imagePosition: 'absolute',
                },
              })
            }
            closeDialog()
          }}
        />
      </div>
    </div>
  )
}
