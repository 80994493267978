import { lazy, Suspense, useEffect, useState } from 'react'
import { ErrorBoundary } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster } from '@/components/ui/toaster'
import { MinimalLayout } from '@/components/layouts/MinimalLayout'
import { Navbar } from '@/components/nav/Navbar'
import queryClient from '@/queryClient'
import {
  ExclamationCircleIcon,
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/24/outline'
import { Router } from './router'
import { Button } from './components/ui/button'

const ReactQueryDevtoolsProduction = lazy(() =>
  // @ts-expect-error react-query-devtools lazy loaded
  import('@tanstack/react-query-devtools/production').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
)

export const App = () => {
  const [showReactQueryDevtools, setShowReactQueryDevtools] =
    useState<boolean>(false)

  useEffect(() => {
    // @ts-expect-error - adding react-query devtools toggle
    window.toggleReactQueryDevtools = () =>
      setShowReactQueryDevtools((old) => !old)
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        fallback={
          <MinimalLayout>
            <Navbar hardReload>
              <div className="flex items-center space-x-4 h-30 py-10 w-full"></div>
            </Navbar>
            <div
              className="flex flex-col items-center space-y-6 mt-32"
              style={{
                height: 'calc(100vh - 90px)',
              }}
            >
              <h1 className="text-2xl flex flex-col items-center">
                <ExclamationCircleIcon className="h-8 w-8 text-red-500" />
                <span>Something went wrong</span>
              </h1>
              <Button
                className="flex items-center space-x-2"
                onClick={() => {
                  window.location.href = '/'
                }}
              >
                <ArrowLeftStartOnRectangleIcon className="h-5 w-5" />
                <span>Return Home</span>
              </Button>
            </div>
          </MinimalLayout>
        }
      >
        <Router />
        <Toaster />
        <ReactQueryDevtools buttonPosition="bottom-left" />
        {showReactQueryDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </Suspense>
        )}
      </ErrorBoundary>
    </QueryClientProvider>
  )
}
