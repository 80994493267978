import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { cn } from '@/utils'
import { useCADPageStore } from '@/state/cad'
import { useOperationSteps } from '@/services/queries/operation_steps'

import type { ASMTreeNode, RawAssemblyTree } from '@/state'

import { findAttachedStep } from './lib/findAttachedStep'

export const AttachedToOperationIndicator = ({
  node,
  tree,
  isVisible,
}: {
  node: ASMTreeNode
  tree: RawAssemblyTree
  isVisible: boolean
}) => {
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const setCADPageState = useCADPageStore(useShallow((state) => state.setState))
  const selectFromCad = useCADPageStore(
    (state) => state.operationStep?.selectFromCad,
  )
  const isHidden = hiddenParts.includes(node.uuid)
  const isSelected = useCADPageStore((state) =>
    state.selectedParts.includes(node.uuid),
  )
  const { steps } = useOperationSteps()

  const attachedStep = useMemo(
    () => findAttachedStep({ steps, tree, node }),
    [steps, tree, node],
  )

  if (!isVisible) {
    return null
  }

  return (
    <div
      className={cn('h-2 w-2 rounded-full cursor-pointer', {
        'bg-teal-200': isHidden,
        'bg-teal-400': !isHidden,
        'bg-purple-200': selectFromCad && isSelected && isHidden,
        'bg-purple-600': selectFromCad && isSelected && !isHidden,
      })}
      onClick={async () => {
        if (attachedStep) {
          setCADPageState({
            subAssemblyDocumentPageId: attachedStep?.step.parent,
          })

          requestAnimationFrame(() => {
            const el = document.getElementById(
              `step-part-diplay-name-${attachedStep.node.uuid}`,
            )
            el?.scrollIntoView({ behavior: 'smooth' })
            el?.classList.add('bg-primary-10', 'border', '!border-primary-50')
            setTimeout(() => {
              el?.classList.remove(
                'bg-primary-10',
                'border',
                '!border-primary-50',
              )
            }, 1500)
          })
        }
      }}
    />
  )
}
