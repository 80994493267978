import { useMutation } from '@tanstack/react-query'
import { createDocumentPageAssemblyGroupIDs } from '@/lib/api/client'
import queryClient from '@/queryClient'
import { useCADPageStore } from '@/state'

import type { DocumentPage } from '@/lib/api/client'

import { QUERY_KEYS as DOCUMENT_PAGES_QUERY_KEYS } from '@/services/queries/document_pages/queryKeys'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'
import { useToggleStepCardsList } from '@/pages/CADPage/components/AssemblyOrderPanel/StepCardList/hooks/useToggleStepCardsList'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useOperationSteps } from './useOperationSteps'

export const useAddPartsToOperation = () => {
  const { toast } = useToast()
  const { documentId } = useCadPageParams()
  const { steps } = useOperationSteps()
  const toggleStepCardsList = useToggleStepCardsList()
  const setCadPageState = useCADPageStore((state) => state.setState)

  return useMutation({
    mutationFn: async ({
      documentPageId,
      documentVersionId,
      assemblyGroupIds,
      onSuccess,
    }: {
      documentPageId: string
      documentVersionId: string
      assemblyGroupIds: string[]
      onSuccess?: () => void
    }) => {
      await createDocumentPageAssemblyGroupIDs(documentPageId, assemblyGroupIds)
      return { documentPageId, documentVersionId, onSuccess }
    },

    onSuccess: async ({ documentPageId, documentVersionId, onSuccess }) => {
      await queryClient.invalidateQueries({
        queryKey: [
          DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
          { documentVersionId },
        ],
        refetchType: 'all',
      })

      if (onSuccess) {
        onSuccess()
      } else {
        setCadPageState({
          selectedParts: [],
          operationStep: {
            documentId,
            stepId: documentPageId,
            isActive: false,
            selectFromCad: false,
          },
        })

        await toggleStepCardsList({
          steps,
          previousSteps: steps,
          documentVersionId,
          isReadOnly: false,
        })
      }
    },

    onMutate: ({ assemblyGroupIds, documentPageId, documentVersionId }) => {
      const queryKey = [
        DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES,
        { documentVersionId },
      ]

      const previousDocumentPages = (queryClient.getQueryData(queryKey) ||
        []) as DocumentPage[]

      queryClient.setQueryData(queryKey, (old: DocumentPage[]) => {
        if (!old) return []

        return old.map((doc) => {
          if (doc.id === documentPageId) {
            return {
              ...doc,
              documentpageassemblygroupids: [
                ...doc.documentpageassemblygroupids,
                ...assemblyGroupIds.map((assemblyGroupId) => ({
                  assembly_group_id: assemblyGroupId,
                  document_page: documentPageId,
                })),
              ],
            }
          }
          return doc
        })
      })

      return { previousDocumentPages }
    },

    onError: (error, { documentVersionId }, context) => {
      console.error('Failed to add part to operation', error)

      queryClient.setQueryData(
        [DOCUMENT_PAGES_QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
        context?.previousDocumentPages || [],
      )

      if (error instanceof CustomError) {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        })
      }
    },
  })
}
