import { FetchResponse } from 'openapi-fetch'
import * as Sentry from '@sentry/browser'

export class CustomError extends Error {
  message: string
  code: number
  status: number

  constructor(response: FetchResponse<any, any, any>, defaultMessage: string) {
    const message = response.error?.message || defaultMessage
    const code = response.error?.code
    const status = response.response.status

    super(message)
    this.message = message
    this.code = code
    this.status = status

    Sentry.getCurrentScope().setExtras({ message, code, status })
  }
}

export default CustomError
