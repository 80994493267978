import logoUrl from '@/assets/q20-logo3.png'
import { SignUpForm } from './components/SignUpForm'
import { Link } from 'wouter'

export const SignUpPage = () => {
  return (
    <div className="flex min-h-screen flex-col justify-start px-6 py-4 lg:px-8 bg-stone-50">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto w-24" src={logoUrl} alt="Quarter20" />
        <h2 className="mt-0 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Create an Account
        </h2>
        <h2 className="text-center mt-3 tracking-tight">
          Sign up for a two week free trial of Quarter20, <br /> no credit card
          required.
        </h2>
      </div>

      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg bg-white border border-gray-100 shadow-md p-10 rounded-lg">
        <SignUpForm />
      </div>
      <p className="mt-6 text-center text-sm text-gray-500 font-semibold">
        Have an account?
        <Link
          href="/login"
          className="font-bold leading-6 text-primary-50 ml-1"
        >
          Sign In
        </Link>
      </p>
    </div>
  )
}
