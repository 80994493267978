import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from '@/components/ui/context-menu'
import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import { Loading } from '@/components/ui/loading'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { cn } from '@/utils'

type EditableNameProps = {
  name: string
  onEditName: (updatedName: string) => void
  isRenaming: boolean
  schema: z.ZodSchema
  className?: string
  orderNumber?: number
  shouldUpdateOnClick?: boolean
}
export const EditableName = ({
  name,
  onEditName,
  isRenaming,
  schema,
  className,
  orderNumber,
  shouldUpdateOnClick,
}: EditableNameProps) => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        {isEditing ? (
          <div className="px-8">
            <RenameForm
              name={name}
              onCompleted={() => setIsEditing(false)}
              onEditName={onEditName}
              isRenaming={isRenaming}
              schema={schema}
            />
          </div>
        ) : (
          <span
            className={cn('text-lg font-semibold', className, {
              'cursor-pointer': shouldUpdateOnClick,
            })}
            onClick={() => (shouldUpdateOnClick ? setIsEditing(true) : null)}
            data-testid={`operation-${orderNumber}`}
          >
            {name}
          </span>
        )}
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem onClick={() => setIsEditing(true)}>
          Rename
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  )
}

type RenameFormProps = {
  name: string
  onEditName: (updatedName: string) => void
  isRenaming: boolean
  onCompleted: () => void
  schema: z.ZodSchema
}

const RenameForm = ({
  name,
  onEditName,
  isRenaming,
  onCompleted,
  schema,
}: RenameFormProps) => {
  const form = useForm<{ displayName: string }>({
    resolver: zodResolver(schema),
    defaultValues: {
      displayName: name,
    },
  })

  const onFormSubmit = async (values: z.infer<typeof schema>) => {
    try {
      await onEditName(values.displayName)
    } finally {
      onCompleted()
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFormSubmit)} className="p-2">
        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  className="bg-white"
                  {...field}
                  autoFocus
                  autoComplete="off"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-center space-x-1 justify-end mt-2">
          <Button
            className="h-6 px-2"
            disabled={isRenaming}
            onClick={async (event) => {
              event.stopPropagation()
              const formValues = form.getValues()
              await onFormSubmit(formValues)
            }}
          >
            {isRenaming ? (
              <Loading className="w-3 h-3" />
            ) : (
              <CheckIcon className="w-3 h-3" />
            )}
          </Button>
          <Button
            variant="secondary"
            className="h-6 px-2"
            onClick={onCompleted}
          >
            <Cross2Icon className="w-3 h-3" />
          </Button>
        </div>
      </form>
    </Form>
  )
}
