import { useEffect, useMemo } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { setUser } from '@sentry/react'
import { useLocation } from 'wouter'

import queryClient from '@/queryClient'
import { verifyLogin, login, logout, signUp, getCookie } from '@/lib/api/client'
import { LOGIN_REDIRECT_NAME, BASE_URL } from '@/constants'
import QUERY_KEYS from './queryKeys'
import MUTATION_KEYS from './mutationKeys'
import { useAppStore } from '@/state'

type useVerifyLoginProps = {
  enabled?: boolean
  onSuccess?: () => void
}

const defaultUseVerifyLoginProps: useVerifyLoginProps = {
  enabled: true,
}

const verifyLoginQueryFn = async (onSuccess?: () => void) => {
  const resp = await verifyLogin()

  if (resp && resp.response.ok && onSuccess) {
    onSuccess()
  }

  return {
    is_logged_in: resp && resp.response.ok,
    user: resp ? resp.data : null,
  }
}

export const useVerifyLogin = (props?: useVerifyLoginProps) => {
  const { enabled, onSuccess } = {
    ...defaultUseVerifyLoginProps,
    ...props,
  }
  const onUserLogin = useAppStore((state) => state.onUserLogin)
  const { data, isLoading, isError, refetch } = useQuery({
    enabled,
    refetchOnMount: true,
    staleTime: 0,
    queryKey: [QUERY_KEYS.VERIFY_LOGIN],
    queryFn: () => verifyLoginQueryFn(onSuccess),
  })

  const isLoggedIn = useMemo(
    () => !isLoading && data?.is_logged_in,
    [isLoading, data?.is_logged_in],
  )

  useEffect(() => {
    if (isLoggedIn && data?.user) {
      onUserLogin(data.user)
    }
  }, [isLoggedIn, data?.user, onUserLogin])

  return {
    isLoggedIn,
    user: data?.user,
    isVerifyingLoginStatus: isLoading,
    loginVerificationFailed: isError,
    refetchVerifyLogin: refetch,
  }
}

export const verifyLoginQuery = (props?: useVerifyLoginProps) =>
  queryClient.fetchQuery({
    queryKey: [QUERY_KEYS.VERIFY_LOGIN],
    queryFn: () => verifyLoginQueryFn(props?.onSuccess),
    staleTime: 0,
  })

export const useLogin = () => {
  const [, setLocation] = useLocation()
  const { mutate, data, isPending, isError, error } = useMutation({
    mutationKey: [MUTATION_KEYS.LOGIN],
    mutationFn: async (values: { email: string; password: string }) => {
      try {
        const loginResp = await login(values.email, values.password)
        const { is_logged_in } = await verifyLoginQuery()
        const loginRedirectUrl = getCookie(LOGIN_REDIRECT_NAME)

        if (!is_logged_in) {
          throw new Error('Invalid login credentials')
        }

        return {
          loginSuccessful: !!loginResp.response.ok && is_logged_in,
          isLoggedIn: is_logged_in,
          loginRedirectUrl,
        }
      } catch (err) {
        if (err instanceof Error) {
          throw err
        }
        throw new Error('Unknown error')
      }
    },
    onSuccess: ({ isLoggedIn, loginRedirectUrl }) => {
      if (isLoggedIn) {
        const continueToUrl =
          new URLSearchParams(window.location.search).get('continue_to') || '/'
        setLocation(continueToUrl)
        return
      }

      if (!isLoggedIn && loginRedirectUrl) {
        window.location.href = JSON.parse(loginRedirectUrl)
      }
    },
  })

  return {
    handleLogin: mutate,
    isLogginIn: isPending,
    isLoggedIn: data?.loginSuccessful,
    loginFailed: isError,
    error,
  }
}

export const useLogout = (props?: { redirectOnLogout?: boolean }) => {
  const [, setLocation] = useLocation()
  const onUserLogout = useAppStore((state) => state.onUserLogout)
  const { mutate, data, isPending, isError } = useMutation({
    mutationKey: [MUTATION_KEYS.LOGOUT],
    mutationFn: async () => {
      const logoutResp = await logout()
      const { is_logged_in } = await verifyLoginQuery()

      if (logoutResp.response.ok && !is_logged_in && props?.redirectOnLogout) {
        setLocation('/login')
      } else {
        throw new Error('Logout failed!')
      }

      return {
        logOutSuccessful: !!logoutResp.response.ok && !is_logged_in,
      }
    },
    onSuccess: () => {
      onUserLogout()
      queryClient.clear()
    },
  })

  useEffect(() => {
    if (data?.logOutSuccessful) {
      setUser(null)
    }
  }, [data?.logOutSuccessful])

  return {
    handleLogout: mutate,
    isLogginOut: isPending,
    isLoggedOut: data?.logOutSuccessful,
    logoutFailed: isError,
  }
}

export const useSignUp = () => {
  const [, setLocation] = useLocation()
  return useMutation({
    mutationKey: [MUTATION_KEYS.LOGIN],
    mutationFn: async (values: {
      email: string
      password: string
      firstName: string
      lastName: string
    }) => signUp(values),

    onSuccess: () => {
      const verifyEmailUrl = `${BASE_URL}/accounts/confirm-email/`
      window.location.href = verifyEmailUrl
    },

    onError: (error) => {
      setLocation(`/login?error=${encodeURIComponent(error.message)}`)
    },
  })
}

export { QUERY_KEYS, MUTATION_KEYS }
