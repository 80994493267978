import { cn } from '@/utils'
import { useListImageTemplates } from '@/services/hooks/template_attributes'
import { useListImages } from '@/services/queries/images'
import DraggableImage, { ImageTypeEnum } from '../DraggableImage'
import type { DocumentPage } from '@/lib/api/client'

interface OperationImagesProps {
  documentPage: DocumentPage
  isReadOnly: boolean
}

export const OperationImages = ({
  documentPage,
  isReadOnly,
}: OperationImagesProps) => {
  const imageTemplates = useListImageTemplates(documentPage.id)
  const { data: images } = useListImages({
    documentVersionId: documentPage.document_version,
  })

  const documentImages = images || []

  return (
    <div
      className="flex flex-1 justify-between"
      style={{
        maxHeight: 360,
      }}
    >
      <div className="flex flex-col gap-2 flex-1">
        <div
          className="flex gap-2"
          style={{
            height: '50%',
          }}
        >
          {imageTemplates
            .sort(
              (a, b) =>
                (b.template_values?.position?.x || 0) -
                (a.template_values?.position?.x || 0),
            )
            .map((template, i) => {
              const baseUrl = window.location.origin
              const placeholderImage = `${baseUrl}/static/images/image-placeholder.png`
              const placeholderImageLoading = `${baseUrl}/static/images/image-placeholder-loading.png`

              const userFacingDownloadUrl = ''
              const isAddingAnnotation = false

              const imageId = template?.template_values?.imageId
              const image = documentImages.find((image) => image.id === imageId)

              const downloadUrl = imageId
                ? image?.download_url || placeholderImageLoading
                : placeholderImage

              return (
                <div
                  key={i}
                  className={cn(
                    'flex flex-col justify-between top-0 left-0 flex-1',
                    {
                      [template.template_values.imagePosition || 'relative']:
                        true,
                    },
                  )}
                  style={{
                    maxWidth: '49%',
                    maxHeight: 180,
                  }}
                >
                  <DraggableImage
                    documentPageId={documentPage?.id as string}
                    userFacingDownloadUrl={userFacingDownloadUrl}
                    isAddingAnnotation={isAddingAnnotation}
                    downloadUrl={downloadUrl}
                    imageTemplate={template as any}
                    type={ImageTypeEnum.Image}
                    imageId={imageId}
                    isReadOnly={isReadOnly}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
