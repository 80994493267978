import { ReactNode } from 'react'
import type { ASMTreeNode } from '@/state'
import type { Step } from '@/services/queries/operation_steps/types'
import {
  DndContext,
  DragStartEvent,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { useCADPageStore } from '@/state/cad'
import { useAddPartsToOperation } from '@/services/queries/operation_steps'
import { useShallow } from 'zustand/react/shallow'
import { useCADQuery } from '@/services/queries/cads'

export const DraggableTreeNodeContext = ({
  children,
}: {
  children: ReactNode
}) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 0,
      tolerance: 5,
    },
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 0,
      tolerance: 5,
    },
  })

  const sensors = useSensors(mouseSensor, touchSensor)

  const {
    data: { gltf, rawAssemblyTree },
  } = useCADQuery()
  const { mutateAsync: addPartsToOperation } = useAddPartsToOperation()
  const getState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  if (!gltf || !rawAssemblyTree) return null

  return (
    <DndContext
      sensors={sensors}
      onDragStart={(e: DragStartEvent) => {
        document.body.style.cursor = 'move'
        const uuid = e.active?.data?.current?.node?.uuid
        if (!uuid) return

        if (uuid && !getState().selectedParts.includes(uuid)) {
          setCadPageState({ selectedParts: [] })
        }
      }}
      onDragEnd={async ({ active, over }: DragEndEvent) => {
        document.body.style.cursor = 'default'
        const shouldAddAssemblyNodeToStep =
          active &&
          over &&
          active.data?.current &&
          active.data?.current?.type === 'assembly-tree-node' &&
          over.data?.current?.type === 'step-card-drop-zone'
        if (shouldAddAssemblyNodeToStep) {
          const assemblyNode = active.data?.current?.node as ASMTreeNode
          const step = over.data?.current?.step as Step
          const selected = getState().selectedParts
          const draggedParts = selected.length ? selected : [assemblyNode.uuid]

          if (selected.length && !selected.includes(assemblyNode.uuid)) {
            console.error('Multi Selected node was not added to step ')
            return
          }

          step.assembly_group_ids = Array.from(
            new Set([...step.assembly_group_ids, ...draggedParts]),
          )

          if (step.id) {
            await addPartsToOperation({
              assemblyGroupIds: draggedParts,
              documentPageId: step.id,
              documentVersionId: step.document_version,
            })
          } else {
            console.error('Step id is not defined')
          }
        }
      }}
      onDragCancel={() => {
        document.body.style.cursor = 'default'
      }}
    >
      {children}
    </DndContext>
  )
}
