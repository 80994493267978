import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'

import { HIGHLIGHT_PURPLE } from '@/constants'
import { useCADQuery } from '@/services/queries/cads'
import { useAssemblyTreeQuery } from '@/pages/CADPage/queries'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { ExplosionsState, useCADPageStore } from '@/state/cad'
import {
  useAddPartsToOperation,
  useOperationSteps,
} from '@/services/queries/operation_steps'
import { Step } from '@/services/queries/operation_steps/types'

export const useAddPartsFromCAD = () => {
  const { documentId } = useCadPageParams()
  const {
    isLoading: isLoadingAssemblyTree,
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const {
    isLoading: isLoadingCAD,
    data: { gltf },
  } = useCADQuery()
  const { isLoading: isLoadingSteps, steps } = useOperationSteps()

  const addPartToStep = useAddPartsToOperation()

  const colorMap = useCADPageStore(useShallow((state) => state.colorMap))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const getAllAddedParts = useCallback(() => {
    return Array.from(new Set(steps.flatMap((step) => step.assembly_group_ids)))
  }, [steps])

  const isLoading = isLoadingAssemblyTree || isLoadingCAD || isLoadingSteps

  /**
   * Toggles add parts from CAD mode
   *
   * @param {Step} step: inlcuded to toggle step on, otherwise toggle off
   */
  const toggleAddPartsFromCADMode = useCallback(
    (step: Step) => {
      if (!gltf || !steps || !step.id || !assemblyTree || !documentId) return

      const cadPageState = getCadPageState()
      const isActiveStep = cadPageState.operationStep?.stepId === step.id
      const isInAddPartMode = Boolean(cadPageState.operationStep?.selectFromCad)
      const shouldToggleOn = !(isActiveStep && isInAddPartMode)
      const selectedParts = shouldToggleOn ? step.assembly_group_ids : []

      // use selected parts as a proxy for highlighting parts in active step
      setCadPageState({
        explosionsState: ExplosionsState.DISABLED,
        isEditModeActive: shouldToggleOn,
        selectedParts,
        operationStep: {
          documentId,
          stepId: step.id,
          selectFromCad: shouldToggleOn,
          isActive: shouldToggleOn,
        },
      })

      gltf.setVisibilityForOperationSteps({
        assemblyTree,
        steps,
        activeStep: shouldToggleOn ? null : step,
        hiddenParts: cadPageState.hiddenParts,
        explosions: cadPageState.explosions,
        isExplosionLinesEnabled: cadPageState.isExplosionLinesEnabled,
      })

      gltf.unhighlightParts(colorMap)
      if (shouldToggleOn) {
        selectedParts.map((partUUID) => {
          const node = assemblyTree.nodes.find((n) => n.uuid === partUUID)
          if (node) gltf.highlightPart(node.instance, HIGHLIGHT_PURPLE)
        })
      }
    },
    [
      gltf,
      steps,
      assemblyTree,
      colorMap,
      documentId,
      getCadPageState,
      setCadPageState,
    ],
  )

  /**
   * Add new part from CAD to active step
   */
  const onAddPartFromCAD = useCallback(
    (uuid: string) => {
      if (!gltf || !steps || !assemblyTree) return

      const cadPageState = getCadPageState()
      const operationStep = cadPageState.operationStep
      const activeStep = steps.find((step) => step.id === operationStep?.stepId)
      // #TODO: update wherever else is necessary
      const isAlreadyAdded = getAllAddedParts().includes(uuid)

      if (
        operationStep?.selectFromCad &&
        !isAlreadyAdded &&
        activeStep?.id &&
        activeStep.document_version
      ) {
        addPartToStep.mutate({
          documentPageId: activeStep.id,
          documentVersionId: activeStep.document_version,
          assemblyGroupIds: [uuid],
          onSuccess: () => {
            const selectedParts = Array.from(
              new Set([...cadPageState.selectedParts, uuid]),
            )
            setCadPageState({ selectedParts })

            const node = assemblyTree.nodes.find((n) => n.uuid === uuid)
            if (node) gltf.highlightPart(node.instance, HIGHLIGHT_PURPLE)
          },
        })
      }
    },
    [
      gltf,
      steps,
      assemblyTree,
      addPartToStep,
      getCadPageState,
      setCadPageState,
      getAllAddedParts,
    ],
  )

  return {
    isLoading,
    toggleAddPartsFromCADMode,
    onAddPartFromCAD,
  }
}
