import type { View } from '@/lib/api/client'
import type { Step } from '@/services/queries/operation_steps/types'
import type { DocumentTypeChoices } from '@/lib/api/client'
import { Screenshot } from './Screenshot'
import { useKickViewWorker } from '@/services/hooks/view_worker'
import { useAppStore } from '@/state'
import { useCADPageStore } from '@/state'
import { Skeleton } from '@/components/ui/skeleton'
import { Spinner } from '@/components/ui/spinner'
import { cn } from '@/utils'

export const ScreenshotList = ({
  views,
  steps,
  documentType,
  isLoading,
  isDisabled,
  isReadOnly,
  onClick,
  onDelete,
}: {
  views: View[]
  steps: Step[]
  documentType?: DocumentTypeChoices
  isLoading: boolean
  isDisabled?: boolean
  isReadOnly: boolean
  onDelete?: (viewId?: string | null) => void
  onClick: (view: View, attachedStep?: Step) => void
}) => {
  const { isViewWorkerProcessing } = useKickViewWorker()

  const allViewUploadsInProgress = useAppStore(
    (state) => state.viewUploadsInProgress,
  )

  const filterScreenshotsByDocumentPageId = useCADPageStore(
    (state) => state.filterScreenshotsByDocumentPageId,
  )

  const screenshots = views
    .sort((a, b) => {
      const stepA = steps.find((s) => s.id === a.document_page)
      const stepB = steps.find((s) => s.id === b.document_page)
      return (stepA?.order_number || 0) - (stepB?.order_number || 0)
    })
    .filter((view) => {
      return filterScreenshotsByDocumentPageId
        ? view.document_page === filterScreenshotsByDocumentPageId
        : true
    })

  const viewUploadsInProgress = Array.from(
    new Set(
      allViewUploadsInProgress
        .filter((p) => {
          const screenshot = screenshots.find((s) => s.id === p.viewId)
          return screenshot && !screenshot.download_url
        })
        .map((p) => p.viewId),
    ),
  )

  if (isLoading) {
    return (
      <div className="grid grid-cols-3 auto-rows-max gap-4 overflow-y-auto h-full w-full mt-4 pb-16">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    )
  }

  if (!isViewWorkerProcessing) {
    return <Spinner />
  }

  if (screenshots.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center space-y-2 mt-10">
        <h1 className="text-md font-semibold text-center">
          No screenshots found.
        </h1>
        <p className="text-sm text-gray-500 text-center">
          Create or select an operation to take a screenshot.
        </p>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'grid grid-cols-3 auto-rows-max gap-4 overflow-y-auto h-full w-full mt-4 pb-16',
        {
          'pointer-events-none': isDisabled,
        },
      )}
    >
      {viewUploadsInProgress.map((_, i) => (
        <Skeleton key={i} className="h-64 w-56 border" />
      ))}
      {screenshots.map(
        (view) =>
          view.download_url && (
            <Screenshot
              key={view.id}
              view={view}
              steps={steps}
              documentType={documentType}
              isReadOnly={isReadOnly}
              isLoading={isDisabled}
              onDelete={onDelete && (() => onDelete(view.id))}
              onClick={(attachedStep) => {
                onClick(view, attachedStep)
              }}
            />
          ),
      )}
    </div>
  )
}
