import { useCallback, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useCADPageStore } from '@/state'
import { useAppStore } from '@/state'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'

/**
 * Kick the view jobs when the document is first loaded
 */
export const useInitialViewJobs = () => {
  const hasDocumentLoaded = useCADPageStore((state) => state.hasDocumentLoaded)
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const viewWorker = useAppStore((state) => state.viewWorker)

  const { data: cadData } = useCADQuery()
  const { data: docData } = useDocumentPageQuery()

  const cadVersionId = cadData?.version?.id
  const documentVersionId = docData?.documentVersion?.id

  const kickInitialViewJobs = useCallback(
    async (cadVersionId: string, documentVersionId: string) => {
      if (!viewWorker) return
      await viewWorker.kick(cadVersionId, documentVersionId)
    },
    [viewWorker],
  )

  useEffect(() => {
    if (
      hasDocumentLoaded ||
      !viewWorker ||
      !cadVersionId ||
      !documentVersionId
    ) {
      return
    }

    kickInitialViewJobs(cadVersionId, documentVersionId)
      .then(() => {
        setCadPageState({ hasDocumentLoaded: true })
      })
      .catch((error) => {
        throw new Error(`${error.message} - Failed to kick initial view jobs`)
      })
  }, [
    kickInitialViewJobs,
    cadVersionId,
    documentVersionId,
    hasDocumentLoaded,
    setCadPageState,
    viewWorker,
    cadData,
    docData,
  ])
}
