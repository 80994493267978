import { Vector3, Plane } from 'three'
import { useShallow } from 'zustand/react/shallow'
import { TransformControls } from '@react-three/drei'
import { useEffect, useCallback, useState, useRef } from 'react'
import { TransformControls as TransformControlsImpl } from 'three-stdlib'

import { GLTFObject } from '@/lib/cad/GLTFObject'
import { PlaneVisualizer } from '@/lib/cad/PlaneVisualizer'
import { SectionState, useCADPageStore } from '@/state/cad'

const log = false
export const initPlaneObject = () => new PlaneVisualizer(0x0000ff)

export const CrossSectionControls = ({ gltf }: { gltf: GLTFObject }) => {
  const state = useCADPageStore(useShallow((state) => state.sectionState))
  const boundsMax = useCADPageStore(useShallow((state) => state.boundsMax))
  const sceneCenter = useCADPageStore(useShallow((state) => state.sceneCenter))
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))
  const getCadPageState = useCADPageStore(useShallow((state) => state.getState))
  const trans = useCADPageStore(useShallow((state) => state.translateEnabled))
  const rotate = useCADPageStore(useShallow((state) => state.rotateEnabled))
  const planeObject = useCADPageStore(useShallow((state) => state.planeObject))
  const clippingPlanes = useCADPageStore(
    useShallow((state) => state.clippingPlanes),
  )

  const { RESET, INIT, ENABLED, PREENABLE, DISABLED } = SectionState
  const [active, setActive] = useState<'rotate' | 'translate' | null>(null)
  const [zero, setZero] = useState<Vector3 | null>(null)

  const rotateControlsRef = useRef<TransformControlsImpl>(null)
  const translateControlsRef = useRef<TransformControlsImpl>(null)

  const activeClippingPlane = 0

  useEffect(() => {
    //
    // Initialize clipping plane
    if (state === INIT && !clippingPlanes[activeClippingPlane]) {
      const normal = new Vector3(0, 0, -1)
      const constant = -new Vector3(0, 0, 0).dot(normal)
      setCadPageState({ clippingPlanes: [new Plane(normal, constant)] }) // #TODO: only update active plane
    }

    //
    // Handle state change
    if (clippingPlanes[activeClippingPlane] && boundsMax && sceneCenter) {
      if (state === INIT) {
        if (log) console.log('INIT')
        gltf.setClippingPlanes(clippingPlanes) // #TODO: only update active plane
      }

      if (state === INIT || state === PREENABLE) {
        if (log) console.log('PREENABLE')

        if (!zero) {
          setZero(planeObject.position.clone().add(sceneCenter))
          planeObject.position.add(sceneCenter)
        } else {
          planeObject.position.copy(zero)
        }

        planeObject.scale.setScalar(0.5 * boundsMax)
        planeObject.addEventListener('added', onAddPlaneObject)
        gltf.scene.add(planeObject)
      } else if (state === ENABLED) {
        if (log) console.log('ENABLED')
      } else if (state === DISABLED) {
        if (log) console.log('DISABLED')
        planeObject.removeEventListener('added', onAddPlaneObject)
        gltf.scene.remove(planeObject)
      }
    }

    //
    // Handle reset
    if (state === RESET) {
      if (log) console.log('RESET')
      planeObject.removeEventListener('added', onAddPlaneObject)
      gltf.scene.remove(planeObject)
    }

    return () => {
      if (planeObject) {
        planeObject.removeEventListener('added', onAddPlaneObject)
      }
    }
  }, [gltf, state, boundsMax, sceneCenter, planeObject, clippingPlanes])

  //
  // Handle planeObject added to scene
  const onAddPlaneObject = useCallback(() => {
    const state = getCadPageState().sectionState
    if (state === INIT || state === PREENABLE) {
      setCadPageState({ sectionState: ENABLED, isEditModeActive: true })
    }
  }, [setCadPageState, getCadPageState])

  //
  // Update clipping plane onChange
  const updateClippingPlane = useCallback(() => {
    if (getCadPageState().sectionState === ENABLED) {
      const planeNormal = new Vector3(0, 0, -1)
        .applyQuaternion(planeObject.quaternion)
        .normalize()

      const globalPosition = new Vector3()
      planeObject.getWorldPosition(globalPosition)
      const updatedPlane = new Plane()
      updatedPlane.setFromNormalAndCoplanarPoint(planeNormal, globalPosition)
      clippingPlanes[activeClippingPlane] = updatedPlane
      setCadPageState({ clippingPlanes: [...clippingPlanes] })
    }
  }, [clippingPlanes, planeObject])

  if (state !== ENABLED || !planeObject) return null

  return (
    <>
      {trans && (
        <TransformControls
          ref={translateControlsRef}
          size={1.2}
          mode={'translate'}
          object={planeObject}
          space="local"
          showX={false}
          showY={false}
          onMouseDown={() => {
            if (active === 'rotate') return
            setActive('translate')
            if (rotateControlsRef.current) {
              rotateControlsRef.current['enabled'] = false
            }
          }}
          onMouseUp={() => {
            setActive(null)
            if (rotateControlsRef.current) {
              rotateControlsRef.current['enabled'] = true
            }
          }}
          onChange={() => {
            updateClippingPlane()
          }}
        />
      )}
      {rotate && (
        <TransformControls
          ref={rotateControlsRef}
          mode={'rotate'}
          object={planeObject}
          space="local"
          showZ={false}
          onMouseDown={() => {
            if (active === 'translate') return
            setActive('rotate')
            if (translateControlsRef.current) {
              translateControlsRef.current['enabled'] = false
            }
          }}
          onMouseUp={() => {
            setActive(null)
            if (translateControlsRef.current) {
              translateControlsRef.current['enabled'] = true
            }
          }}
          onChange={() => {
            updateClippingPlane()
          }}
        />
      )}
    </>
  )
}
