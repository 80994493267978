import { useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'

import {
  Cad,
  CadVersion,
  Project,
  DocumentVersion,
  DocumentPage,
} from '@/lib/api/client'
import { RawAssemblyTree } from '@/state'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { GLTFObject } from '@/lib/cad/GLTFObject'
import { useGetProject } from '@/services/queries/projects'
import {
  useListCadVersions,
  useGetCadVersion,
} from '@/services/queries/cad_versions'
import { useDocument } from '@/services/queries/documents'
import { useGetLatestDocumentVersion } from '@/services/queries/document_versions'
import { useListDocumentPagesQuery } from '@/services/queries/document_pages'
import { useCad } from './useCad'

import { useGLTF } from './useGLTF'
import { useOperationSteps } from '../../operation_steps'
import { useCADPageStore } from '@/state/cad'

type GetCADData = {
  project?: Project
  documentVersion?: DocumentVersion | null
  documentPages: DocumentPage[]
  cad?: Cad
  cadVersions: CadVersion[]
  version?: CadVersion | null
  isLatestCadVersion: boolean
  rawAssemblyTree?: RawAssemblyTree
  hasNoDownloadUrl?: boolean
  gltf?: GLTFObject
}

export const useCADQuery = (): {
  isLoading: boolean
  data: GetCADData
} => {
  const { projectId, documentId, cv: selectedCadVersion } = useCadPageParams()

  const projectData = useGetProject({ projectId })
  const project = projectData.data

  const documentData = useDocument({ documentId })
  const latestDocument = documentData.data

  const cadData = useCad({ cadId: latestDocument?.cad })
  const cad = cadData.data

  const cadVersionsData = useListCadVersions({ cadId: cad?.id })
  const cadVersions = cadVersionsData.data || []
  const latestCadVersion =
    Array.isArray(cadVersions) && cadVersions.length ? cadVersions[0] : null

  const cadVersionData = useGetCadVersion({ cadVersionId: selectedCadVersion })
  const cadVersion = selectedCadVersion ? cadVersionData.data : latestCadVersion

  const documentVersionData = useGetLatestDocumentVersion({
    documentId: latestDocument?.id,
  })
  const documentVersion = documentVersionData.data

  const documentPagesData = useListDocumentPagesQuery({
    documentVersionId: documentVersion?.id,
  })
  const documentPages = documentPagesData.data || []

  const isLatestCadVersion = cadVersion?.id === latestCadVersion?.id
  const rawAssemblyTree = cadVersion?.assembly_tree as
    | RawAssemblyTree
    | undefined
  const downloadUrl = cadVersion?.download_url
  const cadVersionId = cadVersion?.id
  const gltfData = useGLTF({ downloadUrl, documentId, cadVersionId })
  const gltf = gltfData.data?.gltf

  const isLoading =
    cadData.isLoading ||
    projectData.isLoading ||
    cadVersionsData.isLoading ||
    cadVersionData.isLoading ||
    documentData.isLoading ||
    documentVersionData.isLoading ||
    gltfData.isLoading

  const data: GetCADData = {
    project,
    documentVersion,
    documentPages,
    cad,
    cadVersions,
    version: cadVersion,
    rawAssemblyTree,
    isLatestCadVersion,
    hasNoDownloadUrl: !downloadUrl,
    gltf,
  }

  return { data, isLoading }
}

export const useResetGLTF = () => {
  const hiddenParts = useCADPageStore((state) => state.hiddenParts)
  const explosions = useCADPageStore((state) => state.explosions)
  const isExplosionLinesEnabled = useCADPageStore(
    (state) => state.isExplosionLinesEnabled,
  )
  const { data: cadData } = useCADQuery()
  const { steps } = useOperationSteps()
  const gltf = useMemo(() => cadData?.gltf, [cadData?.gltf])
  const assemblyTree = useMemo(
    () => cadData?.rawAssemblyTree,
    [cadData?.rawAssemblyTree],
  )
  const colorMap = useCADPageStore((state) => state.colorMap)

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const reset = () => {
    if (!assemblyTree) return

    gltf?.setVisibilityForOperationSteps({
      assemblyTree,
      steps,
      activeStep: null,
      hiddenParts,
      explosions,
      isExplosionLinesEnabled,
    })
    setCadPageState({
      selectedParts: [],
      operationStep: null,
    })
    gltf?.unhighlightParts(colorMap)
  }

  return { reset }
}
