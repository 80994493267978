import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { Editor as TipTapEditor } from '@tiptap/react'
import { ResizableBox } from 'react-resizable'
import debounce from 'lodash.debounce'
import max from 'lodash.max'

import { cn } from '@/utils'
import { Editor } from '@/components/editor/Editor'
import { Spinner } from '@/components/ui/spinner'
import { useEditDocPageNote } from '@/services/queries/document_notes/useEditDocPageNote'
import { useDocumentState } from '@/state/document'
import {
  Note,
  TemplateAttribute,
  useDeleteNotes,
  useUpdateTemplateElement,
} from '@/services/hooks/template_attributes'

import 'react-resizable/css/styles.css'

interface OperationNotesEditorProps {
  documentId: string
  documentPageId: string
  projectId: string
  isAddingAnnotation: boolean
  isReadOnly: boolean
  type: string
  notesTemplate: TemplateAttribute<Note>
}

export const OperationNotesEditor = ({
  documentId,
  documentPageId,
  projectId,
  isAddingAnnotation,
  isReadOnly,
  type,
  notesTemplate,
}: OperationNotesEditorProps) => {
  const INITIAL_DELTA = useMemo(() => ({ x: 0, y: 0 }), [])
  const containerRef = useRef<HTMLDivElement>(null)
  const selectedElement = useDocumentState((state) => state.selectedElement)
  const { mutateAsync: updateTemplate, isPending: isUpdatingTemplate } =
    useUpdateTemplateElement({
      documentPageId,
    })
  const { mutateAsync: removeNotes, isPending: isRemovingNotes } =
    useDeleteNotes()
  const zoom = useDocumentState((state) => state.zoom)
  const zoomRatio = useMemo(() => zoom / 100, [zoom])
  const setSelectedElement = useDocumentState(
    (state) => state.setSelectedElement,
  )
  const isSelected = useMemo(
    () =>
      selectedElement?.documentPageId === documentPageId &&
      selectedElement?.id === notesTemplate.id,
    [selectedElement, documentPageId, notesTemplate.id],
  )
  const positionData = useMemo(
    () => notesTemplate?.template_values?.position,
    [notesTemplate],
  )
  const size = useMemo(
    () => notesTemplate?.template_values?.size,
    [notesTemplate],
  )

  const notesPosition = useMemo(
    () => notesTemplate?.template_values?.notesPosition,
    [notesTemplate],
  )
  const isAbsolute = useMemo(
    () => notesPosition === 'absolute',
    [notesPosition],
  )

  const deleteNotesHandler = useCallback(async () => {
    if (notesTemplate) {
      await removeNotes(notesTemplate.id as string)
    }
  }, [removeNotes, notesTemplate])

  const updateNotesSizeHandler = useCallback(
    async (size: { width: number; height: number }) => {
      await updateTemplate({
        payload: {
          size,
        },
        data_type: type as any,
        templateAttributeId: notesTemplate.id as string,
      })
    },
    [type, notesTemplate.id, updateTemplate],
  )

  const onRemove = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        await deleteNotesHandler()
      }
    },
    [deleteNotesHandler],
  )

  useEffect(() => {
    if (isSelected && selectedElement?.isRemovable) {
      document.addEventListener('keydown', onRemove)
    } else {
      document.removeEventListener('keydown', onRemove)
    }

    return () => {
      document.removeEventListener('keydown', onRemove)
    }
  }, [onRemove, isSelected, selectedElement?.isRemovable])

  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    let resizeObserver: ResizeObserver
    const minDimensions = { width: 300, height: 100 }
    if (containerRef.current && !size) {
      const rect = containerRef.current.getBoundingClientRect()
      const width = max([rect.width, minDimensions.width])
      const height = max([rect.height, minDimensions.height])
      setContainerDimensions({
        width,
        height,
      })

      resizeObserver = new ResizeObserver((entries) => {
        const { width, height } = entries[0].contentRect

        setContainerDimensions({
          width: max([width, minDimensions.width]),
          height: max([height, minDimensions.height]),
        })
      })

      resizeObserver.observe(containerRef.current)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [containerRef, size])

  useEffect(() => {
    if (size) {
      setContainerDimensions(size)
    }
  }, [size])

  const [{ position, delta }, setPosition] = useState({
    position: positionData,
    delta: INITIAL_DELTA,
  })
  const { mutate: updateNotes } = useEditDocPageNote({
    documentPageId,
    notesId: notesTemplate?.id as string,
  })
  const containerId = useMemo(
    () => `${documentPageId}-${notesTemplate.id}`,
    [documentPageId, notesTemplate.id],
  )

  const { attributes, isDragging, listeners, transform, setNodeRef } =
    useDraggable({
      disabled: isAddingAnnotation || isReadOnly,
      id: notesTemplate.id as string,
      data: { type },
    })

  useEffect(() => {
    if (!(transform?.x || transform?.y)) return
    setPosition((prevPosition) => ({
      ...prevPosition,
      delta: {
        x: (transform?.x || 0) / zoomRatio,
        y: (transform?.y || 0) / zoomRatio,
      },
    }))
  }, [transform, zoomRatio])

  useEffect(() => {
    setPosition({
      position: positionData,
      delta: INITIAL_DELTA,
    })
  }, [INITIAL_DELTA, positionData])

  const saveNotes = useCallback(
    debounce(({ editor }: { editor: TipTapEditor }) => {
      updateNotes({
        editor,
        notesPosition: notesPosition as 'absolute' | 'relative',
      })
    }, 500),
    [updateNotes],
  )

  const mutatedListeners = useMemo(() => {
    return {
      ...listeners,
      onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => {
        const targetId = (e.target as Element)?.id
        if (targetId === containerId) {
          listeners?.onMouseDown(e)
        }
      },
      onTouchStart: (e: React.TouchEvent<Element>) => {
        const targetId = (e.target as Element)?.id
        if (targetId === containerId) {
          listeners?.onMouseDown(e)
        }
      },
    }
  }, [listeners, containerId])

  const isLoading = useMemo(
    () => isUpdatingTemplate || isRemovingNotes,
    [isUpdatingTemplate, isRemovingNotes],
  )

  return (
    <div
      className={cn('flex flex-1 p-px', {
        [notesPosition as string]: true,
        'top-0': isAbsolute,
        'left-0': isAbsolute,
      })}
    >
      <div className="relative flex flex-1 h-full w-full" ref={containerRef} />
      <div
        id={containerId}
        ref={setNodeRef}
        className={cn('border-4 flex rounded-md box-content absolute', {
          'cursor-move': !isReadOnly,
          'cursor-default': isReadOnly,
          'rounded-md': isSelected,
          'shadow-md': isDragging,
          'shadow-gray-200': isDragging,
          'bg-sky-200/10': isDragging,
          'pointer-events-none': isAddingAnnotation,
          'pointer-events-auto': !isAddingAnnotation,
          'z-[1]': !isDragging,
          'z-[100]': isDragging,
        })}
        {...attributes}
        {...mutatedListeners}
        style={{
          translate: `${delta?.x || 0}px ${delta?.y || 0}px`,
          top: position?.y || 0,
          left: position?.x || 0,
          borderColor: isSelected ? 'rgba(129, 140, 248, 0.5)' : 'transparent',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <ResizableBox
          width={containerDimensions.width}
          height={containerDimensions.height}
          minConstraints={[150, 45]}
          maxConstraints={[740, 420]}
          onResizeStop={(_event, data) => updateNotesSizeHandler(data.size)}
          resizeHandles={!isReadOnly && isSelected ? ['se'] : []}
        >
          <>
            {isLoading && (
              <div className="absolute h-full w-full bg-zinc-400/60 z-10">
                <Spinner />
              </div>
            )}
            <Editor
              projectId={projectId}
              documentId={documentId}
              documentPageId={documentPageId}
              className={cn('flex-1 bg-transparent', {
                'pointer-events-none': isAddingAnnotation || isDragging,
              })}
              isAddingAnnotation={isAddingAnnotation}
              onBlur={saveNotes}
              onClick={() => {
                setSelectedElement({
                  id: notesTemplate.id as string,
                  documentPageId,
                  isRemovable: false,
                })
              }}
              onUpdate={saveNotes}
              content={notesTemplate?.template_values?.content ?? ''}
              disabled={isReadOnly}
              placeholder="Write something or press '/' for commands..."
              isDragging={isDragging}
              updateBomWithSlashCommands={true}
              isTransparent={true}
            />
          </>
        </ResizableBox>
      </div>
    </div>
  )
}
