import { useMutation, useQueryClient } from '@tanstack/react-query'

import { createCad, Cad } from '@/lib/api/client'
import CustomError from '@/lib/api/CustomError'
import { useToast } from '@/components/ui/use-toast'
import QUERY_KEYS from '../queryKeys'

type mutationCadProps = {
  onSuccess?: (props: { projectId: string; cad?: Cad }) => void
}

export const useCreateCad = (props?: mutationCadProps) => {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      projectId,
      values,
    }: {
      projectId: string
      values: { file: File; name: string }
    }) => {
      try {
        const cad = await createCad(projectId, { ...values })
        const cadId = cad.id
        if (!cadId) {
          throw new Error('Failed to get cad ID')
        }
        return {
          cad,
          cadId,
          projectId,
        }
      } catch (e: any) {
        if (e instanceof CustomError) {
          toast({
            title: 'Error',
            description: e.message,
            variant: 'destructive',
          })
        }
        throw e
      }
    },
    onSuccess: ({ cad, projectId }) => {
      queryClient.setQueryData(
        [QUERY_KEYS.CADS, { projectId }],
        (cads: Array<Cad> | undefined) => (cads ? [...cads, cad] : [cad]),
      )
      queryClient.setQueryData([QUERY_KEYS.CAD, { cadId: cad?.id }], cad)
      if (props?.onSuccess)
        props.onSuccess({
          projectId,
          cad,
        })
    },
  })
}
