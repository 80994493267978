import { useState } from 'react'
import { Html } from '@react-three/drei'
import { useContextBridge } from 'its-fine'
import { useShallow } from 'zustand/react/shallow'

import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { ScreenshotList } from './ScreenshotList'
import { ScreenshotsFilterDropdown } from './ScreenshotsFilterDropdown'

import { useDeleteView } from '@/services/queries/views'
import { useCADQuery } from '@/services/queries/cads'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { useCadPageParams } from '@/pages/CADPage/hooks'
import { useScreenshots } from './hooks/useScreenshots'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useSetCadFromView } from './hooks/useSetCadFromView'
import { useCADPageStore } from '@/state'
import { useIsReadOnly } from '../../hooks/useIsReadOnly'
import { formatNumber } from '@/lib/formatNumber'
import { Spinner } from '@/components/ui/spinner'

import type { DocumentTypeChoices } from '@/lib/api/client'

export const ScreenshotPanelButton = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  const ContextBridge = useContextBridge()

  const { documentId, dv: selectedDocumentVersionId } = useCadPageParams()

  const { isLoading: isCadLoading, data: cadData } = useCADQuery()

  const { isLoading: isLoadingSteps, steps } = useOperationSteps()

  const { isLoading: isDocLoading, data } = useDocumentPageQuery()

  const { isLoading: isReadOnlyInfoLoading, data: readOnlyInfo } =
    useIsReadOnly()

  const documentType = data?.documentType as DocumentTypeChoices
  const cadVersionId = cadData?.version?.id
  const cadId = cadData.cad?.id
  const gltfScene = cadData.gltf?.scene

  const { isLoading: isLoadingScreenshots, data: views } = useScreenshots({
    documentId,
    cadVersionId,
    documentVersionId: selectedDocumentVersionId,
  })

  const { mutate: deleteView } = useDeleteView()

  const setCadFromView = useSetCadFromView()

  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const isLoading =
    isLoadingSteps ||
    isLoadingScreenshots ||
    isCadLoading ||
    isDocLoading ||
    isReadOnlyInfoLoading

  const isReadOnly = Boolean(readOnlyInfo?.isReadOnly)

  if (!gltfScene || !cadId || isCadLoading) {
    return null
  }

  return (
    <Html
      wrapperClass="transform-none w-44 h-36 bg-transparent !z-20 !top-5 !right-0 !left-auto select-none"
      className="h-full w-full bg-transparent"
    >
      <ContextBridge>
        <Sheet open={isPanelOpen} onOpenChange={setIsPanelOpen}>
          <SheetTrigger asChild disabled={isLoading}>
            <Button
              id="screenshot-button"
              data-testid="screenshot-button"
              className="rounded-full"
              onMouseDown={() => {
                setCadPageState({
                  filterScreenshotsByDocumentPageId: null,
                })
              }}
            >
              <div className="relative">
                <span>Screenshots</span>
                {views?.length !== 0 && (
                  <span
                    className="absolute bottom-4 rounded-full px-1.5 py-0"
                    style={{
                      fontSize: 10,
                      backgroundColor: '#ff7f50',
                    }}
                  >
                    {isLoading ? (
                      <div className="py-1">
                        <Spinner className="w-3 h-3" />
                      </div>
                    ) : (
                      formatNumber(views?.length || 0)
                    )}
                  </span>
                )}
              </div>
            </Button>
          </SheetTrigger>
          <SheetContent className="max-w-3xl">
            <SheetHeader>
              <SheetTitle>Screenshots</SheetTitle>
            </SheetHeader>
            <div className="h-full pb-12">
              <div className="flex justify-start my-6">
                <ScreenshotsFilterDropdown />
              </div>

              <ScreenshotList
                views={views || []}
                steps={steps}
                documentType={documentType}
                isLoading={isLoading}
                isReadOnly={isReadOnly}
                onDelete={(viewId) => {
                  if (!viewId) return
                  deleteView({ viewId })
                }}
                onClick={async (view, attachedStep) => {
                  await setCadFromView(view)
                  setIsPanelOpen(false)
                  if (attachedStep) {
                    setCadPageState({
                      subAssemblyDocumentPageId: attachedStep.parent,
                    })
                  }
                }}
              />
            </div>
          </SheetContent>
        </Sheet>
      </ContextBridge>
    </Html>
  )
}
