import { cn } from '@/utils'
import { useListNotes } from '@/services/hooks/template_attributes'
import { useDocumentPageQuery } from '@/pages/DocumentPage/queries'
import { OperationNotesEditor } from '../OperationNotesEditor/OperationNotesEditor'
import type { DocumentPage } from '@/lib/api/client'

interface OperationNotessProps {
  documentPage: DocumentPage
  isReadOnly: boolean
}

export const OperationNotes = ({
  documentPage,
  isReadOnly,
}: OperationNotessProps) => {
  const isAddingAnnotation = false
  const notesTemplates = useListNotes(documentPage.id)
  const { data: docData } = useDocumentPageQuery()

  if (
    !docData ||
    !docData.project ||
    !docData.version ||
    !docData.documentPages
  ) {
    return null
  }

  return (
    <div className="flex gap-2 flex-1" style={{ height: '50%' }}>
      {notesTemplates.map((note) => (
        <div
          className={cn('flex flex-1 max-w-[50%]', {
            'max-w-[50%]': note.template_values.notesPosition === 'relative',
            [note.template_values.notesPosition || 'relative']: true,
            'top-0': note.template_values.notesPosition === 'absolute',
            'left-0': note.template_values.notesPosition === 'absolute',
          })}
          key={note.id}
        >
          <OperationNotesEditor
            documentId={docData.documentId ?? ''}
            projectId={docData?.project.id ?? ''}
            documentPageId={documentPage.id ?? ''}
            isAddingAnnotation={isAddingAnnotation}
            isReadOnly={isReadOnly}
            type="note"
            notesTemplate={note}
          />
        </div>
      ))}
    </div>
  )
}
