import { Html } from '@react-three/drei'
import { useAssemblyTreeQuery } from '../queries'
import { useCADPageStore } from '@/state'
import { DocumentTypeChoices } from '@/lib/api/client'
import { useOperationSteps } from '@/services/queries/operation_steps'
import { useMemo } from 'react'

export const CADPartLabel = ({
  documentType,
}: {
  documentType: DocumentTypeChoices
}) => {
  const {
    data: { assemblyTree },
  } = useAssemblyTreeQuery()
  const { isLoading, steps } = useOperationSteps()

  const selectedParts = useCADPageStore((state) => state.selectedParts)
  const addedParts = useMemo(
    () => Array.from(new Set(steps.flatMap((step) => step.assembly_group_ids))),
    [steps],
  )
  const operationStep = useCADPageStore((state) => state.operationStep)
  const highlightedPartUUID = useCADPageStore(
    (state) => state.highlightedPartUUID,
  )
  const node = assemblyTree?.nodes.find(
    (node) => node.uuid === highlightedPartUUID,
  )
  const partName = node?.display_name || node?.product || node?.instance

  const selectedNode = assemblyTree?.nodes.find(
    (node) => node.uuid === selectedParts[0],
  )
  const selectedPartName =
    selectedNode?.display_name ||
    selectedNode?.product ||
    selectedNode?.instance

  const partIsSelected = selectedParts.includes(highlightedPartUUID || '')
  const partIsAdded = addedParts.includes(highlightedPartUUID || '')

  const activeStepNumber = steps.find(
    (step) => step.id === operationStep?.stepId,
  )?.order_number

  const addedToStepNumber = steps.find((step) =>
    step.assembly_group_ids.includes(highlightedPartUUID || ''),
  )?.order_number

  if (isLoading || !steps) return null

  if (documentType === 'work_instructions' && partName) {
    const Label = () => {
      if (operationStep?.selectFromCad && !partIsSelected && !partIsAdded) {
        return (
          <span>
            Add {partName} to Operation {activeStepNumber}
          </span>
        )
      } else if (operationStep?.selectFromCad && partIsAdded) {
        return (
          <span>
            {partName} attached to Operation {addedToStepNumber}
          </span>
        )
      } else return <span>{partName}</span>
    }
    return (
      <CADPartLabelTemplate>
        <Label />
      </CADPartLabelTemplate>
    )
  } else if (documentType === 'project_tracker' && selectedPartName) {
    return (
      <CADPartLabelTemplate>
        {<span>Selected: {selectedPartName}</span>}
      </CADPartLabelTemplate>
    )
  } else if (documentType === 'project_tracker' && partName) {
    return (
      <CADPartLabelTemplate>{<span>{partName}</span>}</CADPartLabelTemplate>
    )
  }

  return null
}

const CADPartLabelTemplate = ({ children }: { children: React.ReactNode }) => (
  <Html
    wrapperClass="transform-none w-full h-0 !z-20 text-center"
    className="right-0"
    style={{
      right: 0,
      left: 0,
      top: '60px',
      transform: 'none !important',
    }}
  >
    <div className="flex justify-center select-none">
      <h1 className="text-sm text-white bg-gray-900 rounded px-2 py-1 flex items-center space-x-2">
        {children}
      </h1>
    </div>
  </Html>
)
