import { CSRF_TOKEN_NAME } from '@/constants'
import * as Sentry from '@sentry/browser'
import { client } from './client'

/**
 * Get the value of a cookie
 * @param name - The name of the cookie to retrieve
 * @returns
 */
export const getCookie = (name: string) => {
  let cookieValue: string | null = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue =
          decodeURIComponent(cookie.substring(name.length + 1)) || ''
        break
      }
    }
  }
  return cookieValue
}

/**
 * Get the CSRF token
 * @returns Promise<csrf_token>
 */
export const getCsrf = async () => {
  const csrf = getCookie(CSRF_TOKEN_NAME)
  if (csrf) {
    return csrf
  }
  const resp = await client.POST('/api/v1/accounts/csrf', {
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to get CSRF token')
  }
  return getCookie(CSRF_TOKEN_NAME)
}

/**
 * Log in to the application
 * @param email
 * @param password
 * @returns
 */
export const login = async (email: string, password: string) => {
  await getCsrf()
  const resp = await client.POST('/api/v1/accounts/login', {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    body: {
      email,
      password,
    },
  })
  if (resp.error || !resp.response.ok) {
    const keys: string[] = []
    for (const key of resp.response.headers.keys()) {
      keys.push(key)
    }
    Sentry.getCurrentScope().setExtras({
      headerKeys: keys,
      error: resp.error,
      status: resp.response.status,
      hasCSRFCookie: Boolean(getCookie(CSRF_TOKEN_NAME)),
      email,
    })

    Sentry.captureException(new Error('Login Rejected'))
    throw new Error('Login failed!')
  }
  return resp
}

/**
 * Log out of the application
 */
export const logout = async () => {
  try {
    return await client.POST('/api/v1/accounts/logout', {
      headers: {
        'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
      },
    })
  } catch (error) {
    Sentry.getCurrentScope().setExtras({ error })
    Sentry.captureException(new Error('Logout Failed'))
    throw new Error(`Logout failed! ${error}`)
  }
}

/**
 * Verify that the user is logged in
 */
export const verifyLogin = async () => {
  try {
    return await client.GET('/api/v1/accounts/hello')
  } catch (error) {
    console.warn('User is not logged in', error)
  }
}

/**
 * Create a new user account
 */
export const signUp = async ({
  firstName,
  lastName,
  email,
  password,
}: {
  firstName: string
  lastName: string
  email: string
  password: string
}) => {
  await getCsrf()
  const resp = await client.POST('/api/v1/accounts/signup', {
    headers: {
      'X-CSRFToken': getCookie(CSRF_TOKEN_NAME),
    },
    body: {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
    },
  })

  if (resp.error || !resp.response.ok) {
    throw new Error('Failed to create a new account.')
  }
  return resp
}
