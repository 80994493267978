import { Image, View } from '@/lib/api/client'
import { ViewTemplate } from '@/services/hooks/template_attributes'

const isView = (view: View | Image | undefined): view is View => {
  return (view as View)?.document_page_order !== undefined
}

export function cleanAndFilterViews({
  viewData = [],
  viewsTemplates,
  documentPageId,
}: {
  viewData: View[]
  viewsTemplates: ViewTemplate[]
  documentPageId: string
}): View[] | Image[] {
  if (!viewsTemplates?.length) return []
  const views = viewsTemplates
    .filter((template) => template.imagePosition === 'relative')
    .map((template) => {
      const viewId = template.viewId
      return viewData.find((view) => view.id === viewId)
    })
  const viewsInOperation = views
    .filter((view) => view?.document_page === documentPageId)
    .map((view) => ({
      ...view,
      type: 'view',
    }))
  const viewsWithoutPosition = viewsInOperation.filter(
    (view) =>
      view.document_page_order === undefined ||
      view.document_page_order === null,
  )

  const findNextViewWithoutPosition = (cleanViews: Array<View>) => {
    for (const image of viewsWithoutPosition) {
      const foundView = cleanViews.find(
        (currentView) => image.id === currentView.id,
      )
      if (!foundView) {
        return image
      }
    }
  }

  const maxOrder =
    Math.max(
      0, // Ensure there's at least one valid number
      ...(viewsInOperation || []).map(
        (i: any) => i.document_page_order || (0 as number),
      ),
    ) + 1

  return viewsInOperation
    .reduce(
      (accum, view: any) => {
        if (
          isView(view) &&
          view.document_page_order !== undefined &&
          view.document_page_order !== null
        ) {
          accum[view.document_page_order] = view
        }

        return accum
      },
      Array(maxOrder).fill({
        type: 'empty',
      }),
    )
    .reduce((accum, view, i) => {
      if (view.type === 'empty') {
        const viewWithoutPosition = findNextViewWithoutPosition(accum)

        if (viewWithoutPosition) {
          accum[i] = viewWithoutPosition
        } else {
          accum[i] = view
        }
      } else {
        accum[i] = view
      }

      return accum
    }, [])
}
