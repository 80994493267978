import SortableList from 'react-sortable-dnd-list'
import { useShallow } from 'zustand/react/shallow'
import { StepCard } from '../StepCard'

import { useToggleStepCardsList } from './hooks/useToggleStepCardsList'

import { CARD_PLACEHOLDER } from './constants'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import type { Step } from '@/services/queries/operation_steps/types'
import type { StepCardItem } from './types'
import { useCallback, useState } from 'react'
import { Button } from '@/components/ui/button'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { useCreateOperationStep } from '@/services/queries/operation_steps'
import { useCADPageStore } from '@/state/cad'
import { QUERY_KEYS } from '@/services/queries/document_pages'
import queryClient from '@/queryClient'

export const StepCardList = ({
  documentVersionId,
  documentId,
  steps,
  isReadOnly,
}: {
  documentVersionId: string
  documentId: string
  steps: Step[]
  isReadOnly?: boolean
}) => {
  const toggleStepCardsList = useToggleStepCardsList()

  const stepsKey = steps.length === 0 ? 'steps-list-0' : 'steps-list-ready'

  const items = [CARD_PLACEHOLDER, ...steps]

  return (
    <SortableList
      key={stepsKey}
      className="flex flex-col h-full overflow-y-auto"
      animationDuration={0}
      itemComponent={StepCardItemContainer}
      itemComponentProps={{
        isReadOnly,
        documentId,
        documentVersionId,
        steps: items,
      }}
      value={items}
      onChange={async (rawReorderedSteps: StepCardItem[]) => {
        await toggleStepCardsList({
          steps: rawReorderedSteps,
          previousSteps: steps,
          documentVersionId,
          isReadOnly: Boolean(isReadOnly),
        })
      }}
    />
  )
}

export const StepCardItemContainer = ({
  dragging,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dragged,
  children: step,
  isReadOnly,
  documentId,
  documentVersionId,
  steps,
  ...rest
}: {
  dragging: boolean
  dragged: boolean
  documentId: string
  documentVersionId: string
  isReadOnly?: boolean
  children: StepCardItem
  steps: Step[]
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const { mutate: createOperationStep } = useCreateOperationStep({
    documentVersionId,
    invalidateOnSuccess: false,
  })
  const toggleStepCardsList = useToggleStepCardsList()
  const subAssemblyDocumentPageId = useCADPageStore(
    (state) => state.subAssemblyDocumentPageId || null,
  )
  const setCadPageState = useCADPageStore(useShallow((state) => state.setState))

  const onCreateOperation = useCallback(async () => {
    const { documentPage: operation } = await createOperationStep({
      documentVersionId,
      parentDocumentId: subAssemblyDocumentPageId,
    })

    const currentStepIndex = steps.findIndex((s) => s.id === (step as Step).id)

    const updatedSteps = [
      ...steps.slice(0, currentStepIndex),
      operation,
      ...steps.slice(currentStepIndex),
    ]

    await toggleStepCardsList({
      steps: updatedSteps as Step[],
      previousSteps: steps,
      documentVersionId,
      isReadOnly: Boolean(isReadOnly),
    })

    await queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.DOCUMENT_PAGES, { documentVersionId }],
    })

    setCadPageState({
      operationStep: {
        documentId,
        stepId: operation?.id as string,
        isActive: true,
        selectFromCad: false,
      },
    })

    const intervalId = setInterval(() => {
      const stepContainer = document.getElementById('step-' + operation?.id)
      if (stepContainer) {
        clearInterval(intervalId)
        stepContainer.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }, 100)
  }, [documentVersionId, subAssemblyDocumentPageId, steps, createOperationStep])

  return (
    <div {...rest} className={`${dragging ? 'is-dragging' : ''}`}>
      {step === CARD_PLACEHOLDER ? null : (
        <>
          <div
            className="min-h-5 w-full flex items-center justify-center"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <AddOperationButton
              isHovering={isHovering}
              onClick={onCreateOperation}
            />
          </div>
          <StepCard
            stepNumber={step.order_number as number}
            step={step}
            isReadOnly={isReadOnly}
          />
        </>
      )}
    </div>
  )
}

type AddOperationButtonProps = {
  isHovering: boolean
  onClick: () => void
}
const AddOperationButton = ({
  isHovering,
  onClick,
}: AddOperationButtonProps) => {
  return isHovering ? (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="py-0.5 flex">
            <Button
              variant="ghost"
              onClick={onClick}
              className="m-0 p-0 h-auto hover:bg-slate-200"
            >
              <PlusCircleIcon className="w-4 h-4 text-slate-500" />
            </Button>
          </div>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Add Operation</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : null
}
