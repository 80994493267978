import { useState } from 'react'
import { cn } from '@/utils'
import { Dialog, DialogTrigger } from '@/components/ui/dialog'
import { DownloadPDFDialogContent } from './DownloadPDFDialogContent'

import { usePDFDownloadEnabled } from './hooks/usePDFDownloadEnabled'
import { usePDFDownloadTrigger } from './hooks/usePDFDownloadTrigger'
import { Button } from '@/components/ui/button'

export const PDFDownloadButton = ({
  onDownload,
}: {
  onDownload: () => void
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const isPDFDownloadEnabled = usePDFDownloadEnabled()
  const triggerDownload = usePDFDownloadTrigger()

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(isOpen) => {
        if (isPDFDownloadEnabled) {
          setIsDialogOpen(isOpen)
        }
      }}
    >
      <DialogTrigger
        asChild
        disabled={!isPDFDownloadEnabled}
        onClick={async () => {
          if (isPDFDownloadEnabled) {
            await triggerDownload()
          }
        }}
      >
        <Button
          variant="ghost"
          className={cn('flex items space-x-2', {
            'text-gray-400 cursor-not-allowed': !isPDFDownloadEnabled,
          })}
        >
          <span>Download Document (.pdf)</span>
        </Button>
      </DialogTrigger>
      <DownloadPDFDialogContent
        onDownload={() => {
          setIsDialogOpen(false)
          onDownload()
        }}
      />
    </Dialog>
  )
}
