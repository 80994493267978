import { Squares2X2Icon } from '@heroicons/react/24/outline'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'
import { useCreateDocumentTemplate } from '@/services/queries/document_templates'

interface CreateDocumentTemplateButtonProps {
  documentPageId?: string
  documentVersionId?: string
  isDisabled?: boolean
}

export const CreateDocumentTemplateButton = ({
  documentPageId,
  documentVersionId,
  isDisabled,
}: CreateDocumentTemplateButtonProps) => {
  const { mutate: createDocumentTemplate } = useCreateDocumentTemplate()

  const isButtonDisabled = isDisabled || !documentPageId || !documentVersionId

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="rounded-md hover:bg-gray-100 m-0 h-8 w-8 flex p-0 items-center justify-center"
            onClick={() => {
              if (documentPageId && documentVersionId) {
                createDocumentTemplate({
                  documentPageId,
                  documentVersionId,
                })
              }
            }}
            disabled={isButtonDisabled}
            data-testid="create-document-template-button"
          >
            <div className="flex flex-col items-center space-y-2 hover:color-primary cursor-pointer">
              <Squares2X2Icon className="w-6 h-6 stroke-2" />
            </div>
          </Button>
        </TooltipTrigger>
        <TooltipContent className="bg-black text-white">
          <p>Create Document Template</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
