import { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { PlusCircleIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { SelectProjectsDropdown } from './SelectProjectsDropdown'
import { SelectCADDropdown } from './SelectCADDropdown'
import { Files } from '@/components/ui/file'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from '@/components/ui/form'
import { z } from 'zod'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/ui/loading'

import workBookPreview from '@/assets/work-book-preview.png'
import workInstructionsPreview from '@/assets/work-instructions-preview2.png'

export type FormInputs = {
  documentName: string
  template: 'work_instructions' | 'project_tracker' | 'visual_bom'
  cadId: string
  files: any[]
  projectId: string
  projectName: string
}

const formSchema = z.object({
  documentName: z
    .string({
      required_error: 'Document name is required.',
    })
    .min(2, {
      message: 'Document name must be at least 2 characters.',
    }),
  cadId: z.string().optional(),
  files: z.array(z.any()).optional(),
  projectId: z.string().optional(),
  projectName: z.string().optional(),
  template: z.enum(['work_instructions', 'project_tracker', 'visual_bom']),
})

interface CreateDocumentFormFieldsProps {
  totalProjects: number
  isUploadingNewCAD: boolean
  isCreatingNewProject: boolean
  isCreatingNewDocument: boolean
  setIsCreatingNewProject: (value: boolean) => void
  setIsUploadingNewCAD: (value: boolean) => void
  onAfterSubmit: (values: FormInputs) => void
}

export const CreateDocumentFormFields = ({
  totalProjects,
  isUploadingNewCAD,
  isCreatingNewProject,
  isCreatingNewDocument,
  setIsCreatingNewProject,
  setIsUploadingNewCAD,
  onAfterSubmit,
}: CreateDocumentFormFieldsProps) => {
  const form = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      documentName: '',
      template: 'work_instructions',
      cadId: '',
      files: [],
      projectId: '',
      projectName: '',
    },
  })

  const validate = useCallback(
    (values: z.infer<typeof formSchema>) => {
      let isValid = true

      if (!values.documentName) {
        isValid = false
        form.setError('documentName', {
          type: 'required',
          message: 'Document name is required.',
        })
      }

      if (totalProjects > 0) {
        if (isCreatingNewProject && !values.projectName) {
          isValid = false
          form.setError('projectName', {
            type: 'required',
            message: 'Project name is required.',
          })
        }

        if (!isCreatingNewProject && !values.projectId) {
          isValid = false
          form.setError('projectId', {
            type: 'required',
            message: 'Project is required.',
          })
        }

        if (isUploadingNewCAD && (values.files || []).length < 1) {
          isValid = false
          form.setError('files', {
            type: 'required',
            message: 'Upload at least 1 CAD file.',
          })
        }

        if (!isUploadingNewCAD && !values.cadId) {
          isValid = false
          form.setError('cadId', {
            type: 'required',
            message: 'CAD is required.',
          })
        }
      } else {
        if (!values.projectName) {
          isValid = false
          form.setError('projectName', {
            type: 'required',
            message: 'Project name is required.',
          })
        }

        if ((values.files || []).length < 1) {
          isValid = false
          form.setError('files', {
            type: 'required',
            message: 'Upload at least 1 CAD file.',
          })
        }
      }

      return { isValid }
    },
    [totalProjects, form, isCreatingNewProject, isUploadingNewCAD],
  )

  const onSubmit: SubmitHandler<FormInputs> = (values) => {
    try {
      const { isValid } = validate(values)
      if (!isValid) return
      onAfterSubmit(values)
    } catch (e) {
      throw Error(`Unable to submit form: ${e}`)
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={(event) => void form.handleSubmit(onSubmit)(event)}>
        <div className="mb-6 mt-2">
          {!isCreatingNewProject && totalProjects > 0 && (
            <div className="flex items-center space-x-4">
              <FormField
                control={form.control}
                name="projectId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="project-id">Project</FormLabel>
                    <FormControl>
                      <SelectProjectsDropdown {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <span className="mt-8 w-8 text-end">or</span>
              <div className="mt-8">
                <Button
                  variant="ghost"
                  className="flex items-center space-x-1 text-sm"
                  onClick={() => {
                    form.setValue('projectId', '')
                    setIsCreatingNewProject(true)
                  }}
                >
                  <PlusCircleIcon className="h-5 w-5" />
                  <span>Create new project</span>
                </Button>
              </div>
            </div>
          )}
          {(isCreatingNewProject || totalProjects === 0) && (
            <div className="flex items-center space-x-2">
              <FormField
                control={form.control}
                name="projectName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Project</FormLabel>
                    <FormControl>
                      <Input
                        className="w-96"
                        placeholder="Project name"
                        {...field}
                        autoComplete="off"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {totalProjects > 0 && (
                <>
                  <span className="mt-8 w-8 text-end">or</span>
                  <div className="mt-8">
                    <Button
                      variant="ghost"
                      className="flex items-center space-x-1 text-sm"
                      onClick={() => {
                        form.setValue('projectName', '')
                        setIsCreatingNewProject(false)
                      }}
                    >
                      <PlusCircleIcon className="h-5 w-5" />
                      <span>Add existing project</span>
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className="mb-6">
          <FormField
            control={form.control}
            name="documentName"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="document-name">Document Name</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    autoComplete="off"
                    id="document-name"
                    placeholder="Document name"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="mb-8">
          {!isUploadingNewCAD && totalProjects > 0 && (
            <div className="flex items-center space-x-2">
              <FormField
                control={form.control}
                name="cadId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="cad-id">CAD</FormLabel>
                    <FormControl>
                      <SelectCADDropdown
                        {...field}
                        projectId={form.watch('projectId')}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <span className="mt-8 w-8 text-end">or</span>
              <div className="mt-8">
                <Button
                  variant="ghost"
                  className="text-sm flex items-center space-x-2"
                  onClick={() => {
                    form.setValue('cadId', '')
                    setIsUploadingNewCAD(true)
                  }}
                >
                  <CloudArrowUpIcon className="h-5 w-5" />
                  <span>Upload new CAD</span>
                </Button>
              </div>
            </div>
          )}
          {(isUploadingNewCAD || totalProjects === 0) && (
            <div className="flex items-center space-x-2">
              <div className="flex flex-col w-96">
                <h2 className="text-sm font-medium leading-none">CAD</h2>
                <Files
                  onChange={(files) => {
                    form.setValue('files', files)
                  }}
                  validateFile={(file) => {
                    form.setError('files', {})
                    const validExtentions = ['step', 'stp']
                    const tok = file.name.toLowerCase().split('.')
                    const ext = tok[tok.length - 1]

                    const hasValidFileExtention = validExtentions.includes(ext)
                    if (!hasValidFileExtention) {
                      form.setError('files', {
                        type: 'extention',
                        message: `Only files with the following extensions are allowed: ${validExtentions.join(
                          ', ',
                        )}.`,
                      })
                    }

                    const isValid = hasValidFileExtention

                    return isValid
                  }}
                  error={form.formState.errors.files?.message}
                />
              </div>
              {totalProjects > 0 && (
                <>
                  <span className="mt-4 w-8 text-end">or</span>
                  <div className="mt-4">
                    <Button
                      variant="ghost"
                      className="text-sm flex items-center space-x-2"
                      onClick={() => {
                        form.setValue('files', [])
                        setIsUploadingNewCAD(false)
                      }}
                    >
                      <PlusCircleIcon className="h-5 w-5" />
                      <span>Add existing CAD</span>
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
          <FormDescription className="mt-2">
            Select CAD from an existing project or upload a new one
          </FormDescription>
        </div>
        <div className="mb-8">
          <FormField
            control={form.control}
            name="template"
            render={({ field }) => (
              <FormItem>
                <FormLabel htmlFor="template">Template</FormLabel>
                <FormControl>
                  <div className="flex space-x-4" id="template">
                    <label
                      className="cursor-pointer"
                      htmlFor="work-instructions"
                    >
                      <input
                        id="work-instructions"
                        type="radio"
                        value="work_instructions"
                        checked={field.value === 'work_instructions'}
                        onChange={() => field.onChange('work_instructions')}
                        className="hidden"
                      />
                      <div
                        className={`px-4 py-2 border ${
                          field.value === 'work_instructions'
                            ? 'border-blue-500'
                            : 'border-gray-300'
                        } rounded-lg`}
                      >
                        <div className="text-center text-sm mb-6 font-semibold">
                          Work Instructions
                        </div>
                        <img
                          src={workInstructionsPreview}
                          alt="Work Instruction Template"
                        />
                      </div>
                    </label>
                    <label className="cursor-pointer" htmlFor="project-tracker">
                      <input
                        id="project-tracker"
                        type="radio"
                        value="project_tracker"
                        checked={field.value === 'project_tracker'}
                        onChange={() => field.onChange('project_tracker')}
                        className="hidden"
                      />
                      <div
                        className={`px-4 py-2 border ${
                          field.value === 'project_tracker'
                            ? 'border-blue-500'
                            : 'border-gray-300'
                        } rounded-lg`}
                        data-testid="create-document-project-tracker-option"
                      >
                        <div className="text-center text-sm mb-6 font-semibold">
                          Project Workbook
                        </div>
                        <img src={workBookPreview} alt="Work Book Template" />
                      </div>
                    </label>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end">
          <Button type="submit" disabled={isCreatingNewDocument}>
            {isCreatingNewDocument ? (
              <div className="flex items-center space-x-2">
                <span>Uploading</span>
                <Loading />
              </div>
            ) : (
              'Create Document'
            )}
          </Button>
        </div>
      </form>
    </Form>
  )
}
