import { useCallback, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { truncate } from '@/lib/truncate'
import { useCreateView, useListViews } from '@/services/queries/views'
import {
  useCreateDocumentNotes,
  useCreateViewTemplate,
  useListNotes,
} from '@/services/hooks/template_attributes'
import { useCADQuery } from '@/services/queries/cads'
import { useImageData } from './useImageData'
import { useCADPageStore } from '@/state/cad'
import { useDocumentTemplateFlag } from '@/featureFlags/useDocumentTemplateFlag'
import { useCreateOrAttachViewTemplateAttributeMutation } from '@/services/queries/template_attributes'

import type { DocumentTypeChoices, View } from '@/lib/api/client'

const MAX_VIEWS_WORK_INSTRUCTIONS = 2
const MAX_VIEWS_PROJECT_TRACKER = 1

const calculateNewScreenshotOrder = (relatedViews: Array<View>) => {
  const document_order_numbers = relatedViews.map(
    (view) => view.document_page_order || 0,
  )

  document_order_numbers.sort((a, b) => a - b)

  for (let i = 0; i < document_order_numbers.length; i++) {
    if (document_order_numbers[i] !== i) {
      return i
    }
  }

  return document_order_numbers.length
}

export const useCreateCadView = ({
  cadVersionId,
  documentVersionId,
  documentType,
  documentPageId,
  onCreate,
  onMutate,
}: {
  cadVersionId?: string | null
  documentVersionId?: string | null
  documentType?: DocumentTypeChoices
  documentPageId
  onMutate?: () => void
  onCreate?: () => void
}) => {
  const {
    data: { isEnabled: isDocumentTemplateFlagEnabled },
  } = useDocumentTemplateFlag()

  const { mutate: createOrAttachViewTemplate } =
    useCreateOrAttachViewTemplateAttributeMutation({ documentPageId })

  const cadQuery = useCADQuery()

  const addCreatedView = useCADPageStore(
    useShallow((state) => state.addCreatedView),
  )

  const listViewsQuery = useListViews({
    cadVersionId,
    documentVersionId,
  })

  const notesTemplates = useListNotes(documentPageId)
  const relativeNotes = useMemo(
    () =>
      notesTemplates.filter(
        (note) => note.template_values.notesPosition === 'relative',
      ),
    [notesTemplates],
  )

  const createTemplateViewMutation = useCreateViewTemplate()
  const { mutateAsync: createNotes, isPending: isCreateNotesPending } =
    useCreateDocumentNotes()
  const maxViews =
    documentType === 'work_instructions'
      ? MAX_VIEWS_WORK_INSTRUCTIONS
      : MAX_VIEWS_PROJECT_TRACKER
  const relatedViews = useMemo(
    () =>
      listViewsQuery.data?.filter((view) => {
        return view.document_page === documentPageId
      }) || [],
    [listViewsQuery.data, documentPageId],
  )
  const viewOrderInDocument = calculateNewScreenshotOrder(relatedViews)

  const createViewMutation = useCreateView({
    onMutate,
    onSuccess: (newView) => {
      if (!newView) {
        console.error('Failed to create view template because view is missing')
        return
      }

      addCreatedView(newView.id as string)

      const documentPageId = newView.document_page

      const views =
        listViewsQuery.data?.filter((v) => {
          return (
            typeof documentPageId === 'string' &&
            v.document_page === documentPageId &&
            v.id !== newView.id
          )
        }) || []

      if (isDocumentTemplateFlagEnabled && documentPageId) {
        createOrAttachViewTemplate({ viewId: newView.id as string })
      } else {
        if (views.length + 1 <= maxViews && documentPageId) {
          createTemplateViewMutation.mutate({
            documentPageId,
            viewTemplateData: {
              viewId: newView.id,
              imagePosition: 'relative',
            },
          })
        }
      }

      if (typeof onCreate === 'function') {
        onCreate()
      }
    },
  })

  const imageData = useImageData()

  const projectName = cadQuery.data?.project?.name || 'project'
  const cadVersionNumber = cadQuery.data?.version?.version_number || 1

  const mutateAsync = useCallback(
    async ({ documentPageId }: { documentPageId?: string }) => {
      if (!cadVersionId) {
        console.error('Unable to create view because cadVersionId is missing')
        return
      }

      const viewName = truncate(
        `${projectName}-${cadVersionNumber}-${Date.now()}`.replace(' ', '-'),
      )

      await createViewMutation.mutateAsync({
        cadVersionId,
        documentVersionId: documentVersionId as string,
        values: {
          source_type:
            documentType === 'visual_bom' ? 'isometric' : 'screenshot',
          name: viewName,
          image_data: imageData,
          document_page_id: documentPageId || null,
          dynamic: documentType === 'work_instructions',
          document_page_order: viewOrderInDocument,
        },
      })

      if (
        relativeNotes.length < maxViews &&
        documentPageId &&
        !isDocumentTemplateFlagEnabled
      ) {
        await createNotes({
          documentPageId,
          notesData: {
            content: '',
            notesPosition: 'relative',
          },
        })
      }
    },
    [
      documentVersionId,
      cadVersionId,
      cadVersionNumber,
      createViewMutation,
      documentType,
      imageData,
      projectName,
      viewOrderInDocument,
      maxViews,
      relativeNotes?.length,
      createNotes,
      isDocumentTemplateFlagEnabled,
    ],
  )

  const isPending =
    createViewMutation.isPending ||
    createTemplateViewMutation.isPending ||
    isCreateNotesPending

  return {
    mutateAsync,
    isPending,
  }
}
